import {
  fetchSystemLogList,
  setSystemLog,
} from '../../../../actions/systemLog';

import { searchEventMessages, clearFromEventMessageState } from '../../../../actions/eventMessageType';
import { fetchMessageList, clearFromMessageState } from '../../../../actions/message';

export const mapStateToProps = (state) => ({
  settings: state.settings,
  systemLog: state.systemLog,
  session: state.session,
  message: state.message,
  eventMessageType: state.eventMessageType,
});

export const mapDispatchToProps = {
  searchEventMessages,
  clearFromEventMessageState,
  fetchMessageList,
  clearFromMessageState,
  setSystemLog,
  fetchSystemLogList,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
