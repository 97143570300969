import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import Layout from '../../../../components/Layout';
import List from '../../../../components/List';
import FilterNav from '../../../../components/FilterNav';
import Select from '../../../../components/Select';
import ExtraActions from '../../../../components/ExtraActions';

import { headerList } from '../../../utils/headerList';
import popoverSettings from '../../utils/popoverSettings';
import IntlMessages from '../../../../utils/IntlMessages';
import attributeAggList from '../../../../utils/stash/attributeAggList';


import getSearchParams from '../../utils/getSearchParams';
import getQueryItems from '../../utils/getQueryItems';
import getDateRangeFilter from '../../utils/getDateRangeFilter';
import getGroupFilter from '../../utils/getGroupFilter';
import { getCatalogDetailAppPath } from '../../../../utils/paths';

import useStateStore from './hooks/useState';
import useLog from './hooks/useLog';
import useMerchant from './hooks/useMerchant';
import useMessage from './hooks/useMessage';
import usePhysicalCategories from './hooks/usePhysicalCategories';
import useAttribute from './hooks/useAttribute';
import useAttributeOptions from './hooks/useAttributeOptions';
import useUnmount from './hooks/useUnmount';
import { mapStateToProps, mapDispatchToProps } from './connect';
import Body from './conainers';
import { propTypes, defaultProps } from './propTypes';
import { mappingFields } from '../../../../utils/variables';
import { defaultPagination, defaultTimeRange } from '../../utils/defaultData';
import { errorLevelList } from '../../../../utils/staticData';
import sortBy from '../../utils/sortBy';
import getAttributeTableRows from '../../utils/getAttributeTableRows';

const Catalog = (props) => {
  const {
    physicalCategory, merchant, eventMessageType, log, message, settings,
    attribute, attributeOption,
  } = props;
  const { searched, searching, searchList } = physicalCategory;
  const { sourceOptions, timeFrameOptions } = settings;
  const tempHidded = true;

  const state = useStateStore(props);

  useLog(state, props);
  useMerchant(state, props);
  useMessage(state, props);
  usePhysicalCategories(state, props);
  useAttribute(state, props);
  useAttributeOptions(state, props);
  useUnmount(props);

  const resetPagination = (limit) => {
    const data = {
      page: 1,
      limit: limit || state.pagination.limit,
    };
    state.setPagination(data);
    state.setPaginationDirectionKey('');
    props.deleteLogBeforeKeys();
  };

  const clearSelectedMerchant = () => {
    state.setMerchantsFilter([]);
    state.setSelectedMerchants([]);
  };

  const handleLogTypeChange = (data) => {
    state.setLoadedAttributeCodes([]);
    state.setLogType(data.value);
    props.clearFromPhysicalCategoryState({
      fetched: false,
    });
    state.setPagination(defaultPagination);
  };

  const hadleChartDelete = () => {
    state.setSelectedChart({});
    state.setMessageFilter([]);
    state.setAggregationType({});
    state.setSelectedAttribute({});
    state.setMessageAttributeAggregation({});
    handleLogTypeChange({ value: 'merchant' });
  };

  const handleClearFilter = () => {
    sessionStorage.removeItem('catalogValues');
    clearSelectedMerchant();
    state.setRefreshFilter(!state.refreshFilter);
    state.setPagination(defaultPagination);
    state.setCategoriesFilter([]);
    state.setSearchFilter([]);
    state.setSelectCategoryQuery('');
    state.setTimeFrameValue('last_24_hours');
    state.setMessageFilter([]);
    state.setCategoriesFilter([]);
    state.setOriginFilter([]);
    state.setTargetList([]);
    state.setFilter([]);
    state.setSort([]);
    state.setAggregations([]);
    state.setFields([]);
    state.setOriginValue('');
    state.setTimeFrameFilter(defaultTimeRange);
  };

  const handleOriginChange = (data) => {
    clearSelectedMerchant();
    if (data.value) {
      const originFilter = [{
        field: mappingFields.origin,
        value: data.origValue.label,
        operator: 'eq',
      }];

      state.setOriginFilter(originFilter);
      state.setOriginValue(data.origValue.value);
    } else {
      state.setOriginFilter([]);
      state.setOriginValue('');
    }

    hadleChartDelete();
    state.setPagination(defaultPagination);
  };

  const handleTimeFrameChange = (data) => {
    clearSelectedMerchant();
    const timeRangeFilter = getDateRangeFilter(data.value);
    state.setTimeFrameValue(data.value);
    state.setTimeFrameFilter(timeRangeFilter);
    state.setPagination(defaultPagination);
    hadleChartDelete();
  };

  const handleMerchantChange = (merchants) => {
    if (!isEmpty(merchants)) {
      const merchantFilter = getGroupFilter({
        field: mappingFields.merchantId,
        list: merchants,
        operator: 'eq',
      });
      state.setSelectedMerchants(merchants);
      state.setMerchantsFilter(merchantFilter);
    } else {
      clearSelectedMerchant();
    }

    hadleChartDelete();
    state.setPagination(defaultPagination);
  };

  const handleSarchSubmit = (query) => {
    if (query) {
      const queryItems = getQueryItems(query);
      props.searchPhysicalCategories(getSearchParams(queryItems));
    }
  };

  const handleQueryChange = (query) => {
    state.setSelectCategoryQuery(query);
  };

  const handleSelectCategoriesClose = () => {
    state.setSelectCategoryQuery('');
    if (!isEmpty(searchList)) {
      props.clearFromPhysicalCategoryState({
        searchList: [],
        searched: false,
        searching: false,
      });
    }
  };

  const handleSelectCategoriesCancel = () => {
    state.setCategoriesFilter([]);
    state.setTargetList([]);
    handleSelectCategoriesClose();
  };


  const handleOnTransferChange = ({ targetList }) => {
    state.setTargetList(targetList);
  };

  const handleSelectCategoriesOk = () => {
    clearSelectedMerchant();
    const { targetList } = state;
    const categoriesFilter = targetList && !isEmpty(targetList) ? targetList.map((item) => ({
      field: 'categories.category',
      value: item.id,
      operator: 'eq',
    })) : [];

    hadleChartDelete();
    state.setCategoriesFilter([...categoriesFilter]);
  };

  const noSearchResult = searched && isEmpty(searchList);

  const handleChartClick = (chart) => {
    state.setSelectedChart(chart);
    state.setMessageFilter(getGroupFilter({
      field: mappingFields.messageId,
      list: [chart],
      operator: 'eq',
    }));

    if (attributeAggList.some((agl) => agl.id === chart.id)) {
      state.setExtraSortOptions(attributeAggList);
    }

    if (
      !isEmpty(state.selectedChart)
      && chart.id === state.selectedChart.id
    ) {
      hadleChartDelete();
      state.setExtraSortOptions('');
    }
  };


  const handleRefreshFilter = () => {
    state.setRefreshFilter(!state.refreshFilter);
    state.setPagination(defaultPagination);
  };

  const handleTableCellTitleClick = (data) => {
    props.setLog(data);

    props.history.push(getCatalogDetailAppPath({
      type: state.logType,
      id: data.key,
    }));
  };

  const handleErrorLevelChange = (data) => {
    let errorLevelFilterGroup = {};
    if (data.value) {
      errorLevelFilterGroup = {
        condition: 'and',
        group: [{
          field: mappingFields.level,
          value: data.origValue.label,
          operator: 'eq',
        }],
      };
    }

    state.setErrorLevel(data);
    state.setErrorLevelFilterGroup(errorLevelFilterGroup);
  };

  const handleAggregationTypeChange = (data) => {
    state.setAggregationType(data);
    props.clearFromAttributeState({ after: {} });
    resetPagination();

    if (!isEmpty(data.origValue)) {
      const newAggregation = [{
        operator: 'terms',
        field: data.origValue.field,
      }];
      state.setMessageAttributeAggregation(newAggregation);
    } else {
      state.setMessageAttributeAggregation([]);
    }
  };

  const handleToggleChange = (data) => {
    state.setAggregationType(data);
    handleAggregationTypeChange(data);

    if (!isEmpty(data)) {
      const newGroup = [{
        condition: 'and',
        group: [{
          field: data.origValue.fieldOrig,
          operator: 'exists',
        }],
      }];
      state.seAttributeFilterGroup(newGroup);
    } else {
      state.seAttributeFilterGroup([]);
    }
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
  };

  const handleSortByOptionClick = (data) => {
    resetPagination();
    if (attributeAggList.some((agl) => agl.value === data.value)) {
      state.setLogType(data.value);
      handleToggleChange(data);
    } else {
      handleLogTypeChange(data);
    }
  };

  const handlePaginationChange = (data, paginationDirectionKey) => {
    state.setPagination(data);

    if (data.page === 1) {
      resetPagination(data.limit);
    } else {
      state.setPaginationDirectionKey(paginationDirectionKey);
    }
  };

  const handlePanelClick = (data) => {
    state.setLoadedAttributeCodes([...state.loadedAttributeCodes, data.row.code]);
    if (
      !isEmpty(data)
      && !isEmpty(data.row)
      && !state.loadedAttributeCodes.some((lac) => lac === data.row.code)
    ) {
      const prefix = state.aggregationType.subAggregationPrefix
        || state.aggregationType.origValue.subAggregationPrefix;
      const { code } = data.row;
      const newAggregation = [{
        operator: 'terms',
        field: `${prefix}.${code}.keyword`,
        sort: {
          field: '_count',
          order: 'desc',
        },
      }];
      state.setMessageAttributeOptionAggregation(newAggregation);
      state.setSelectedAttribute(data);

      const newAttributeList = props.attribute.list.map((al) => {
        if (al.code === data.row.code) return ({ ...al, loading: true });
        return ({ ...al, loading: false });
      });
      props.replaceAttributeList(newAttributeList);
    } else {
      state.setMessageAttributeOptionAggregation([]);
      state.setSelectedAttribute({});
    }
  };

  const getTableRows = () => {
    const aggregationValue = !isEmpty(state.logType) ? state.logType : '';
    const disableExpand = state.logType ? state.logType === 'byAttributesOrigin' : false;
    const panelSettings = {
      className: disableExpand ? 'expansion-disabled' : '',
      isAllCollapsed: attribute.fetched,
    };
    switch (aggregationValue) {
      case 'byAttributes':
      case 'byAttributesOrigin':
        return ({
          contentType: 'node',
          list: getAttributeTableRows(attribute.list, handlePanelClick, panelSettings),
        });

      default:
        return ({
          contentType: 'array',
          list: log.list,
        });
    }
  };

  const filterFetching = message.fetching
    || eventMessageType.searching
    || attribute.fetching
    || attributeOption.fetching
    || log.fetching
    || physicalCategory.fetchingById;

  const tableBodyLoader = log.fetching
    || physicalCategory.fetchingById
    || attribute.fetching
    || attributeOption.fetching;

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-fixed',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center list-lg"
            dataSource={headerList}
          />
        ),
        content: (
          <FilterNav
            useCategory
            useMerchant
            useSource
            useTimeRange
            onSelectCategoriesOk={handleSelectCategoriesOk}
            onSelectCategoriesCancel={handleSelectCategoriesCancel}
            onSelectCategoriesClose={handleSelectCategoriesClose}
            sourceOptions={sourceOptions}
            merchant={{
              value: state.selectedMerchants,
              options: merchant.list,
            }}
            originSource={{
              value: state.originValue,
            }}
            timeFrame={{
              value: state.timeFrameValue,
              options: timeFrameOptions,
            }}
            settings={{
              popover: popoverSettings,
            }}
            content={{
              noResult: noSearchResult,
              loading: searching,
              valuePreloader: props.physicalCategory.searchingSelected,
              query: state.selectCategoryQuery,
              onSearchSubmit: handleSarchSubmit,
              onQueryChange: handleQueryChange,
            }}
            transferSourceData={{
              sourceTitle: <IntlMessages id="text.sourceCategoriesLabel" />,
              targetTitle: <IntlMessages id="text.selectedCategoriesLabel" />,
              sourceList: searchList,
              targetList: state.targetList,
              onChange: handleOnTransferChange,
              selectAll: true,
              unselectAll: true,
              tooltip: {
                placement: 'right',
              },
            }}
            onOriginChange={handleOriginChange}
            onTimeFrameChange={handleTimeFrameChange}
            onMerchantChange={handleMerchantChange}
            refreshFilter={handleRefreshFilter}
            clearFilter={!tempHidded ? handleClearFilter : null}
            className={filterFetching ? 'disabled' : ''}
            loading={filterFetching}
          />
        ),
      }}
      main={{
        fluid: true,
        className: 'container-home',
        content: <Body
          loading={{
            charts: message.fetching || eventMessageType.searching,
            logs: tableBodyLoader,
          }}
          table={{
            useTablePagination: true,
            list: getTableRows(),
            total: state.tableTotal,
            extraActions: (
              <ExtraActions
                disabled={filterFetching || tableBodyLoader}
                className="extra-actions-catalog text-nowrap"
                selectClassName="extra-actions-main-wrapper"
                staticLabel={<IntlMessages id="catalog.table.extarActions.label.filterBy" />}
                errorLevel={{
                  name: 'errorLevel',
                  onChange: handleErrorLevelChange,
                  options: errorLevelList,
                  staticLabelId: 'catalog.table.extarActions.label.errorLevel',
                  size: 'xs',
                  value: state.errorLevel.value,
                }}
                toggler={{
                  className: 'toggler-secondary',
                }}
                extraList={[{
                  value: 'filter',
                  className: 'enabled-content',
                  content: (
                    <Select
                      className="extra-actions-main-wrapper"
                      size="xs"
                      value={state.logType}
                      staticLabel={<IntlMessages id="catalog.table.extarActions.label.entityType" />}
                      name="sortBy"
                      options={[...sortBy, ...state.extraSortOptions]}
                      onChange={handleSortByOptionClick}
                    />
                  ),
                  disabled: true,
                }]}
              />
            ),
          }}
          chartList={message.list}
          logType={state.logType}
          onLogTypeChange={handleLogTypeChange}
          onTableCellTitleClick={handleTableCellTitleClick}
          onPaginationChange={handlePaginationChange}
          pagination={state.pagination}
          chart={{
            selected: state.selectedChart,
            list: message.list,
            onDelete: hadleChartDelete,
            onClick: handleChartClick,
          }}
        />,
      }}
    />
  );
};

Catalog.propTypes = propTypes;
Catalog.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Catalog));
