import { isEmpty } from 'lodash';

export const getFullTextSearch = (query) => ({
  full_text: {
    fields: [
      'name.en',
    ],
    value: query,
    match_type: 'must',
  },
  pagination: {
    page: 1,
    limit: 500,
  },
});

export const getGroupFilter = ({
  field, list, operator, valueIsString,
}) => list && !isEmpty(list) && list.map((item) => ({
  field,
  value: valueIsString ? item : item.value,
  operator,
}));

export default {
  getFullTextSearch,
  getGroupFilter,
};
