import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  PHYSICAL_CATEGORY_SEARCH_BY_IDS_START,
  PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED,
  PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START,
  PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED,
} from '../actions/types';

const initialState = {};
const physicalCategoryRestTypes = restTypes('physical_category');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case physicalCategoryRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
        ...action.payload,
      };
    case physicalCategoryRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
        ...action.payload,
      };
    case physicalCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.searchAllStart:
      return {
        ...state,
        ...restStates.searchAllStart,
        ...action.payload,
      };
    case physicalCategoryRestTypes.searchAllFinished:
      return {
        ...state,
        ...restStates.searchAllFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case PHYSICAL_CATEGORY_SEARCH_BY_IDS_START:
    case PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START:
      return {
        ...state,
        ...action.payload,
      };
    case PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED:
    case PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case physicalCategoryRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restStates.clearStateFinished,
      };
    default: return state;
  }
};
