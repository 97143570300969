import React from 'react';
import { WarningRounded, ErrorRounded } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

export default (level) => {
  switch (level.toLowerCase()) {
    case 'critical':
      return (
        <Tooltip title={level}>
          <ErrorRounded style={{ fill: '#f45145' }} />
        </Tooltip>
      );
    case 'warning':
      return (
        <Tooltip title={level}>
          <WarningRounded style={{ fill: '#FF9800' }} />
        </Tooltip>
      );

    default:
      return null;
  }
};
