/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import exluedAggregationTypeSet from '../../../../../utils/stash/exluedAggregationTypeSet';
import getUniqArrayOfObjects from '../../../../../utils/getUniqArrayOfObjects';

const useAttribute = (state, props) => {
  const isMerchant = window.location.pathname.includes('merchant');

  const messages = [...state.messageFilter];

  const searchFilter = {
    aggregations: [],
    fields: [],
    filter: [...state.generalFilter.filter],
    pagination: state.pagination,
    logType: state.logType,
  };

  const filter = [];
  const fetchList = (groupFilter = []) => {
    filter.push({
      condition: 'and',
      group: [
        ...groupFilter,
      ],
    });
    if (!isEmpty(state.errorLevelFilterGroup)) {
      filter.push(state.errorLevelFilterGroup);
    }

    if (!isEmpty(state.messageAttributeAggregation) && !isEmpty(state.aggregationType)) {
      if (!isEmpty(state.paginationDirectionKey)) {
        searchFilter.aggregations = state.messageAttributeAggregation.map((mA) => ({
          type: 'composite',
          bucket: mA.field,
          size: state.pagination.limit,
          sources: [
            {
              field: mA.field,
              order: 'desc',
              operator: mA.operator,
            },
          ],
          after: state.paginationDirectionKey === 'after'
            ? props.attribute[state.paginationDirectionKey]
            : props.attribute.beforeKeys[props.log.beforeKeys.length - 2],
        }));
      } else {
        searchFilter.aggregations = state.messageAttributeAggregation.map((mA) => ({
          type: 'composite',
          bucket: mA.field,
          size: state.pagination.limit,
          sources: [
            {
              field: mA.field,
              order: 'desc',
              operator: mA.operator,
            },
          ],
        }));
      }
    }

    const mappedFilter = getUniqArrayOfObjects([...searchFilter.filter, ...filter].flat());
    const isExtraFetch = !exluedAggregationTypeSet.has(state.aggregationType.value);
    searchFilter.filter = mappedFilter;
    props.fetchAttributeAggregationList({ ...searchFilter, isExtraFetch });
  };

  useEffect(() => {
    props.setAttributeBeforeKey({
      data: props.attribute.after,
      directionKey: state.paginationDirectionKey,
    });
  }, [
    state.pagination,
  ]);

  useEffect(() => {
    if (state.pagination && !isEmpty(state.messageAttributeAggregation) && isMerchant) {
      fetchList([...messages].flat());
    }
  }, [
    state.pagination,
    state.errorLevelFilterGroup,
    state.messageAttributeAggregation,
  ]);

  useEffect(() => {
    const { fetched, list } = props.attributeOption;
    if (fetched && isMerchant && !isEmpty(list)) {
      props.updateAttributeList(list, state.selectedAttribute);
    }
    if (fetched && isMerchant && isEmpty(list) && !isEmpty(props.attribute.list)) {
      const newAttributeList = props.attribute.list.map((al) => ({ ...al, loading: false }));
      props.replaceAttributeList(newAttributeList);
    }
  }, [
    props.attributeOption.fetched,
  ]);
};

export default useAttribute;
