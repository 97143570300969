import axios from 'axios';
import { camelizeKeys } from 'humps';
import { isEmpty, uniq, uniqBy } from 'lodash';

import RestActions from '../utils/rest/actions';
import {
  FETCH_ROOT_SCOPE_CATEGORIES_START,
  FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
  FETCH_CHILD_SCOPE_CATEGORIES_START,
  FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
  SEARCH_SCOPE_CATEGORIES_START,
  SEARCH_SCOPE_CATEGORIES_FINISHED,
  CLEAR_SCOPE_CATEGORIES_STATE,
  CLEAR_FETCH_SCOPE_CATEGORY_STATE,
  TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE,
  HANDLE_LOAD_SCOPE_SUBCATEGORIES,
  CLEAR_SCOPE_CATEGORY_TREE_STATE,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
  FETCH_PARENT_CATEGORIES_BY_IDS_START,
  FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED,
} from './types';

import {
  getCategoriesTreePath,
  getCategoryTreePath,
  getCategoriesSearchPath,
  getCategoryPath,
  getCategoriesExpandTreePath,
} from '../utils/paths';
import { mapObjectToArray } from '../utils/objectsToArray';
import handleError from '../utils/handleError';

const rootScopeCategoriesActions = new RestActions('scope_category');

export const fetchRootScopeCategories = (cb) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch({ type: FETCH_ROOT_SCOPE_CATEGORIES_START });
  const path = `${getCategoriesTreePath(cdms.apiPath)}`;

  return axios.get(path).then((res) => {
    const rootCategories = camelizeKeys(res.data.data);

    dispatch({
      type: FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
      payload: { list: rootCategories },
    });
    if (cb) cb();
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  });
};

export const clearFetchScopeCategoryState = () => (dispatch) => {
  dispatch({ type: CLEAR_FETCH_SCOPE_CATEGORY_STATE });
};

export const fetchChildScopeCategories = (rootId) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch({ type: FETCH_CHILD_SCOPE_CATEGORIES_START });
  return axios.get(getCategoryTreePath(cdms.apiPath, rootId)).then((res) => {
    dispatch({
      type: FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
      payload: { list: camelizeKeys(res.data.data) },
    });
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  });
};

export const clearScopeCategoriesState = () => (dispatch) => {
  dispatch({ type: CLEAR_SCOPE_CATEGORIES_STATE });
};

export const clearSearchListState = () => (dispatch) => {
  dispatch(rootScopeCategoriesActions.clearSearchListFinished());
};

export const searchScopeCategories = (query, cb) => async (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  const idsFilter = !isEmpty(query.filter)
    ? query.filter.find((f) => f.group.some((g) => g.field === 'id'))
    : [];

  let pages = [0];
  const pagination = {
    limit: 100,
  };
  if (!isEmpty(idsFilter)) {
    const iDs = (idsFilter.group.find((g) => g.field === 'id') || {}).value;
    pages = Array.from(Array(Math.ceil(iDs.length / pagination.limit)).keys());
  }
  dispatch({ type: SEARCH_SCOPE_CATEGORIES_START });

  try {
    const promises = await pages.map(async (page) => {
      const requestQuery = {
        ...query,
        pagination: {
          page: page + 1,
          limit: pagination.limit,
        },
      };
      const categoriesResult = await axios.post(
        getCategoriesSearchPath(cdms.apiPath), { ...requestQuery },
      );
      const categories = camelizeKeys(categoriesResult.data.data);

      return categories;
    });

    const categoriesResult = await Promise.all(promises);
    const categories = categoriesResult.flat();

    dispatch({
      type: SEARCH_SCOPE_CATEGORIES_FINISHED,
      payload: {
        list: camelizeKeys(categories),
      },
    });
    if (cb) cb(camelizeKeys(categories));
  } catch (err) {
    const errorRes = handleError(err);
    dispatch({
      type: SEARCH_SCOPE_CATEGORIES_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  }
};

export const searchScopeCategoriesFilter = (query) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch({ type: SEARCH_VIRTUAL_CATEGORIES_FILTER_START });
  return axios.post(getCategoriesSearchPath(cdms.apiPath), query).then((res) => {
    const searchResults = mapObjectToArray(res.data.data);
    dispatch({
      type: SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
      payload: {
        list: camelizeKeys(searchResults),
      },
    });
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  });
};

export const fetchOne = (id) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch(rootScopeCategoriesActions.fetchStart());
  return axios.get(getCategoryPath(cdms.apiPath, id)).then((res) => {
    dispatch(rootScopeCategoriesActions.fetchFinished({
      item: camelizeKeys(res.data.data),
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(rootScopeCategoriesActions.fetchFinished({
      error: errorRes, hasError: true,
    }));
  });
};

export const onToggleScopeCategoryTreeCollapse = (collapse) => (dispatch) => {
  dispatch({ type: TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE, payload: collapse });
};

export const handleLoadScopeSubcategories = (loadedKeys) => (dispatch) => {
  dispatch({ type: HANDLE_LOAD_SCOPE_SUBCATEGORIES, payload: { loadedKeys } });
};

export const clearScopeCategoryTreeState = () => (dispatch) => {
  dispatch({ type: CLEAR_SCOPE_CATEGORY_TREE_STATE });
};

export const clearFromScopeCategoryState = (payload) => (dispatch) => {
  dispatch(rootScopeCategoriesActions.clearFromStateFinished(payload));
};


export const loadAllChildCategories = (parent, expand, cb) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch({ type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START });
  const path = `${getCategoriesExpandTreePath(cdms.apiPath, parent.id)}?expand=${expand}`;
  return axios.get(path).then((res) => {
    const result = camelizeKeys(res.data.data);
    const ids = [];
    const categories = [];
    const totalArray = [parent];

    const getChildrenItems = (array) => {
      const resp = array.map((arrayItem) => {
        totalArray.push(arrayItem);

        if (arrayItem.children) {
          getChildrenItems(arrayItem.children);
        }

        return arrayItem;
      });
      return resp;
    };

    getChildrenItems(result);

    totalArray.map((category) => {
      ids.push(category.id);
      categories.push(category);

      return category;
    });

    if (cb) cb(ids);

    dispatch({
      type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
      payload: { parentWidthChildren: camelizeKeys(categories) },
    });
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  });
};

export const fetchScopeCategoriesByIds = (ids) => (dispatch, getState) => {
  const { cdms } = getState().settings.serviceConfigs;
  dispatch({
    type: FETCH_SCOPE_CATEGORIES_BY_IDS_START,
    payload: {
      fetchingScopeCategoriesByIds: true,
      fetchedScopeCategoriesByIds: false,
    },
  });
  const filter = [{
    group: [{
      field: 'id',
      value: uniq(ids),
      operator: 'in',
    }],
  }];

  return axios.post(getCategoriesSearchPath(cdms.apiPath), { filter }).then((res) => {
    dispatch({
      type: FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
      payload: {
        categoriesByIds: camelizeKeys(res.data.data),
        fetchingScopeCategoriesByIds: false,
        fetchedScopeCategoriesByIds: true,
      },
    });
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
      payload: {
        error: errorRes, hasError: true,
      },
    });
  });
};

export const fetchRecursiveParentCategories = (cIds) => async (dispatch, getState) => {
  const pagination = {
    limit: 100,
  };
  const pages = Array.from(Array(Math.ceil(cIds.length / pagination.limit)).keys());
  const { treeItems } = getState().scopeCategory;
  const { cdms } = getState().settings.serviceConfigs;

  dispatch({ type: FETCH_PARENT_CATEGORIES_BY_IDS_START });

  const promises = await pages.map(async (page) => {
    const query = {
      filter: [{
        group: [{
          field: 'id',
          value: uniq(cIds),
          operator: 'in',
        }],
      }],
      pagination: {
        page: page + 1,
        limit: pagination.limit,
      },
    };
    const categoriesResult = await axios.post(getCategoriesSearchPath(cdms.apiPath), { ...query });
    const categories = camelizeKeys(categoriesResult.data.data);

    return categories;
  });

  const categoriesResult = await Promise.all(promises);
  const categories = categoriesResult.flat();

  const ids = categories.filter((c) => c.pathByCategoryId).map((c) => {
    const rootCategoryId = c.pathByCategoryId.substr(0, c.pathByCategoryId.indexOf('/'));
    return rootCategoryId;
  });

  const rootPromises = await uniq(ids).map(async (id) => {
    const rootCategoriesResult = await axios.get(`${getCategoriesExpandTreePath(cdms.apiPath, id)}?expand=3`);
    const rootCategories = camelizeKeys(rootCategoriesResult.data.data);

    return rootCategories;
  });

  const rootCategoriesResult = await Promise.all(rootPromises);
  const totalRootResult = rootCategoriesResult.flat();
  const totalArray = [];

  const getChildrenItems = (array) => {
    const resp = array.map((arrayItem) => {
      totalArray.push(arrayItem);

      if (arrayItem.children) {
        getChildrenItems(arrayItem.children);
      }

      return arrayItem;
    });
    return resp;
  };

  getChildrenItems(totalRootResult);

  const treePayload = uniqBy(
    [...totalArray, ...treeItems],
    'id',
  );

  const filtered = treePayload.filter((c) => {
    const childrenCategoriesFirstLevel = treePayload
      .filter((cat) => cat.parentId === c.id);
    const childrenCategorySecondLevel = childrenCategoriesFirstLevel
      ? treePayload.filter((cat) => childrenCategoriesFirstLevel
        .some((item) => cat.parentId === item.id))
      : null;
    const childrenCategoryThirdLevel = childrenCategorySecondLevel
      ? treePayload.filter((cat) => childrenCategorySecondLevel
        .some((item) => cat.parentId === item.id))
      : null;
    const includedItems = [
      ...childrenCategoriesFirstLevel,
      ...childrenCategorySecondLevel,
      ...childrenCategoryThirdLevel,
    ].filter((cat) => cIds.includes(cat.id));
    const shouldBeCollapsed = !isEmpty(includedItems);
    return shouldBeCollapsed;
  });
  const collapsePayload = uniq(filtered.map((c) => c.id));
  const filteredtree = uniqBy([
    ...treePayload.filter((c) => filtered.some((cat) => c.parentId === cat.id)),
    ...treeItems,
  ], 'id');

  dispatch({
    type: FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED,
    payload: {
      treeItems: filteredtree,
    },
  });
  await dispatch(handleLoadScopeSubcategories(collapsePayload));
  await dispatch(onToggleScopeCategoryTreeCollapse(collapsePayload));
};

export default {
  fetchRootScopeCategories,
  fetchChildScopeCategories,
  clearScopeCategoriesState,
  fetchOne,
  onToggleScopeCategoryTreeCollapse,
  handleLoadScopeSubcategories,
  loadAllChildCategories,
};
