import React from 'react';
import {
  number, oneOfType, string, node,
} from 'prop-types';

const StatisticTitle = (props) => {
  const { title, precision } = props;
  if (precision && title.length >= precision) {
    return (
      <div className="title-cutted" title={title}>
        {`${title.substring(0, precision)}...`}
      </div>
    );
  }
  return <div className="clamp-module fade">{title}</div>;
};

StatisticTitle.propTypes = {
  precision: number,
  title: oneOfType([string, node]),
};

StatisticTitle.defaultProps = {
  precision: 0,
  title: null,
};

export default StatisticTitle;
