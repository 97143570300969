import _ from 'lodash';
import { defaultPagination } from './defaultData';

export default (data) => {
  const ids = data.filter((qI) => qI.includes('ctgr_'));
  const query = data.filter((qI) => !qI.includes('ctgr_'));
  const queryIn = ids && !_.isEmpty(ids) ? ({
    field: 'id',
    value: ids.map((c) => c),
    operator: 'in',
  }) : [];

  return ({
    filter: [{
      condition: 'or',
      group: [
        queryIn,
        ...query.map((c) => ({
          field: 'name.en.keyword',
          value: `*${c.toLowerCase()}*`,
          operator: 'like',
        })),
      ].flat(),
    }],
    pagination: data.pagination || defaultPagination,
  });
};
