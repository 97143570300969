import React from 'react';
import {
  node, string, oneOfType, number,
} from 'prop-types';

const Statistic = (props) => {
  const {
    className, title, suffix, prefix, precision, value, type, valueSuffix,
  } = props;
  return (
    <div className={`statistic text-${type || 'default'} ${className}`}>
      {title && <div className="statistic-title">{title}</div>}
      {value && (
        <div className="statistic-value-container">
          {prefix && <div className="statistic-prefix">{prefix}</div>}
          <div className="statistic-value">
            {
              precision && !Number.isInteger(value)
                ? `${value.toFixed(precision)}${valueSuffix}`
                : `${value}${valueSuffix}`
            }
          </div>
          {suffix && <div className="statistic-suffix">{suffix}</div>}
        </div>
      )}
    </div>
  );
};

Statistic.propTypes = {
  className: string,
  valueSuffix: string,
  type: string,
  title: oneOfType([node, string]),
  prefix: oneOfType([node, string]),
  suffix: oneOfType([node, string]),
  value: number,
  precision: number,
};

Statistic.defaultProps = {
  className: '',
  valueSuffix: '',
  type: '',
  title: null,
  prefix: null,
  suffix: null,
  value: null,
  precision: 0,
};

export default Statistic;
