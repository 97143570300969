import { useState } from 'react';

export const useStateHook = (props) => {
  const [targetList, setTargetList] = useState(props.targetList);
  const [sourceList, setSourceList] = useState(props.sourceList);

  const state = {
    targetList,
    sourceList,
  };

  const setState = {
    setTargetList,
    setSourceList,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
