export default (key) => {
  switch (key) {
    case 'last_6_hours':
    case 'last_24_hours':
      return 'HH:00';
    case 'last_7_days':
      return 'ddd';
    case 'last_30_days':
      return 'D MMM';
    case 'last_3_months':
      return 'MMM D';

    default:
      return 'HH:00';
  }
};
