import React from 'react';
import {
  arrayOf, string, shape, func, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import IntlMessages from '../../../utils/IntlMessages';
import getIcon from '../../../utils/getIcon';


const TableHeader = (props) => (
  <thead className={`MuiTableHead-root ${props.disabled ? 'disabled' : ''}`}>
    <tr>
      <td rowSpan="1" />
      <th colSpan={props.list.length} className="table-cell">
        <IntlMessages id="productsHistory.table.header.title.prodCount" />
        {false && (
          <Button
            onClick={props.onGetDeltaClick}
            color="primary"
            className="btn-xs float-right ml10"
          >
            <IntlMessages id="productsHistory.table.header.title.delta" />
          </Button>
        )}
      </th>
    </tr>
    <tr>
      {props.list && !isEmpty(props.list) && (
        props.list.map((th, index) => {
          const isCurrentCell = !isEmpty(props.selectedColumn.origDate)
            && props.selectedColumn.origDate === th.origDate;

          const activeClass = isCurrentCell ? 'table-cell-active' : '';
          const className = `cell-${th.value} ${index > 0 ? 'text-center' : ''} ${th.className || 'table-cell-hover'} ${activeClass}`;
          return (
            <th
              className={`table-cell text-left ${className}`}
              key={th.value}
              onClick={() => props.onClick({ ...th, index })}
            >
              <span className="table-cell-label">{th.label}</span>
              {
                isCurrentCell && (
                  <span className="table-cell-icon">{getIcon(props.selectedColumn.order || 'desc')}</span>
                )
              }
            </th>
          );
        })
      )}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  list: arrayOf(shape({
    value: string,
    label: string,
  })).isRequired,
  onClick: func,
  onGetDeltaClick: func,
  selectedColumn: shape(),
  disabled: bool,
};

TableHeader.defaultProps = {
  onClick: null,
  onGetDeltaClick: null,
  selectedColumn: {},
  disabled: false,
};

export default TableHeader;
