import React from 'react';

import Layout from '../../../../components/Layout';
import List from '../../../../components/List';
import { headerList } from '../../../utils/headerList';
import Body from './containers';

const Details = () => (
  <Layout
    horizontalMenu
    header={{
      className: 'header-fixed',
      subHeaderContent: (
        <List
          dir="horizontal"
          className="justify-center list-lg"
          dataSource={headerList}
        />
      ),
    }}
    main={{
      fluid: true,
      className: 'container-details',
      content: <Body />,
    }}
  />
);

export default Details;
