import React from 'react';
import { isEmpty } from 'lodash';
import {
  shape, string, func, oneOfType,
} from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Group from '../../../components/Group';

const TableCell = (props) => {
  const {
    cells,
    pathByName,
    id,
    type,
    logType,
    urlKey,
  } = props.sourceData;

  const getTooltipTitle = (cell) => {
    if (pathByName.en || pathByName.he) {
      return (
        <Tooltip
          title={(
            <div className="tooltip-title">
              <span>{pathByName.en}</span>
              <span className="tooltip-title">{`(${pathByName.he})`}</span>
            </div>
          )}
          placement="top-start"
        >
          <div className="tooltip-inner-wrapper flex items-center">
            {(cell.value.en) && <Group className="group-primary">{cell.value.en}</Group>}
            {cell.value.he && <Group className="group-he" secondary>{cell.value.he}</Group>}
          </div>
        </Tooltip>
      );
    }
    if (
      !cell.value.en && !cell.value.he
      && (
        !isEmpty(logType) && logType.value === 'navigation'
      )
    ) {
      return (
        <Tooltip
          title={(
            <div className="tooltip-title">
              {type && <div className="tooltip-title">{`Type: ${type}`}</div>}
              {urlKey && <div className="tooltip-title">{`Url key: ${urlKey}`}</div>}
            </div>
          )}
          placement="top-start"
        >
          <div className="tooltip-inner-wrapper flex items-baseline">
            {(cell.value) && <Group className="group-primary">{typeof cell.value === 'string' ? cell.value : id }</Group>}
            {id && <Group className="group-he" secondary>{id}</Group>}
          </div>
        </Tooltip>
      );
    }
    return (
      <div className="tooltip-inner-wrapper flex items-center">
        {(cell.value.en) && <Group className="group-primary">{cell.value.en}</Group>}
        {cell.value.he && <Group className="group-he" secondary>{cell.value.he}</Group>}
      </div>
    );
  };

  const handleTableRowClick = () => props.onTableRowClick(props.sourceData);
  const getGroup = (cell) => {
    switch (cell.cellType) {
      case 'date':
        return <Group secondary>{!isEmpty(cell.virtualCategory) ? cell.virtualCategory.countProducts : '-'}</Group>;
      case 'shapeLang':
        return getTooltipTitle(cell);
      default:
        return <Group secondary>{cell.value || cell.value === 0 ? cell.value : '-'}</Group>;
    }
  };

  return (
    <tr
      className={`MuiTableRow-root tr-wrapper hover-pointer ${props.className}`}
      onClick={handleTableRowClick}
    >
      {cells.map((cell, index) => {
        const isCurrentCell = !isEmpty(props.selectedColumn.value)
          && props.selectedColumn.value === cell.value;

        const activeClass = isCurrentCell ? 'table-cell-active' : '';
        const textCenterClass = index > 0 ? 'text-center' : '';
        return (
          <td
            key={`${cell ? cells.indexOf(cell) : `${new Date().getTime()}_${Math.random()}`}`}
            className={`MuiTableCell-root table-cell ${textCenterClass} ${activeClass}`}
          >
            {cell ? getGroup(cell) : '-'}
          </td>
        );
      })}
    </tr>
  );
};

TableCell.propTypes = {
  sourceData: shape({
    sqsId: string,
    id: string,
    pathByName: shape({
      he: string,
      en: string,
    }),
    name: oneOfType([shape(), string]),
  }).isRequired,
  onTableRowClick: func,
  className: string,
  selectedColumn: shape().isRequired,
};

TableCell.defaultProps = {
  onTableRowClick: null,
  className: '',
};

export default TableCell;
