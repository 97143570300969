import React from 'react';
import {
  oneOf, number, func, string, arrayOf, shape, bool,
} from 'prop-types';

import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

const Head = (props) => {
  const {
    onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, checkbox,
    sortable, hideColumns, disabledCheckbox,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkbox.visible && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              className={`${checkbox.className} ${disabledCheckbox ? 'disabled' : ''}`}
              color={checkbox.color}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          !hideColumns.some((columnId) => columnId === headCell.id) && (
            <TableCell
              className={`${sortable ? '' : 'events-no'} ${headCell.className || ''}`}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
};

Head.propTypes = {
  hideColumns: arrayOf(string).isRequired,
  disabledCheckbox: bool,
  sortable: bool,
  numSelected: number.isRequired,
  onRequestSort: func.isRequired,
  onSelectAllClick: func.isRequired,
  order: oneOf(['asc', 'desc']).isRequired,
  orderBy: string.isRequired,
  rowCount: number,
  headCells: arrayOf(shape({
    id: string,
  })),
  checkbox: shape({
    className: string,
    color: string,
  }).isRequired,
};

Head.defaultProps = {
  rowCount: null,
  headCells: [],
  sortable: false,
  disabledCheckbox: false,
};

export default Head;
