export default [{
  value: 'byAttributesOrigin',
  content: 'Attribute origin',
  label: 'Attribute origin',
  id: 'not_mapped_attribute_exception',
  subAggregationPrefix: 'properties.attributes_origin',
  field: 'properties.attributes_orig_list',
  fieldOrig: 'properties.attributes_orig_list',
}, {
  value: 'byAttributes',
  content: 'Attributes',
  label: 'Attributes',
  id: 'not_mapped_attribute_value_exception',
  subAggregationPrefix: 'properties.attributes',
  field: 'properties.attributes_list',
  fieldOrig: 'properties.attributes_list',
}];
