import {
  shape, bool, arrayOf, string, number, func, oneOfType,
} from 'prop-types';

export const propTypes = {
  detailsLogMessage: shape({
    list: arrayOf(shape({
      id: oneOfType([string, number]),
    })),
    fetching: bool,
  }).isRequired,
  log: shape({
    item: shape({
      name: oneOfType([string, number]),
    }),
  }).isRequired,
  attribute: shape({
    item: shape({
      name: oneOfType([string, number]),
    }),
  }).isRequired,
  detailsLog: shape({
    total: number,
    item: shape({
      name: oneOfType([string, number]),
    }),
  }).isRequired,
  updateLog: func.isRequired,
  onApplyClick: func,
  updateLogMultiple: func.isRequired,
  fetchAttributeAggregationList: func.isRequired,
};

export const defaultProps = {
  onApplyClick: null,
};

export default {
  propTypes,
  defaultProps,
};
