/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import eventMessageSearchParams from '../utils/eventMessageSearchParams';

const useEventMessageType = (props) => {
  useEffect(() => {
    const fetchList = async () => {
      await props.searchEventMessages(eventMessageSearchParams);
    };
    fetchList();
  }, []);
};

export default useEventMessageType;
