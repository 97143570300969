import {
  func, shape, string, arrayOf, bool, oneOfType, number,
} from 'prop-types';

export const propTypes = {
  history: shape().isRequired,
  settings: shape({
    sourceOptions: arrayOf(shape({
      label: string,
      value: string,
    })),
    timeFrameOptions: arrayOf(shape({
      label: string,
      value: string,
    })),
  }).isRequired,
  merchant: shape({
    list: arrayOf(shape({
      label: oneOfType([string, number]),
      value: oneOfType([string, number]),
    })),
  }).isRequired,
  message: shape({
    list: arrayOf(shape({
      label: string,
      value: string,
    })),
  }).isRequired,
  physicalCategory: shape({
    searchList: arrayOf(shape({
      id: string,
    })),
    searching: bool,
    searched: bool,
  }).isRequired,
  log: shape({
    fetching: bool,
  }).isRequired,
  clearFromLogState: func.isRequired,
  searchPhysicalCategories: func.isRequired,
  fetchMerchantList: func.isRequired,
  clearFromMerchantState: func.isRequired,
  searchEventMessages: func.isRequired,
  clearFromEventMessageState: func.isRequired,
  fetchAggregatedLogList: func.isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
