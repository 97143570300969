import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

import {
  string, array, number, oneOfType, func, node,
} from 'prop-types';
import IntlMessages from '../../utils/IntlMessages';

const List = ({
  className, dataSource, type, gap, size, dir, activeClassName, first, last,
}) => {
  const listType = type ? ` list-${type}` : '';
  const listDir = dir ? ` list-${dir}` : '';
  const listSize = size ? ` list-${size}` : '';
  const listGap = gap ? ` list-${gap}` : '';
  return (
    <nav className="list-wrapper">
      <ul className={`list ${className}${listType}${listGap}${listSize}${listDir}`}>
        {first}
        {dataSource && dataSource.length > 0 && dataSource.map((item) => {
          const itemLinkContent = (
            item.notifications
              ? (
                <div className={`list-item-badge-wrapper ${item.className}`}>
                  <Badge
                    className={`badge-tr ${item.badgeClassName}`}
                    badgeContent={item.notifications}
                    color="primary"
                  >
                    {item.icon}
                    {!item.hideLabel && <IntlMessages id={item.valueId} />}
                  </Badge>
                </div>
              )
              : (
                <>
                  {item.icon}
                  {
                    item.ignoreTranslation
                      ? item.value
                      : !item.hideLabel && <IntlMessages id={item.valueId} />
                  }
                </>
              )
          );
          return (
            <li key={item.key || item.id} className={`list-item-wrapper ${item.className || ''}`}>
              {activeClassName
                ? (
                  <Link onClick={item.onClick} to={item.route} className="list-item-link">
                    {itemLinkContent}
                  </Link>
                )
                : (
                  <NavLink
                    exact
                    onClick={item.onClick}
                    to={item.route}
                    className="list-item-link"
                    activeClassName={item.activeClassName}
                  >
                    {itemLinkContent}
                  </NavLink>
                )}
            </li>
          );
        })}
        {last}
      </ul>
    </nav>
  );
};

List.propTypes = {
  className: string,
  activeClassName: string,
  first: node,
  last: node,
  size: string,
  dir: string,
  type: string,
  dataSource: oneOfType([array, func]),
  gap: number,
};

List.defaultProps = {
  className: '',
  first: null,
  last: null,
  activeClassName: '',
  type: '',
  dir: '',
  size: '',
  dataSource: null,
  gap: null,
};

export default List;
