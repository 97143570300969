import React from 'react';
import {
  func, shape, bool, number, arrayOf, string,
} from 'prop-types';
import { isEmpty } from 'lodash';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import getNewSelected from './utils/getNewSelected';

const Body = (props) => {
  const {
    row, selected, index, isSelected, checkbox, hideColumns,
    selectedRowId, className, columnsWithExtraAction,
  } = props;
  const handleCheckClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { id } = row;
    const newSelected = getNewSelected({ selected, id });
    props.onCheckClick(newSelected);
  };

  const handleRowClick = () => {
    props.onRowClick(row);
  };

  const onTableCellClick = (event, columnKey) => {
    if (columnsWithExtraAction.some((cell) => cell === columnKey)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const labelId = `enhanced-table-checkbox-${index}`;
  const cursorClassName = props.onRowClick ? 'hover-pointer' : '';
  const rowActiveClass = selectedRowId && row.id === selectedRowId ? 'table-row-active' : '';

  return (
    <TableRow
      hover
      role="checkbox"
      className={`${className} ${cursorClassName} ${rowActiveClass} ${row.className || ''}`}
      aria-checked={isSelected}
      tabIndex={-1}
      key={row.id}
      selected={isSelected}
      onClick={handleRowClick}
    >
      {checkbox.visible && (
        <TableCell padding="checkbox">
          <Checkbox
            onClick={handleCheckClick}
            checked={isSelected}
            className={checkbox.className}
            color={checkbox.color}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
      )}
      {row && !isEmpty(row) && Object.keys(row).map((key) => (
        !hideColumns.some((columnId) => columnId === key) && (
          <TableCell key={key} onClick={(e) => onTableCellClick(e, key)}>
            {row[key]}
          </TableCell>
        )
      ))}
    </TableRow>
  );
};

Body.propTypes = {
  className: string.isRequired,
  selectedRowId: string.isRequired,
  index: number.isRequired,
  row: shape().isRequired,
  checkbox: shape({
    className: string,
    color: string,
    visible: bool,
  }).isRequired,
  columnsWithExtraAction: arrayOf(string).isRequired,
  hideColumns: arrayOf(string).isRequired,
  selected: arrayOf(string).isRequired,
  isSelected: bool.isRequired,
  onRowClick: func.isRequired,
  onCheckClick: func.isRequired,
};

export default Body;
