import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import { CLEAR_USER_STATE } from '../actions/types';

const initialState = {};
const userRestTypes = restTypes('user');
const {
  fetchAllStart,
  fetchAllFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
  createStart,
  createFinished,
} = userRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        list: action.payload,
      };
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };
    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        hasError: action.payload && action.payload.hasError ? action.payload.hasError : false,
      };
    case updateStart:
      return {
        ...state,
        ...restStates.updateStart,
      };
    case updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        hasError: action.payload ? action.payload.hasError : false,
      };

    case updateMultipleStart:
      return {
        ...state,
        ...restStates.updateMultipleStart,
      };

    case updateMultipleFinished:
      return {
        ...state,
        ...restStates.updateMultipleFinished,
        hasError: action.payload.hasError,
      };

    case CLEAR_USER_STATE:
      return {
        ...initialState,
        ...restInitState,
      };

    default: return state;
  }
};
