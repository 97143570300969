import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
} from '../utils/paths';
import handleError from '../utils/handleError';

const logActions = new RestActions('details_log');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
} = logActions;

export const fetchDetailsLogList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => (dispatch, getState) => {
  const { searchList } = getState().eventMessageType;
  const { monitoring } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  const getEventMessage = (messageId) => {
    const messageItem = searchList.find((l) => l.messageId === messageId);
    return !messageItem ? messageId : messageItem;
  };

  dispatch(fetchAllStart());
  return axios.post(
    `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
    decamelizeKeys(body),
  ).then((res) => {
    const logList = res.data.data;

    const mappedList = logList && !isEmpty(logList) ? logList.map((log) => ({
      ...log,
      message: getEventMessage(log.message_id),
    })) : [];

    dispatch(fetchAllFinished({
      list: camelizeKeys(mappedList),
      total: res.data.total,
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
    }));
  });
};

export const setDetailsLogList = (list) => (dispatch) => {
  dispatch(fetchAllFinished({ list }));
};

export const setAggregation = (data) => (dispatch) => {
  dispatch(fetchAllFinished({ data }));
};

export const clearFromDetailsLogState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
