import { isEmpty } from 'lodash';
import getLogType from '../../../utils/getLogType';
import getDateRangeFilter from './getDateRangeFilter';
import { mappingFields } from '../../../utils/variables';

export default (values, props) => {
  if (!isEmpty(values)) {
    const {
      origins, timeFrameValue, messages, originValue,
    } = values;

    const logTypesSearcFilter = {
      condition: 'and',
      group: [{
        field: 'log_type',
        value: getLogType(),
        operator: 'eq',
      }],
    };

    const timeRangeFilter = [{
      condition: 'and',
      group: getDateRangeFilter(timeFrameValue),
    }];

    const sourceOption = props.settings.sourceOptions.find((so) => so.value === originValue);
    const originFilter = isEmpty(origins) && !isEmpty(sourceOption)
      ? [{
        condition: 'and',
        group: [{
          field: mappingFields.origin,
          value: sourceOption.label,
          operator: 'eq',
        }],
      }]
      : [];

    const messagesFilter = !isEmpty(messages)
      ? [{
        condition: 'and',
        group: messages,
      }]
      : [];

    return [
      logTypesSearcFilter,
      originFilter,
      origins,
      timeRangeFilter,
      messagesFilter,
    ].flat();
  }

  return [];
};
