import {
  shape, func, arrayOf, string, bool,
} from 'prop-types';

export const propTypes = {
  history: shape().isRequired,

  message: shape({
    list: arrayOf(shape({
      label: string,
      value: string,
    })),
  }).isRequired,

  settings: shape({
    sourceOptions: arrayOf(shape({
      label: string,
      value: string,
    })),

    timeFrameOptions: arrayOf(shape({
      label: string,
      value: string,
    })),
  }).isRequired,

  systemLog: shape({
    fetching: bool,
  }).isRequired,

  searchEventMessages: func.isRequired,
  clearFromEventMessageState: func.isRequired,

  setSystemLog: func.isRequired,
  fetchSystemLogList: func.isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
