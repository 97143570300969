export const RTL_LAYOUT = 'RTL_LAYOUT';
export const SET_LANGUAGE = 'SET_LANGUAGE';

// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RESET_USER_PASSWORD_START = 'RESET_USER_PASSWORD_START';
export const RESET_USER_PASSWORD_FINISHED = 'RESET_USER_PASSWORD_FINISHED';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const VERIFY_USER_RESET_CODE_START = 'VERIFY_USER_RESET_CODE_START';
export const VERIFY_USER_RESET_CODE_FINISHED = 'VERIFY_USER_RESET_CODE_FINISHED';
export const CONFIRM_RESET_USER_PASSWORD_START = 'CONFIRM_RESET_USER_PASSWORD_START';
export const CONFIRM_RESET_USER_PASSWORD_FINISHED = 'CONFIRM_RESET_USER_PASSWORD_FINISHED';

// user
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';

// session
export const CLEAR_SESSION_STATE = 'CLEAR_SESSION_STATE';

// pgysical category
export const PHYSICAL_CATEGORY_SEARCH_BY_IDS_START = 'PHYSICAL_CATEGORY_SEARCH_BY_IDS_START';
export const PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED = 'PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED';
export const PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START = 'PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START';
export const PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED = 'PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED';

// scope category
export const FETCH_ROOT_SCOPE_CATEGORIES_START = 'FETCH_ROOT_SCOPE_CATEGORIES_START';
export const FETCH_ROOT_SCOPE_CATEGORIES_FINISHED = 'FETCH_ROOT_SCOPE_CATEGORIES_FINISHED';
export const FETCH_CHILD_SCOPE_CATEGORIES_START = 'FETCH_CHILD_SCOPE_CATEGORIES_START';
export const FETCH_CHILD_SCOPE_CATEGORIES_FINISHED = 'FETCH_CHILD_SCOPE_CATEGORIES_FINISHED';
export const SEARCH_SCOPE_CATEGORIES_START = 'SEARCH_SCOPE_CATEGORIES_START';
export const SEARCH_SCOPE_CATEGORIES_FINISHED = 'SEARCH_SCOPE_CATEGORIES_FINISHED';
export const CLEAR_SCOPE_CATEGORIES_STATE = 'CLEAR_SCOPE_CATEGORIES_STATE';
export const CLEAR_FETCH_SCOPE_CATEGORY_STATE = 'CLEAR_FETCH_SCOPE_CATEGORY_STATE';
export const TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE = 'TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE';
export const HANDLE_LOAD_SCOPE_SUBCATEGORIES = 'HANDLE_LOAD_SCOPE_SUBCATEGORIES';
export const CLEAR_SCOPE_CATEGORY_TREE_STATE = 'CLEAR_SCOPE_CATEGORY_TREE_STATE';
export const FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START = 'FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START';
export const FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED = 'FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED';
export const SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED';
export const SEARCH_VIRTUAL_CATEGORIES_FILTER_START = 'SEARCH_VIRTUAL_CATEGORIES_FILTER_START';
export const FETCH_SCOPE_CATEGORIES_BY_IDS_START = 'FETCH_SCOPE_CATEGORIES_BY_IDS_START';
export const FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED = 'FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED';
export const FETCH_PARENT_CATEGORIES_BY_IDS_START = 'FETCH_PARENT_CATEGORIES_BY_IDS_START';
export const FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED = 'FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED';

// virtual category
export const PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START = 'PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START';
export const PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED = 'PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED';
export const PRODUCTS_HISTORY_SET_BEFORE_KEYS = 'PRODUCTS_HISTORY_SET_BEFORE_KEYS';
export const PRODUCTS_HISTORY_DELETE_BEFORE_KEYS = 'PRODUCTS_HISTORY_DELETE_BEFORE_KEYS';
export const PRODUCTS_HISTORY_SET_TO_STATE_FINISHED = 'PRODUCTS_HISTORY_SET_TO_STATE_FINISHED';

// virtual category table
export const PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN = 'PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN';
export const PRODUCTS_HISTORY_TABLE_GET_DELTA = 'PRODUCTS_HISTORY_TABLE_GET_DELTA';
export const PRODUCTS_HISTORY_TABLE_RESET_STATE = 'PRODUCTS_HISTORY_TABLE_RESET_STATE';

// log
export const LOG_SET_BEFORE_KEYS = 'LOG_SET_BEFORE_KEYS';
export const LOG_DELETE_BEFORE_KEYS = 'LOG_DELETE_BEFORE_KEYS';

// attribute
export const ATTRIBUTE_SET_BEFORE_KEYS = 'ATTRIBUTE_SET_BEFORE_KEYS';
export const ATTRIBUTE_DELETE_BEFORE_KEYS = 'ATTRIBUTE_DELETE_BEFORE_KEYS';
