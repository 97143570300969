import moment from 'moment';
import { isEmpty } from 'lodash';
import getTimeFormat from './getTimeFormat';
import combineChartDuplicates from './combineChartDuplicates';
import { aggregation } from './variables';

export default ({
  timeRange, source, timeRangeList, dataKey,
}) => {
  const format = getTimeFormat(timeRange);
  const timeRangeBuckets = !source[aggregation.timestamp]
    ? []
    : source[aggregation.timestamp].buckets;

  const rangeData = !isEmpty(timeRangeBuckets) && timeRangeBuckets.map((s) => ({
    name: moment(s.keyAsString).format(format),
    uv: 100,
    [dataKey]: s.docCount,
    amt: 0,
  }));
  const combinedData = combineChartDuplicates(rangeData, dataKey);
  const chartData = timeRangeList.map((t) => (
    combinedData.find((c) => c.name === t)
      ? combinedData.find((c) => c.name === t)
      : {
        name: t,
        uv: 0,
        [dataKey]: 0,
        amt: 0,
      }
  ));
  return chartData.reverse();
};
