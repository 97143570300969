import React from 'react';
import {
  shape, string, node,
} from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import locales from '../lang';

const ConnectedIntlProvider = (props) => {
  const { locale } = props.settings.locale;
  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={locales[locale].messages}
    >
      <>
        {props.children}
      </>
    </IntlProvider>
  );
};

ConnectedIntlProvider.propTypes = {
  settings: shape({
    locale: shape({
      locale: string,
    }),
  }).isRequired,
  children: node.isRequired,
};

const mapStateToProps = ({
  settings,
}) => ({
  settings,
});

export default connect(mapStateToProps)(ConnectedIntlProvider);
