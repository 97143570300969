import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { isEmpty } from 'lodash';

const TableBody = (props) => (
  <thead className="MuiTableHead-root">
    <tr>
      {props.list && !isEmpty(props.list) && (
        props.list.map((th) => (
          <th
            className={`table-cell text-left cell-${th.value}`}
            key={th.value}
          >
            {th.label}
          </th>
        ))
      )}
    </tr>
  </thead>
);

TableBody.propTypes = {
  list: arrayOf(shape({
    value: string,
    label: string,
  })).isRequired,
};

export default TableBody;
