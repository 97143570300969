/* eslint-disable func-names */
/* eslint-disable wrap-iife */
import { isEmpty } from 'lodash';

export default (source, dataKey) => function (array, key) {
  const r = [];
  if (!isEmpty(array)) {
    array.forEach(function (a) {
      if (!this[a.name]) {
        this[a.name] = {
          name: a.name,
          [key]: 0,
          amt: 0,
          uv: 0,
        };
        r.push(this[a.name]);
      }
      this[a.name][key] += a[key];
      this[a.name].amt += a.amt;
      this[a.name].uv += a.uv;
    }, {});
  }
  return r;
}(source, dataKey);
