import {
  shape, bool, string, func, arrayOf, node, number, oneOfType, object,
} from 'prop-types';

export const propTypes = {
  content: shape({
    noResult: bool,
    loading: bool,
    onSearchSubmit: func,
    query: string,
    valuePreloader: bool,
  }),
  popoverSettings: shape({}),
  transferSourceData: shape({
    tooltip: shape({
      placement: string,
    }),
    sourceTitle: node,
    targetTitle: node,
    sourceList: arrayOf(shape()),
    targetList: arrayOf(shape()),
    onChange: func,
    selectAll: bool,
  }),
  onSelectCategoriesOk: func,
  onSelectCategoriesCancel: func,
  onSelectCategoriesClose: func,
  sourceOptions: arrayOf(shape({
    label: string,
    value: string,
  })),
  timeFrame: shape({
    options: arrayOf(shape({
      label: string,
      value: string,
    })),
  }),
  merchant: shape({
    value: arrayOf(shape()),
    options: arrayOf(shape({
      label: oneOfType([string, number]),
      value: oneOfType([string, number]),
    })),
  }),
  dateRangePicker: shape({
    onChange: func,
    className: '',
  }),
  scopeDialog: shape({
    showScopeDialog: bool,
    collapsed: arrayOf(string),
    selectedTreeItems: arrayOf(oneOfType([object, string])),
    treeItems: arrayOf(shape({
      id: string,
    })),
    closeScopeDialog: func,
    onSubmit: func,
    toogleTreeComponents: func,
    fetchChildScopeCategories: func,
    onSearchSubmit: func,
    onToggleScopeCategoryTreeCollapse: func,
    handleLoadSubcategories: func,
    loading: bool,
    multiple: bool,
  }),
  refreshButton: shape({
    onClick: func,
    disabled: bool,
  }),
  onOriginChange: func,
  onTimeFrameChange: func,
  onMerchantChange: func,
  onMerchantSelectOpen: func,
  disabledFilterButton: bool,
  useCategory: bool,
  useMerchant: bool,
  useSource: bool,
  useTimeRange: bool,
  useDatePicker: bool,
  useScopeDialog: bool,
  clearFilter: func,
};

export const defaultProps = {
  popoverSettings: {},
  content: {
    noResult: false,
    loading: false,
    onSearchSubmit: null,
    query: '',
    valuePreloader: false,
  },
  transferSourceData: {
    tooltip: {
      placement: '',
    },
    sourceTitle: null,
    targetTitle: null,
    sourceList: [],
    targetList: [],
    onChange: null,
    selectAll: false,
  },
  merchant: {},
  timeFrame: {},
  scopeDialog: {},
  sourceOptions: [],
  dateRangePicker: {},
  onMerchantChange: null,
  onSelectCategoriesOk: null,
  onSelectCategoriesCancel: null,
  onSelectCategoriesClose: null,
  onMerchantSelectOpen: null,
  disabledFilterButton: true,
  useCategory: false,
  useMerchant: false,
  useSource: false,
  useTimeRange: false,
  useDatePicker: false,
  useScopeDialog: false,
  onOriginChange: null,
  onTimeFrameChange: null,
  clearFilter: null,
  refreshButton: {
    onClick: null,
    disabled: false,
  },
};

export default {
  propTypes,
  defaultProps,
};
