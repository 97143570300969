import React from 'react';
import {
  shape, arrayOf, string, bool, oneOfType, node,
} from 'prop-types';
import _ from 'lodash';
import Badge from '../Badge';

const Badges = (props) => {
  const { list, tooltip, placeholder } = props;
  return (
    <div className="rct-badge-list">
      {list && !_.isEmpty(list) ? list.map((badge) => (
        <Badge
          key={list.indexOf(badge)}
          tooltip={tooltip}
          badge={badge}
        />
      )) : <div className="placeholder">{placeholder}</div>}
    </div>
  );
};

Badges.propTypes = {
  tooltip: bool,
  list: arrayOf(shape({
    id: string,
  })).isRequired,
  placeholder: oneOfType([node, string]),
};

Badges.defaultProps = {
  tooltip: false,
  placeholder: null,
};

export default Badges;
