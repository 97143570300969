import React, { useState, useEffect } from 'react';
import {
  shape, bool, arrayOf, string, func, number, object, oneOfType, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { CircularProgress, TablePagination } from '@material-ui/core';

import Title from '../../../components/Title';
import IntlMessages from '../../../utils/IntlMessages';
import Skeleton from '../../../components/Skeleton';
import { defaultPagination } from '../utils/defaultData';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Table = (props) => {
  const {
    loading, list, logType, extra, total, selectedError, tablePlaceholder,
    header, pagination,
  } = props;
  const disabledClassName = loading ? 'disabled-loading' : '';
  const [rowsPerPage, setRowsPerPage] = useState(defaultPagination.limit);
  const [page, setPage] = useState(0);

  const showExtraPagination = !isEmpty(list) && list.length > 10;

  useEffect(() => {
    setRowsPerPage(props.pagination.data.limit);
    setPage(props.pagination.data.page - 1);
  }, [props.pagination.data]);

  const handleChangePage = (event, newPage) => {
    const directionKey = page > newPage ? 'before' : 'after';
    props.onPaginationChange({
      page: newPage + 1,
      limit: rowsPerPage,
    }, directionKey);
  };

  const handleChangeRowsPerPage = (event) => {
    props.onPaginationChange({
      page: 1,
      limit: parseInt(event.target.value, 10),
    });
  };

  const drawerIn = isEmpty(selectedError) ? 'table-details-closed' : 'table-details-open';
  const paginationClass = total === 0 ? 'disabled' : '';

  return (
    <div className={`table-container container container-fluid pagination-sm ${drawerIn}`}>
      <div className="container-sticky pb0">
        <Title
          secondary
          className="flex justify-between items-center first-letter-up"
          innerClassName="no-events flex"
          contentClassName="flex items-center"
          title={(
            <>
              {`${logType}`}
              {loading && <CircularProgress className="m-8-l" size={20} />}
            </>
          )}
          titleSecondary={`Showing ${list.length} issues`}
        >
          <TablePagination
            component="div"
            className={`${paginationClass} ${loading ? 'disabled' : ''}`}
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        </Title>
        {!isEmpty(list) && (
          <table className="table no-shadow table-cell-md block mt-20 cell-lg-first">
            <TableHeader
              disabled={loading}
              list={header.list}
              selectedColumn={header.selectedColumn || {}}
              onClick={header.onClick}
              onGetDeltaClick={header.onGetDeltaClick}
            />
          </table>
        )}
      </div>
      <div className={`flex flex-wrap pos-rel ${disabledClassName}`}>
        {!loading && isEmpty(list) && (
          <Skeleton
            type="tableCell"
            rowLength={5}
            message={tablePlaceholder || <IntlMessages id="productsHistory.table.placeholder.firstQuery" />}
            messageSize="sm"
          />
        )}
        {!isEmpty(list) && (
          <table className="table table-cell-md block table-with-details cell-lg-first">
            <TableBody
              extra={extra}
              list={list}
              selected={selectedError}
              onTableCellTitleClick={props.onTableCellTitleClick}
              selectedColumn={header.selectedColumn}
              onTableRowClick={props.onTableRowClick}
            />
          </table>
        )}
        {showExtraPagination && (
          <TablePagination
            component="div"
            className={`${paginationClass} ${loading ? 'disabled' : ''}`}
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  loading: bool,
  extra: shape(),
  selectedError: shape(),
  list: arrayOf(shape()),
  header: shape({
    list: arrayOf(object),
    onCellClick: func,
  }),
  logType: string,
  onTableCellTitleClick: func,
  onTableRowClick: func,
  onPaginationChange: func,
  pagination: shape({
    className: string,
    component: string,
    backIconButton: shape(),
    nextIconButton: shape(),
    rowsPerPageOptions: arrayOf(oneOfType([object, number, string])),
    data: shape({
      page: number,
      limit: number,
    }),
  }),
  total: number,
  tablePlaceholder: node,
};

Table.defaultProps = {
  loading: false,
  extra: {},
  selectedError: {},
  list: [],
  header: {
    list: [],
    onCellClick: null,
  },
  logType: 'Virtual categories',
  onTableCellTitleClick: null,
  onTableRowClick: null,
  onPaginationChange: null,
  pagination: {
    component: 'div',
    backIconButton: {
      'aria-label': 'previous page',
    },
    nextIconButton: {
      'aria-label': 'next page',
    },
    rowsPerPageOptions: null,
  },
  total: 0,
  tablePlaceholder: null,
};

export default Table;
