import {
  clearFromLogState,
  fetchAggregatedLogList,
  setLog,
  deleteLogBeforeKeys,
  setLogBeforeKey,
} from '../../../../actions/log';
import {
  searchPhysicalCategories,
  clearFromPhysicalCategoryState,
  searchPhysicalCategoriesByIds,
  searchSelectedPhysicalCategoriesByIds,
} from '../../../../actions/physicalCategory';

import { searchEventMessages, clearFromEventMessageState } from '../../../../actions/eventMessageType';
import { fetchMerchantList, clearFromMerchantState } from '../../../../actions/merchant';
import { fetchMessageList, clearFromMessageState } from '../../../../actions/message';

import {
  fetchAttributeAggregationList,
  updateAttributeList,
  replaceAttributeList,
  setAttributeBeforeKey,
  deleteAttributeBeforeKeys,
  clearFromAttributeState,
} from '../../../../actions/attribute';

import {
  fetchAttributeOptionAggregationList,
  updateAttributeOptionList,
} from '../../../../actions/attributeOption';

export const mapStateToProps = (state) => ({
  settings: state.settings,
  log: state.log,
  session: state.session,
  physicalCategory: state.physicalCategory,
  merchant: state.merchant,
  eventMessageType: state.eventMessageType,
  message: state.message,
  attribute: state.attribute,
  attributeOption: state.attributeOption,
});

export const mapDispatchToProps = {
  setLog,
  clearFromLogState,
  searchPhysicalCategories,
  clearFromPhysicalCategoryState,
  fetchMerchantList,
  clearFromMerchantState,
  searchEventMessages,
  clearFromEventMessageState,
  fetchMessageList,
  clearFromMessageState,
  fetchAggregatedLogList,
  searchPhysicalCategoriesByIds,
  searchSelectedPhysicalCategoriesByIds,
  deleteLogBeforeKeys,
  setLogBeforeKey,

  fetchAttributeAggregationList,
  updateAttributeList,
  replaceAttributeList,
  setAttributeBeforeKey,
  deleteAttributeBeforeKeys,
  clearFromAttributeState,

  fetchAttributeOptionAggregationList,
  updateAttributeOptionList,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
