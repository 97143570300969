import restTypes from './types';

export default class RestActions {
  constructor(resource) {
    this.resourceRestTypes = restTypes(resource);
  }

  fetchStart = (payload) => ({
    type: this.resourceRestTypes.fetchOneStart,
    payload,
  });

  fetchFinished = (payload) => ({
    type: this.resourceRestTypes.fetchOneFinished,
    payload,
  });

  setErrorFinished = (payload) => ({
    type: this.resourceRestTypes.setErrorFinished,
    payload,
  });

  clearErrorFinished = (payload) => ({
    type: this.resourceRestTypes.clearErrorFinished,
    payload,
  });

  clearAllFinished = (payload) => ({
    type: this.resourceRestTypes.clearAllFinished,
    payload,
  });

  clearOneFinished = (payload) => ({
    type: this.resourceRestTypes.clearOneFinished,
    payload,
  });

  clearListFinished = (payload) => ({
    type: this.resourceRestTypes.clearListFinished,
    payload,
  });

  clearSearchListFinished = (payload) => ({
    type: this.resourceRestTypes.clearSearchListFinished,
    payload,
  });

  clearFromStateFinished = (payload) => ({
    type: this.resourceRestTypes.clearFromStateFinished,
    payload,
  });

  clearStateFinished = () => ({
    type: this.resourceRestTypes.clearStateFinished,
  });

  setToStateFinished = (payload) => ({
    type: this.resourceRestTypes.setToStateFinished,
    payload,
  });

  fetchAllStart = (payload) => ({
    type: this.resourceRestTypes.fetchAllStart,
    payload,
  });

  fetchAllFinished = (payload) => ({
    type: this.resourceRestTypes.fetchAllFinished,
    payload,
  });

  searchAllStart = (payload) => ({
    type: this.resourceRestTypes.searchAllStart,
    payload,
  });

  searchAllFinished = (payload) => ({
    type: this.resourceRestTypes.searchAllFinished,
    payload,
  });

  updateAllStart = (payload) => ({
    type: this.resourceRestTypes.updateAllStart,
    payload,
  });

  updateAllFinished = (payload) => ({
    type: this.resourceRestTypes.updateAllFinished,
    payload,
  });

  replaceAllFinished = (payload) => ({
    type: this.resourceRestTypes.replaceAllFinished,
    payload,
  });

  updateStart = (payload) => ({
    type: this.resourceRestTypes.updateStart,
    payload,
  });

  updateFinished = (payload) => ({
    type: this.resourceRestTypes.updateFinished,
    payload,
  });

  updateMultipleStart = (payload) => ({
    type: this.resourceRestTypes.updateMultipleStart,
    payload,
  });

  updateMultipleFinished = (payload) => ({
    type: this.resourceRestTypes.updateMultipleFinished,
    payload,
  });

  deleteStart = (payload) => ({
    type: this.resourceRestTypes.deleteStart,
    payload,
  });

  deleteFinished = (payload) => ({
    type: this.resourceRestTypes.deleteFinished,
    payload,
  });

  createStart = (payload) => ({
    type: this.resourceRestTypes.createStart,
    payload,
  });

  createFinished = (payload) => ({
    type: this.resourceRestTypes.createFinished,
    payload,
  });
}
