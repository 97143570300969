import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import Layout from '../../../../components/Layout';
import List from '../../../../components/List';
import FilterNav from '../../../../components/FilterNav';
import ExtraActions from '../../../../components/ExtraActions';

import { headerList } from '../../../utils/headerList';
import { tableHeader } from '../../utils/tableSettings';

import getDateRangeFilter from '../../utils/getDateRangeFilter';
import getGroupFilter from '../../utils/getGroupFilter';

import useStateStore from './hooks/useState';
import useSystemLog from './hooks/useSystemLog';
import useSystemMessage from './hooks/useSystemMessage';
import { mapStateToProps, mapDispatchToProps } from './connect';
import Body from './containers';
import { propTypes, defaultProps } from './propTypes';
import { mappingFields } from '../../../../utils/variables';
import { defaultPagination } from '../../utils/defaultData';
import { errorLevelList } from '../../../../utils/staticData';
import IntlMessages from '../../../../utils/IntlMessages';

const System = (props) => {
  const {
    settings: { sourceOptions, timeFrameOptions },
    eventMessageType, systemLog, message: systemLogMessage,
  } = props;
  const state = useStateStore(props);

  useSystemLog(state, props);
  useSystemMessage(state, props);

  const handleErrorDetailsClose = () => {
    state.setSelectedError({});
  };

  const handleOriginChange = (data) => {
    handleErrorDetailsClose();
    if (data.value) {
      const originFilter = [{
        field: mappingFields.origin,
        value: data.origValue.label,
        operator: 'eq',
      }];

      state.setOriginFilter(originFilter);
      state.setOriginValue(data.origValue.value);
    } else {
      state.setOriginFilter([]);
      state.setOriginValue('');
    }
    state.setPagination(defaultPagination);
  };

  const handleTimeFrameChange = (data) => {
    handleErrorDetailsClose();
    const timeRangeFilter = getDateRangeFilter(data.value);
    state.setTimeFrameValue(data.value);
    state.setTimeFrameFilter(timeRangeFilter);
    state.setPagination(defaultPagination);
  };

  const hadleChartDelete = () => {
    handleErrorDetailsClose();
    state.setSelectedChart({});
    state.setMessageFilter([]);
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
  };

  const handleChartClick = (chart) => {
    handleErrorDetailsClose();
    state.setSelectedChart(chart);
    state.setMessageFilter(getGroupFilter({
      field: mappingFields.messageId,
      list: [chart],
      operator: 'eq',
    }));

    if (
      !isEmpty(state.selectedChart)
      && chart.id === state.selectedChart.id
    ) {
      hadleChartDelete();
    }
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
  };

  const handleRefreshFilter = () => {
    handleErrorDetailsClose();
    state.setRefreshFilter(!state.refreshFilter);
    state.setPagination(defaultPagination);
  };

  const handleTableRowClick = (row) => {
    state.setSelectedError(row);
  };

  const handlePaginationChange = (data) => {
    handleErrorDetailsClose();
    state.setPagination(data);
  };

  const handleErrorLevelChange = (data) => {
    handleErrorDetailsClose();
    let errorLevelFilterGroup = {};
    if (data.value) {
      errorLevelFilterGroup = {
        condition: 'and',
        group: [{
          field: mappingFields.level,
          value: data.origValue.label,
          operator: 'eq',
        }],
      };
    }

    state.setErrorLevel(data);
    state.setErrorLevelFilterGroup(errorLevelFilterGroup);
  };

  const handleToggleChange = (data) => {
    handleErrorDetailsClose();
    state.setAggregationType(data);

    if (!isEmpty(data)) {
      const newAggregation = [{
        operator: 'terms',
        field: data.field,
      }];
      state.setMessageAttributeAggregation(newAggregation);
    } else {
      state.setMessageAttributeAggregation([]);
    }
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
  };

  const filterFetching = systemLogMessage.fetching || eventMessageType.searching
    || systemLog.fetching;

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-fixed',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center list-lg"
            dataSource={headerList}
          />
        ),
        content: (
          <FilterNav
            useSource
            useTimeRange
            sourceOptions={sourceOptions}
            originSource={{
              value: state.originValue,
            }}
            timeFrame={{
              value: state.timeFrameValue,
              options: timeFrameOptions,
            }}
            onOriginChange={handleOriginChange}
            onTimeFrameChange={handleTimeFrameChange}
            refreshFilter={handleRefreshFilter}
            className={filterFetching ? 'disabled' : ''}
            loading={filterFetching}
          />
        ),
      }}
      main={{
        fluid: true,
        className: 'container-home',
        content: <Body
          loading={{
            charts: systemLogMessage.fetching || eventMessageType.searching,
            logs: systemLog.fetching,
          }}
          table={{
            extraActions: (
              <ExtraActions
                disabled={filterFetching}
                className="extra-actions-catalog"
                selectClassName="extra-actions-main-wrapper"
                selectedAttribute={state.selectedChart.id}
                staticLabel={<IntlMessages id="system.table.extarActions.label.filterBy" />}
                errorLevel={{
                  name: 'errorLevel',
                  onChange: handleErrorLevelChange,
                  options: errorLevelList,
                  staticLabelId: 'system.table.extarActions.label.errorLevel',
                  size: 'xs',
                  value: state.errorLevel.value,
                }}
                toggler={{
                  className: 'toggler-secondary',
                  onChange: handleToggleChange,
                }}
              />
            ),
          }}
          chartList={systemLogMessage.list}
          list={systemLog.list}
          listTotal={systemLog.total}
          tableHeader={tableHeader}
          onTableRowClick={handleTableRowClick}
          selectedError={state.selectedError}
          onErrorDetailsClose={handleErrorDetailsClose}
          onPaginationChange={handlePaginationChange}
          pagination={state.pagination || defaultPagination}
          chart={{
            selected: state.selectedChart,
            list: systemLogMessage.list,
            onDelete: hadleChartDelete,
            onClick: handleChartClick,
          }}
        />,
      }}
    />
  );
};

System.propTypes = propTypes;
System.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(System));
