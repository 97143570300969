import { isEmpty } from 'lodash';

export default (error) => {
  const errorData = {};
  if (!isEmpty(error.response)) {
    return error.response.data;
  }

  if (error.name) errorData.name = error.name;
  if (error.message) errorData.message = error.message;
  if (error.stack) errorData.stack = error.stack;

  return errorData;
};
