/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useUnmount = (state) => {
  useEffect(() => () => {
    state.setTargetList([]);
    state.setSourceList([]);
  }, []);
};

export default useUnmount;
