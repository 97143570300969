import React from 'react';
import { isEmpty } from 'lodash';
import ExpansionPanel from '../../../components/ExpansionPanel';
import IntlMessages from '../../../utils/IntlMessages';

const getPanels = (row) => {
  if (!isEmpty(row)) {
    return ([{
      row,
      titleId: row.attribute ? row.attribute.code : row.code,
      title: (
        <div className="h5 sidebar-title">
          {row.attribute ? row.attribute.label.en : row.code}
          {row.attribute && <span className="sidebar-title-secondary">{`[${row.attribute.label.he}]`}</span>}
          {row.attribute && <small className="sidebar-title">{` - ${row.attribute.code}`}</small>}
          <span className="sidebar-title text-primary">{` (${row.count})`}</span>
        </div>
      ),
      details: (
        !isEmpty(row.options)
          ? (
            <div className="dt tlf block">
              {row.options.map((option) => (
                <div key={option.code} className="panel-value-wrapper dtr">
                  <div className="panel-key rct-group-key dtc w50">{option.code}</div>
                  <div className="panel-value rct-group-value dtc w50">{option.count}</div>
                </div>
              ))}
            </div>
          )
          : (
            <div className="panel-placeholder">
              {
                row.loading
                  ? <IntlMessages id="panel.child.placeholder.loading" />
                  : <IntlMessages id="panel.child.placeholder.noData" />
              }
            </div>
          )
      ),
    }]);
  }
  return [];
};

export default (rows, handlePanelClick, panelSettings) => (
  !isEmpty(rows)
    ? rows.map((row) => ({
      id: row.sqsId || row.code || '',
      attributeName: <ExpansionPanel
        onPanelClick={handlePanelClick}
        isAllCollapsed={panelSettings.isAllCollapsed}
        className={`expansion-secondary ${panelSettings.className}`}
        panels={getPanels(row)}
      />,
    }))
    : []
);
