import React from 'react';
import {
  node, string, oneOfType, bool,
} from 'prop-types';
import IntlMessages from '../../utils/IntlMessages';

const Group = (props) => {
  const {
    title, children, className, withIntl, contentClassName, wrapper,
    secondary,
  } = props;

  const label = typeof title === 'string' && withIntl
    ? <IntlMessages id={title} />
    : title;

  const groupWrapperClass = wrapper ? ' rct-group-wrapper' : '';
  const groupSecondaryClass = secondary ? ' rct-group-secondary' : '';

  return (
    <div className={`rct-group ${className}${groupWrapperClass}${groupSecondaryClass}`}>
      {title && (
        <div className="rct-group-label">{label}</div>
      )}
      {children && <div className={`rct-group-content ${contentClassName}`}>{children}</div>}
    </div>
  );
};

Group.propTypes = {
  className: string,
  contentClassName: string,
  title: oneOfType([node, string]),
  children: oneOfType([node, string]),
  withIntl: bool,
  wrapper: bool,
  secondary: bool,
};

Group.defaultProps = {
  className: '',
  contentClassName: '',
  title: null,
  children: null,
  withIntl: false,
  wrapper: false,
  secondary: false,
};

export default Group;
