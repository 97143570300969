/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useUnmount = (props) => {
  useEffect(() => () => {
    props.clearFromProductsHistoryState({
      total: 0,
      aggregateList: [],
      list: [],
      after: [],
      uniqIdsList: [],
      fetched: false,
      fetching: false,
      exludedDates: [],
      fetchAggregatedProductsHistoryFinished: false,
      fetchAggregatedProductsHistoryStart: false,
      clearingFromStateFinished: false,
      clearingStateFinished: false,
    });
    props.clearFromPhysicalCategoryState({
      item: {},
      fetchedOne: false,
      selectedList: [],
    });
  }, []);
};

export default useUnmount;
