
export default (value) => {
  switch (value) {
    case 'last_6_hours':
    case 'last_24_hours':
      return '1h';
    case 'last_7_days':
      return '1d';
    case 'last_30_days':
      return '1d';
    case 'last_3_months':
      return '1M';

    default:
      return '1h';
  }
};
