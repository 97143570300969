import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {
  func, shape, string, bool,
} from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Transfer from '../Transfer';
import IntlMessages from '../../utils/IntlMessages';

const SelectCategories = (props) => {
  const [query, setQuery] = useState(props.query);
  const handleOnCategorySearch = (e) => {
    const { value } = e.target;
    setQuery(value);
    if (props.onQueryChange) props.onQueryChange(value);
  };

  const handleOnCategoryKeyDown = (e) => {
    const { value } = e.target;
    const { charCode } = e;

    handleOnCategorySearch(e);

    if (charCode === 13) {
      if (props.onSearchSubmit) {
        props.onSearchSubmit(value);
      }
    }
  };

  const handleOnSearchIconClick = () => {
    if (props.onSearchSubmit) {
      props.onSearchSubmit(query);
    }
  };

  const {
    transferSourceData, noResult, className, loading,
  } = props;

  return (
    <div className={className}>
      <Paper className="flex items-center pos-rel" elevation={1}>
        {noResult && (
          <span className="input-placeholder">
            <IntlMessages id="text.noResult" />
          </span>
        )}
        <input
          className="form-control form-control-clear block pl-15"
          placeholder="Search Categories"
          onChange={handleOnCategorySearch}
          onKeyPress={handleOnCategoryKeyDown}
          value={query}
        />
        <IconButton aria-label="Search" onClick={handleOnSearchIconClick}>
          {loading ? <CircularProgress size={24} value={75} /> : <SearchIcon />}
        </IconButton>
      </Paper>
      <Transfer
        tooltip={transferSourceData.tooltip}
        sourceTitle={transferSourceData.sourceTitle || <IntlMessages id="label.sourceCategoriesLabel" />}
        targetTitle={transferSourceData.targetTitle || <IntlMessages id="label.selectedCategoriesLabel" />}
        sourceList={transferSourceData.sourceList}
        targetList={transferSourceData.targetList}
        onChange={transferSourceData.onChange}
        selectAll={transferSourceData.selectAll}
        unselectAll={transferSourceData.unselectAll}
      />
    </div>
  );
};

SelectCategories.propTypes = {
  transferSourceData: shape({}).isRequired,
  onSearchSubmit: func,
  onQueryChange: func,
  query: string,
  className: string,
  noResult: bool,
  loading: bool,
};

SelectCategories.defaultProps = {
  onSearchSubmit: null,
  onQueryChange: null,
  query: '',
  className: '',
  noResult: false,
  loading: false,
};

export default SelectCategories;
