// APP paths
export const rootAppPath = '/';

export const catalogAppPath = '/catalog';
export const getCatalogSearchAppPath = (query) => `/catalog?${query}`;
export const catalogDetailAppPath = `${catalogAppPath}/:type/:id`;
export const getCatalogDetailAppPath = ({ type, id }) => `${catalogAppPath}/${type}/${id}`;

export const productsHistoryAppPath = '/products-history';
export const systemAppPath = '/system';
export const signinAppPath = '/signin';
export const forgotPasswordAppPath = '/forgot-password';
export const changePasswordAppPath = '/change-password';
export const settingsAppPath = '/settings';
export const notificationsAppPath = '/notifications';
export const monitoringSettingsPath = '/monitoring_settings';
export const categoriesPath = '/virtual_categories';
export const physicalCategoriesPath = '/categories';
export const navigationsPath = '/navigations';
export const eventMessageTypesPath = '/event_message_types/search';

// API pathes
export const getLogsSearchPath = (monitoringApiPath) => `${monitoringApiPath}/logs/search`;
export const getProductsHistorySearchPath = (monitoringApiPath) => `${monitoringApiPath}/product_count_history/search`;
export const getLogPath = (monitoringApiPath, id) => `${monitoringApiPath}/logs/${id}`;

export const getNagigationsSearchPath = (cdmsApiPath) => `${cdmsApiPath}${navigationsPath}/search`;

export const grtProductAttributesSearchPath = (cdmsApiPath) => `${cdmsApiPath}/product_attributes/search`;
export const getCategoriesSearchPath = (cdmsApiPath) => `${cdmsApiPath}${categoriesPath}/search`;
export const getCategorySearchPath = (cdmsApiPath, id) => `${cdmsApiPath}${categoriesPath}/${id}`;
export const getPhysicalCategoryWithLocaleSearchPath = (cdmsApiPath, id, lang) => `${cdmsApiPath}${physicalCategoriesPath}/${id}?lang=${lang}`;
export const getCategoryWithLocaleSearchPath = (cdmsApiPath, id, lang) => `${cdmsApiPath}${categoriesPath}/${id}?lang=${lang}`;
export const getCategoriesWithLocaleSearchPath = (cdmsApiPath, lang) => `${cdmsApiPath}${categoriesPath}/search?lang=${lang}`;
export const getPhysicalCategoriesWithLocaleSearchPath = (cdmsApiPath, lang) => `${cdmsApiPath}${physicalCategoriesPath}/search?lang=${lang}`;

export const getNavigationPath = (cdmsApiPath, id) => `${cdmsApiPath}${navigationsPath}/${id}`;
export const getNavigationsWithLocaleSearchPath = (cdmsApiPath, lang) => `${cdmsApiPath}${navigationsPath}/search?lang=${lang}`;

export const getEventMessageTypesWithLocaleSearchPath = (cdmsApiPath, lang) => `${cdmsApiPath}${eventMessageTypesPath}?lang=${lang}`;

export const getCategoriesTreePath = (cdmsApiPath) => `${cdmsApiPath}/virtual_categories_tree`;
export const getCategoryTreePath = (cdmsApiPath, id) => `${cdmsApiPath}/virtual_categories_tree/${id}`;
export const getCategoryPath = (cdmsApiPath, id) => `${cdmsApiPath}${categoriesPath}/${id}`;
export const getCategoriesExpandTreePath = (cdmsApiPath, id) => `${cdmsApiPath}/virtual_categories_tree/${id}`;
