import { isEmpty } from 'lodash';
import getLogType from '../../../utils/getLogType';
import getDateRangeFilter from './getDateRangeFilter';
import attributeAggList from '../../../utils/stash/attributeAggList';

export default (values) => {
  if (!isEmpty(values)) {
    const {
      categories, origins, merchants, timeFrameValue, logType,
    } = values;

    const isArrgregationAttribute = attributeAggList.some((agg) => agg.value === logType);
    const logTypesSearcFilter = isArrgregationAttribute
      ? ({
        condition: 'and',
        group: [{
          field: 'log_type',
          value: getLogType(),
          operator: 'eq',
        }],
      })
      : [];

    const timeRangeFilter = [{
      condition: 'and',
      group: getDateRangeFilter(timeFrameValue),
    }];

    const categoriesFilter = !isEmpty(categories)
      ? [{
        condition: 'and',
        group: categories,
      }]
      : [];

    const merchantsFilter = !isEmpty(merchants)
      ? [{
        condition: 'and',
        group: merchants,
      }]
      : [];

    const originsFilter = !isEmpty(origins)
      ? [{
        condition: 'and',
        group: origins,
      }]
      : [];

    return [
      logTypesSearcFilter,
      originsFilter,
      merchantsFilter,
      categoriesFilter,
      timeRangeFilter,
    ].flat();
  }

  return [];
};
