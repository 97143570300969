import React from 'react';
import {
  func, shape, string, arrayOf, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';

import TinyLineChart from '../../../components/Chart';
import Block from '../../../components/Block';
import Statistic from '../../../components/Statistic';
import Skeleton from '../../../components/Skeleton';

import { getTinyLineSettings } from '../utils/chartSettings';
import IntlMessages from '../../../utils/IntlMessages';
import disableEventsOnScroll from '../../../utils/disableEventsOnScroll';
import StatisticTitle from './StatisticTitle';
import getRate from '../../../utils/getRate';

const Charts = (props) => {
  const { chartList, loading } = props;
  const scrollFadeClass = !isEmpty(chartList) && chartList.length > 15 ? 'scroll-fade-before scroll-fade-after' : '';
  const scrollClass = !isEmpty(chartList) && chartList.length > 15 ? 'scroll-y scroll-70' : '';
  let disabledChart = false;

  return (
    isEmpty(chartList)
      ? (
        !loading && (
          <Skeleton
            type="chartCard"
            message={<IntlMessages id="text.noResult" />}
          />
        )
      )
      : (
        <div className={`container container-fluid pb35 ${scrollFadeClass}`}>
          <div
            id="scrollContainer"
            className={`flex flex-wrap rct-block-row ${scrollClass}`}
            onScroll={disableEventsOnScroll('scrollContainer')}
          >
            {!isEmpty(chartList) && chartList.map((chart) => {
              const data = getRate(chart.errors.current, chart.errors.prev);
              const selectedChartClass = !isEmpty(props.selected) && chart.id === props.selected.id ? 'chart-active' : '';

              const tinyLineSettings = getTinyLineSettings({ chart });

              disabledChart = props.selected && !isEmpty(props.selected)
                && chart.id !== props.selected.id;

              return (
                <div
                  role="presentation"
                  className={`rct-block-wrapper chart-row ${disabledChart ? 'no-events' : ''}`}
                  key={chartList.indexOf(chart)}
                  onClick={() => props.onChartClick(chart)}
                >
                  <Block
                    className={`chart-wrapper ${selectedChartClass} ${disabledChart ? 'chart-disabled' : ''}`}
                    title={(
                      <Statistic
                        title={(
                          <StatisticTitle
                            title={chart.name}
                          />
                        )}
                        className="flex justify-between items-baseline"
                        value={data.value}
                        valueSuffix="%"
                        precision={2}
                        type={data.type}
                        suffix={data.icon}
                      />
                    )}
                  >
                    <div className="chart-title">{chart.docCount}</div>
                    <TinyLineChart
                      responsive
                      className={tinyLineSettings.className}
                      width={tinyLineSettings.width}
                      height={tinyLineSettings.height}
                      fill={tinyLineSettings.fill}
                      sourceData={chart}
                      xAxis={tinyLineSettings.xAxis}
                      yAxis={tinyLineSettings.yAxis}
                      dataKey={tinyLineSettings.dataKey}
                      strokeWidth={tinyLineSettings.strokeWidth}
                      dot={tinyLineSettings.dot}
                    />
                  </Block>
                </div>
              );
            })}
          </div>
          {props.selected && !isEmpty(props.selected) && (
            <Button
              onClick={props.deleteSelected}
              color="secondary"
              className="btn-xs pos-abs bottom-0 right"
            >
              <IntlMessages id="text.allErrors" />
            </Button>
          )}
        </div>
      )

  );
};

Charts.propTypes = {
  onChartClick: func.isRequired,
  loading: bool.isRequired,
  deleteSelected: func.isRequired,
  selected: shape({
    id: string,
  }),
  chartList: arrayOf(shape({
    key: string,
  })),
};

Charts.defaultProps = {
  selected: {},
  chartList: [],
};

export default Charts;
