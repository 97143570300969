import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import { CLEAR_SESSION_STATE } from '../actions/types';

const initialState = {
  item: {},
};
const sessionRestTypes = restTypes('session');
const {
  fetchOneStart,
  fetchOneFinished,
} = sessionRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        item: action.payload.user,
        roles: action.payload.roles,
      };
    case CLEAR_SESSION_STATE:
      return {
        ...initialState,
        ...restInitState,
      };

    default: return state;
  }
};
