import React, { useState, useEffect } from 'react';
import {
  string, func, bool, shape, number, arrayOf,
} from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Button, Dialog, DialogActions, DialogContent, CircularProgress,
  DialogTitle, useMediaQuery, Paper, IconButton, Badge,
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import IntlMessages from '../../utils/IntlMessages';

import List from './List';

const ResponsiveDialog = (props) => {
  const {
    className, noResult, loading, pagination, list,
  } = props;
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [keyboardKey, setKeyboardKey] = useState('');

  useEffect(() => {
    if (isEmpty(query) && keyboardKey === 'Backspace' && isSubmited) {
      setSelected([]);
      setIsSubmited(false);
      setPage(1);
      setKeyboardKey('');
      if (props.search.onSubmit) props.search.onSubmit(query);
    }
  }, [keyboardKey, query, props.search, isSubmited]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const clearData = () => {
    setQuery('');
    setKeyboardKey('');
    if (props.search.onClear) props.search.onClear(isSubmited);
    setIsSubmited(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (props.onOpen) props.onOpen();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    handleClose();
    if (props.onOk) props.onOk(selected);
  };

  const handleCancel = () => {
    handleClose();
    setPage(1);
    setQuery('');
    setIsSubmited(false);
    setSelected([]);
    setKeyboardKey('');

    if (
      (!isEmpty(selected) || isSubmited || page > 1)
      && props.onCancel
    ) {
      props.onCancel();
    }
  };

  const handleOnCategorySearch = (e) => {
    const { value } = e.target;
    setQuery(value);
    if (props.onSearchChange) props.onSearchChange(value);
  };

  const handleOnCategoryKeyPress = (e) => {
    const { value } = e.target;
    const { charCode } = e;

    handleOnCategorySearch(e);

    if (charCode === 13) {
      setIsSubmited(true);
      setSelected([]);
      setPage(1);
      setKeyboardKey('');
      if (props.search.onSubmit) props.search.onSubmit(value);
    }
  };

  const handleOnCategoryKeyDown = (e) => {
    const { which } = e;
    if (which === 8 && isSubmited) {
      setKeyboardKey(e.key);
    }
  };

  const handleOnSearchIconClick = () => {
    setIsSubmited(true);
    if (props.search.onSubmit) props.search.onSubmit(query);
  };

  const handleListChange = (itemId) => {
    let selectedList = [...selected];

    if (!itemId) {
      return setSelected([]);
    }

    if (!selectedList.some((treeElId) => treeElId === itemId)) {
      selectedList.push(itemId);
    } else {
      selectedList = selectedList.filter((elId) => elId !== itemId);
    }

    return setSelected(selectedList);
  };

  const handlePaginationChange = (event, value) => {
    setPage(value);
    if (pagination.onChange) pagination.onChange(value);
  };

  const getButton = () => {
    if (!isEmpty(selected)) {
      return (
        <Badge badgeContent={selected.length} color="primary">
          <Button
            className="btn-xs text-nowrap block"
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
          >
            <IntlMessages id="selectNavigation.popup.button.label" />
          </Button>
        </Badge>
      );
    }

    return (
      <Button
        className="btn-xs text-nowrap block"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        <IntlMessages id="selectNavigation.popup.button.label" />
      </Button>
    );
  };

  const disableClass = loading ? 'disabled' : '';

  return (
    <div className={`${className}`}>
      <span className="mr10">
        <IntlMessages id="selectNavigation.popup.selectButton.label" />
      </span>
      {getButton()}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Paper className={`flex items-center pos-rel ${disableClass}`} elevation={1}>
            {noResult && (
              <span className="input-placeholder">
                <IntlMessages id="text.noResult" />
              </span>
            )}
            <input
              className="form-control form-control-clear block pl-15"
              placeholder="Search Navigation(s)"
              onChange={handleOnCategorySearch}
              onKeyPress={handleOnCategoryKeyPress}
              onKeyDown={handleOnCategoryKeyDown}
              value={query}
            />
            {!isEmpty(query) && (
              <IconButton aria-label="Search" onClick={clearData}>
                <Clear />
              </IconButton>
            )}
            <IconButton aria-label="Search" onClick={handleOnSearchIconClick}>
              {loading ? <CircularProgress size={24} value={75} /> : <Search />}
            </IconButton>
          </Paper>
        </DialogTitle>
        <DialogContent>
          <List
            className={`list-checkbox-wrapper list-checkbox-sm ${disableClass}`}
            list={list}
            enabledHoverEffects
            selected={selected}
            onChange={handleListChange}
          />
        </DialogContent>
        <DialogActions>
          <div className={`MuiDialogActions-spacing flex block ${disableClass}`}>
            {pagination.pages > 1 && (
              <Pagination
                page={page}
                disabled={loading}
                count={pagination.pages}
                className="mr-auto"
                size="small"
                onChange={handlePaginationChange}
              />
            )}
            <Button onClick={handleCancel} variant="contained" className="btn-muted ml-auto">
              <IntlMessages id="selectNavigation.popup.button.cancel.label" />
            </Button>
            <Button onClick={handleOk} variant="contained" className="btn-info">
              <IntlMessages id="selectNavigation.popup.button.ok.label" />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ResponsiveDialog.propTypes = {
  className: string,
  onOk: func,
  onOpen: func,
  onCancel: func,
  onSearchChange: func,
  noResult: bool,
  loading: bool,
  list: arrayOf(shape),
  pagination: shape({
    pages: number,
    total: number,
    onChange: func,
  }),
  search: shape({
    onClear: func,
    onSubmit: func,
  }),
};

ResponsiveDialog.defaultProps = {
  className: 'select-navigation-wrapper',
  onOk: null,
  onOpen: null,
  onCancel: null,
  onSearchChange: null,
  noResult: false,
  loading: false,
  list: [],
  pagination: {
    pages: 0,
    total: 0,
    onChange: null,
  },
  search: {
    onClear: null,
    onSubmit: null,
  },
};

export default ResponsiveDialog;
