/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  string, shape, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { camelizeKeys } from 'humps';
import axios from 'axios';
import Popper from '@material-ui/core/Popover';

import PopoverContent from '../ScopePopupPopover/PopoverContent';
import { getNavigationPath } from '../../utils/paths';

const NavigationTreeNode = (props) => {
  const [category, setCategory] = useState({});
  const {
    hoveredCategoryId, popoverAnchor, handleClosePopper,
    anchorOrigin, transformOrigin, settings,
  } = props;

  useEffect(() => {
    const fetchCategories = async () => {
      let result;

      // eslint-disable-next-line no-useless-catch
      try {
        const { cdms } = settings.serviceConfigs;
        result = await axios.get(getNavigationPath(cdms.apiPath, props.hoveredCategoryId));
      } catch (error) {
        throw error;
      }

      const categoriesPayload = camelizeKeys({ ...result.data.data });

      setCategory(categoriesPayload);
    };

    if (props.hoveredCategoryId && category.id !== props.hoveredCategoryId) {
      fetchCategories();
    }
  }, [props.hoveredCategoryId]);

  const isOpen = !isEmpty(category) && !!hoveredCategoryId && hoveredCategoryId === category.id;

  return (
    <Popper
      open={isOpen}
      anchorEl={popoverAnchor}
      onClose={handleClosePopper}
      className="no-events"
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <PopoverContent
        category={category}
      />
    </Popper>
  );
};

NavigationTreeNode.propTypes = {
  hoveredCategoryId: string.isRequired,
  popoverAnchor: shape(),
  handleClosePopper: func,
  anchorOrigin: shape(),
  transformOrigin: shape(),
  settings: shape(),
};

NavigationTreeNode.defaultProps = {
  popoverAnchor: null,
  handleClosePopper: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  settings: {},
};

export default NavigationTreeNode;
