import { useState } from 'react';
import { isEmpty } from 'lodash';
import { defaultPagination } from '../../../utils/defaultData';
import getCatalogFilter from '../../../utils/getCatalogFilter';
import {
  getExtraSortOptions, getMessageAttributeAggregation, getAggregationType,
} from '../../../utils/getCatalogExtraData';

export const useStateHook = (props) => {
  const catalogValues = sessionStorage.getItem('catalogValues');
  const values = !isEmpty(catalogValues) ? JSON.parse(catalogValues) : null;

  const [pagination, setPagination] = useState(defaultPagination);
  const [fields, setFields] = useState(props.fields);
  const [aggregations, setAggregations] = useState(
    values && values.aggregations
      ? values.aggregations || ''
      : '',
  );
  const [sort, setSort] = useState(props.fields);
  const [filter, setFilter] = useState(getCatalogFilter(values));
  const [targetList, setTargetList] = useState([]);
  const [originFilter, setOriginFilter] = useState(
    values && values.origins
      ? values.origins || []
      : [],
  );

  const [originValue, setOriginValue] = useState(
    values && values.originValue
      ? values.originValue || ''
      : '',
  );

  const [categoriesFilter, setCategoriesFilter] = useState(
    values && values.categories
      ? values.categories || []
      : [],
  );

  const [merchantsFilter, setMerchantsFilter] = useState(
    values && values.merchants
      ? values.merchants || []
      : [],
  );

  const [timeFrameFilter, setTimeFrameFilter] = useState([]);

  const [messageFilter, setMessageFilter] = useState(
    values && values.messages
      ? values.messages || []
      : [],
  );

  const [timeFrameValue, setTimeFrameValue] = useState(
    values && values.timeFrameValue
      ? values.timeFrameValue
      : 'last_24_hours',
  );

  const [selectCategoryQuery, setSelectCategoryQuery] = useState(
    values && values.selectCategoryQuery
      ? values.selectCategoryQuery || ''
      : '',
  );

  const [logType, setLogType] = useState(
    values && values.logType
      ? values.logType || 'merchant'
      : 'merchant',
  );

  const [selectedChart, setSelectedChart] = useState(
    values && !isEmpty(values.messages)
      ? { id: values.messages[0].value }
      : {},
  );
  const [searchFilter, setSearchFilter] = useState({});
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [refreshFilter, setRefreshFilter] = useState(false);
  const [errorLevel, setErrorLevel] = useState({ errorLevel: { value: 'all' } });
  const [errorLevelFilterGroup, setErrorLevelFilterGroup] = useState({});
  const [attributeFilterGroup, seAttributeFilterGroup] = useState([]);
  const [extraSortOptions, setExtraSortOptions] = useState(getExtraSortOptions(
    values && values.messages
      ? values.messages || []
      : [],
  ));
  const [aggregationType, setAggregationType] = useState(getAggregationType(
    values && values.logType
      ? values.logType || 'merchant'
      : 'merchant',
  ));
  const [messageAttributeAggregation, setMessageAttributeAggregation] = useState(
    getMessageAttributeAggregation(
      values && values.aggregations
        ? values.aggregations || ''
        : '',
    ),
  );
  const [messageAttributeOptionAggregation, setMessageAttributeOptionAggregation] = useState([]);
  const [paginationDirectionKey, setPaginationDirectionKey] = useState('');
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [tableTotal, setTableTotal] = useState(0);
  const [loadedAttributeCodes, setLoadedAttributeCodes] = useState([]);

  const state = {
    pagination,
    fields,
    sort,
    aggregations,
    filter,
    targetList,
    originFilter,
    categoriesFilter,
    timeFrameValue,
    merchantsFilter,
    timeFrameFilter,
    selectCategoryQuery,
    logType,
    selectedChart,
    messageFilter,
    searchFilter,
    originValue,
    selectedMerchants,
    refreshFilter,
    errorLevel,
    errorLevelFilterGroup,
    attributeFilterGroup,
    aggregationType,
    paginationDirectionKey,
    extraSortOptions,
    messageAttributeAggregation,
    messageAttributeOptionAggregation,
    selectedAttribute,
    tableTotal,
    loadedAttributeCodes,
  };

  const setState = {
    setPagination,
    setFields,
    setSort,
    setAggregations,
    setFilter,
    setTargetList,
    setOriginFilter,
    setCategoriesFilter,
    setTimeFrameValue,
    setMerchantsFilter,
    setTimeFrameFilter,
    setSelectCategoryQuery,
    setLogType,
    setSelectedChart,
    setMessageFilter,
    setSearchFilter,
    setOriginValue,
    setSelectedMerchants,
    setRefreshFilter,
    setErrorLevel,
    setErrorLevelFilterGroup,
    seAttributeFilterGroup,
    setAggregationType,
    setPaginationDirectionKey,
    setExtraSortOptions,
    setMessageAttributeAggregation,
    setMessageAttributeOptionAggregation,
    setSelectedAttribute,
    setTableTotal,
    setLoadedAttributeCodes,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
