import { useState } from 'react';

export const useStateHook = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const state = {
    email,
    password,
    showPassword,
  };

  const setState = {
    setEmail,
    setPassword,
    setShowPassword,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
