import React from 'react';
import { connect } from 'react-redux';
import { func, shape } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Divider from '@material-ui/core/Divider';
import IntlMessages from '../../../utils/IntlMessages';
import Block from '../../../components/Block';
import Logo from '../../../components/Header/Logo';

import {
  signinUserInFirebase,
} from '../../../actions/auth';
import {
  forgotPasswordAppPath,
} from '../../../utils/paths';

import smallLogo from '../../../assets/img/logo-small.png';

import useStateStore from './hooks/useState';
import useDidUpdate from './hooks/useDidUpdate';

const Signin = (props) => {
  const state = useStateStore(props);
  useDidUpdate(state, props);

  const onUserLogin = () => {
    const { email, password } = state;
    props.signinUserInFirebase({ email, password });
  };

  const onKeyPress = (e) => {
    if (e.charCode === 13) onUserLogin();
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email':
        state.setEmail(value);
        break;
      case 'password':
        state.setPassword(value);
        break;

      default:
        break;
    }
  };

  const handleClickShowPassword = () => {
    const { showPassword } = state;
    state.setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { email, password, showPassword } = state;

  return (
    <div className="login-wrapper flex justify-center items-center h-100">
      <Block className="login-body text-center shadow-lg p25">
        <div className="login-head">
          <Logo
            pathTo="/"
            src={smallLogo}
          />
        </div>
        <Divider />
        <div className="form flex direction-column login-login">
          <FormControl className="has-wrapper">
            <TextField
              id="email"
              label="Email"
              className="has-input block"
              value={email}
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="pwd">
              <IntlMessages id="text.password" />
            </InputLabel>
            <OutlinedInput
              name="password"
              id="pwd"
              className="has-input block"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onChange}
              onKeyPress={onKeyPress}
              labelWidth={70}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
          <FormControl className="mb-15">
            <Link to={forgotPasswordAppPath}>
              <Button color="primary" size="small">
                <IntlMessages id="text.forgotPassword" />
              </Button>
            </Link>
          </FormControl>
          <FormControl className="mb-15">
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onUserLogin}
            >
              <IntlMessages id="text.signIn" />
            </Button>
          </FormControl>
        </div>
      </Block>
    </div>
  );
};

Signin.propTypes = {
  signinUserInFirebase: func.isRequired,
  history: ReactRouterPropTypes.history,

  authUser: shape(),
  session: shape(),
};

Signin.defaultProps = {
  history: {},
  authUser: {},
  session: {},
};

const mapDispatchToProps = {
  signinUserInFirebase,
};

const mapStateToProps = (state) => ({
  authUser: state.authUser,
  session: state.session,
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
