import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  SET_LANGUAGE,
  RTL_LAYOUT,
} from '../actions/types';

import AppConfig from '../utils/appConfig';

const settingsRestTypes = restTypes('settings');

const initialState = {
  rtlLayout: AppConfig.rtlLayout,
  locale: AppConfig.locale,
  languages: [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'en',
    },
  ],
  serviceConfigs: {
    cdms: {},
    monitoring: {},
  },
  sourceOptions: [],
  statusOptions: [],
  timeFrameOptions: [],
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case settingsRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case settingsRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload };

    case SET_LANGUAGE:
      return { ...state, locale: action.payload };

    default: return { ...state };
  }
};
