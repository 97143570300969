import React from 'react';
import {
  node, string, oneOfType, bool,
} from 'prop-types';

const Block = (props) => {
  const block = props.block ? 'block' : '';
  const direction = props.direction ? `direction-${props.direction}` : '';
  return (
    <div className={`rct-block ${props.className} rct-block-${props.size} ${block} ${direction}`}>
      {props.title && <div className="rct-block-title">{props.title}</div>}
      {props.children && <div className={`rct-block-content ${props.contentClassName}`}>{props.children}</div>}
    </div>
  );
};

Block.propTypes = {
  className: string,
  contentClassName: string,
  direction: string,
  size: string,
  block: bool,
  children: node.isRequired,
  title: oneOfType([node, string]),
};

Block.defaultProps = {
  size: 'default',
  className: '',
  contentClassName: '',
  direction: 'row',
  block: false,
  title: null,
};

export default Block;
