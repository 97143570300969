import {
  func, shape, string, arrayOf, bool,
} from 'prop-types';

export const propTypes = {
  history: shape().isRequired,

  scopeCategory: shape({
    searchList: arrayOf(shape({
      id: string,
    })),
    searching: bool,
    searched: bool,
  }).isRequired,
  productsHistory: shape({
    fetching: bool,
  }).isRequired,

  searchScopeCategories: func.isRequired,
  fetchScopeCategoriesByIds: func.isRequired,
  clearSearchListState: func.isRequired,
  onToggleScopeCategoryTreeCollapse: func.isRequired,
  fetchChildScopeCategories: func.isRequired,
  handleLoadScopeSubcategories: func.isRequired,
};

export const defaultProps = {};

export default {
  propTypes,
  defaultProps,
};
