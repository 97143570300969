import React from 'react';

import { UnfoldMore, ArrowDownward, ArrowUpward } from '@material-ui/icons';

export default (key, fontSize = 'small') => {
  switch (key) {
    case 'UnfoldMore':
      return <UnfoldMore fontSize={fontSize} />;
    case 'ArrowDownward':
    case 'desc':
      return <ArrowDownward fontSize={fontSize} />;
    case 'ArrowUpward':
    case 'asc':
      return <ArrowUpward fontSize={fontSize} />;

    default:
      return null;
  }
};
