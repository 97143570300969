import React from 'react';
import {
  shape, arrayOf, string, func, oneOfType, node, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import IntlMessages from '../../utils/IntlMessages';
import TooltipWrapper from './TooltipWrapper';
import TargetItem from './TargetItem';

const TargetList = (props) => {
  const {
    targetList, tooltip, title, unselectAll,
  } = props;

  return (
    <div className="target-list-wrapper">
      {title && (
        <div className="target-list-title">
          {title}
          {` (${targetList.length})`}

          {
            unselectAll && (
              <Button
                onClick={props.onUnselectAll}
                color="primary"
                className="btn-xs float-right"
                disabled={isEmpty(targetList)}
              >
                <IntlMessages id="catalog.popover.selectCategories.button.unselectAll.label" />
              </Button>
            )
          }
        </div>
      )}
      <ul className="transfer-list transfer-target-list">
        {targetList && targetList.length > 0 && targetList.map((tItem) => (
          <li key={tItem.id || tItem.value} className="transfer-list-item">
            <TooltipWrapper
              title={tItem.pathByName}
              tooltip={tooltip}
            >
              <TargetItem
                dataSource={{ tItem }}
                onClick={props.onClick}
              />
            </TooltipWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
};

TargetList.propTypes = {
  unselectAll: bool,
  targetList: arrayOf(shape({})),
  title: oneOfType([string, node]),
  onClick: func,
  onUnselectAll: func,
  tooltip: shape({
    placement: string,
  }),
};

TargetList.defaultProps = {
  unselectAll: false,
  title: '',
  targetList: [],
  onClick: null,
  onUnselectAll: null,
  tooltip: {},
};

export default TargetList;
