import axios from 'axios';
import humps from 'humps';
import RestActions from '../utils/rest/actions';

import { getEventMessageTypesWithLocaleSearchPath } from '../utils/paths';
import { mapObjectToArray } from '../utils/objectsToArray';
import messagesColors from '../utils/stash/messagesColors';
import handleError from '../utils/handleError';

const eventMessageActions = new RestActions('event_message_type');
const {
  searchAllStart,
  searchAllFinished,
  clearFromStateFinished,
} = eventMessageActions;

export const searchEventMessages = (query) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch(searchAllStart());
  axios.post(
    getEventMessageTypesWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(query),
  ).then((res) => {
    const mappedList = humps.camelizeKeys(mapObjectToArray(res.data.data));
    const mappedColorList = mappedList.map((l) => ({
      ...l,
      color: messagesColors.find((mC) => mC.messageId === l.messageId) ? messagesColors.find((mC) => mC.messageId === l.messageId).color : 'red',
    }));
    dispatch(searchAllFinished({
      searchList: mappedColorList,
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(searchAllFinished({
      searchList: [],
      error: errorRes,
      hasError: true,
    }));
  });
};

export const clearFromEventMessageState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
