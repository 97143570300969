import { combineReducers } from 'redux';
import settings from './settings';
import session from './session';
import user from './user';
import authUser from './authUser';
import error from './error';
import log from './log';
import physicalCategory from './physicalCategory';
import merchant from './merchant';
import eventMessageType from './eventMessageType';
import message from './message';
import detailsLogMessage from './detailsLogMessage';
import detailsLog from './detailsLog';
import systemLog from './systemLog';
import systemLogMessage from './systemLogMessage';
import productsHistory from './productsHistory';
import scopeCategory from './scopeCategory';
import attribute from './attribute';
import attributeOption from './attributeOption';
import productsHistoryTable from './productsHistoryTable';
import navigation from './navigation';

const reducers = combineReducers({
  settings,
  session,
  user,
  error,
  authUser,
  log,
  physicalCategory,
  merchant,
  eventMessageType,
  message,
  detailsLog,
  detailsLogMessage,
  systemLog,
  systemLogMessage,
  productsHistory,
  scopeCategory,
  attribute,
  attributeOption,
  productsHistoryTable,
  navigation,
});

export default reducers;
