/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useMount = () => {
  useEffect(() => {
    sessionStorage.removeItem('catalogValues');
    sessionStorage.removeItem('systemFilter');
  }, []);
};

export default useMount;
