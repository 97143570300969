export default {
  'text.catalog': 'Catalog',
  'text.system': 'System',
  'text.virtualCategories': 'Virtual Categories',
  'text.preview': 'Preview',
  'text.logout': 'Logout',
  'text.notifications': 'Notifications',
  'text.settings': 'Settings',
  'text.table': 'Table title',
  'text.status': 'Status',
  'text.issues': 'Issues',
  'text.lastModified': 'Last modified',
  'text.resetPasswordButton': 'Reset password',
  'text.password': 'Password',
  'text.forgotPassword': 'Forgot password?',
  'text.signIn': 'Sign In',
  'text.changePasswordButton': 'Change password',
  'text.resetPasswordSuccessMessage': 'We sent an email with the recovery link.',
  'text.codeIsInvalid': 'Code is invalid',
  'text.filterBy': 'Filter by:',
  'text.source': 'Source:',
  'text.timeRange': 'Time range:',
  'text.all': 'All',
  'text.select': 'Select',
  'text.cancel': 'Cancel',
  'text.category': 'Category:',
  'text.sourceCategoriesLabel': 'Source categories',
  'text.selectedCategoriesLabel': 'Selected categories',
  'text.selectAllCategories': 'Select all',
  'text.allErrors': 'All errors',
  'text.noResult': 'Sorry, no results found',
  'text.by': 'By',
  'text.merchant': 'Merchant:',
  'text.applyFilter': 'Apply filter',
  'text.noIssuses': 'No issues found',
  'text.noStatuses': 'No statuses found',
  'text.fix': 'Fix',
  'text.loading': 'Loading...',
  'text.ack': 'Ack',
  'text.new': 'New',
  'text.resolve': 'Resolve',
  'text.ignore': 'Ignore',
  'text.apply': 'Apply',
  'text.errorsType': 'Errors type',
  'text.mapping': 'Mapping',
  'text.userAuthSuccess': 'You\'ve been logged in successfully.',
  'text.userLogOutSuccess': 'You\'ve been logged ou successfully.',
  'text.errorDesctirption': 'Error description',
  'text.origin': 'Origin',
  'text.messageId': 'Message ID',
  'text.operationId': 'Operation ID',
  'text.merchantId': 'Merchant ID',
  'text.productId': 'Product ID',
  'text.idType': 'ID type',
  'text.id': 'ID',
  'text.sqsId': 'SQS ID',
  'text.response': 'Response',
  'text.properties': 'Properties',
  'text.attributes': 'Attributes',
  'text.categoryName': 'Category',
  'text.moreInfo': 'More info:',
  'text.ip': 'IP',
  'text.clientIp': 'Client IP',
  'text.thread': 'Thread',
  'text.level': 'Error Level',
  'text.logType': 'Log type',
  'text.reasonCode': 'Reason code',
  'text.responseCode': 'Response code',
  'text.trace': 'Trace',
  'text.url': 'URL',
  'text.host': 'Host',
  'text.operationName': 'Operation name',
  'text.environment': 'Environment',
  'text.agent': 'Agent',
  'text.userId': 'User ID',
  'text.monitoringTool': 'Monitoring tool',
  'text.refreshFilter': 'Refresh',

  'table.body.placeholder.noData': 'No data',

  'panel.child.placeholder.noData': 'No data',
  'panel.child.placeholder.loading': 'Loading...',

  'fiterNav.button.label.clearFilter': 'Clear Filter',

  'system.table.extarActions.label.filterBy': 'Filter by:',
  'system.table.extarActions.label.errorLevel': 'Error level:',

  'catalog.popover.selectCategories.button.unselectAll.label': 'Unselect all',
  'catalog.pagination.rowsPerPage.label': 'Show:',
  'catalog.details.table.draw.attributeSetId.label': 'Attribute set ID',
  'catalog.details.table.draw.categories.label': 'Categories',
  'catalog.details.table.draw.attributesOrigin.label': 'Attributes Origin',
  'catalog.list.table.log.title.tooltip.pathByName.label': 'Path by Name:',
  'catalog.table.extarActions.label.errorLevel': 'Error level:',
  'catalog.table.logDetails.label.errorLevel': 'Error level:',
  'catalog.table.extarActions.label.filterBy': 'Filter by:',
  'catalog.table.extarActions.label.entityType': 'Entity type:',
  'catalog.details.table.extarActions.label.aggregationType': 'Aggregation type:',

  'productsHistory.header.filterNav.label.virtualCatrgory': 'Virtual Category(s):',
  'productsHistory.header.filterNav.label.type': 'Type:',
  'productsHistory.notification.error.timeRange': 'Sorry, you have selected the incorrect time range. The maximum time range is - ',
  'productsHistory.button.selectCategoriesLabel': 'Select Categories',
  'productsHistory.scopeDialog.button.cancel': 'Cancel',
  'productsHistory.scopeDialog.button.select': 'Select',
  'productsHistory.scopeDialog.placeholder.noResult': 'Sorry, no results found',
  'productsHistory.scopeDialog.categoriesTree.tooltip.noPhysicalCategories': 'There are no physical categories',
  'productsHistory.scopeDialog.errors.lessThan170Characters': 'Use less then 170 characters',
  'productsHistory.scopeDialog.errors.alphanumeric': 'This field must not contain special characters',
  'productsHistory.table.header.title.prodCount': 'Products count per day',
  'productsHistory.table.header.title.delta': 'Delta',
  'productsHistory.table.placeholder.noPeriodResult': 'Sorry 🙁, no results found for this range. Please select another category(s) or change the time range 📅 ',
  'productsHistory.table.placeholder.firstQuery': 'Running Your First Query. Select ✅ category(s) and a time range 📅 or just select some 📅 time range',

  'header.nav.label.productsHistory': 'Products history',

  'selectNavigation.popup.selectButton.label': 'Navigation(s):',
  'selectNavigation.popup.button.label': 'Select navigation(s)',
  'selectNavigation.popup.button.ok.label': 'Select',
  'selectNavigation.popup.button.cancel.label': 'Cancel',

  'chart.title.rate.prev.label': 'Previous rate:',
};
