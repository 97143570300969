import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  func, shape, bool, string,
} from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Block from '../../../components/Block';
import Logo from '../../../components/Header/Logo';
import IntlMessages from '../../../utils/IntlMessages';
import smallLogo from '../../../assets/img/logo-small.png';

import {
  requestResetUserPasswordInFirebase,
} from '../../../actions/auth';


const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    props.requestResetUserPasswordInFirebase(email);
  };

  return (
    <div className="login-wrapper flex justify-center items-center h-100">
      <Block className="login-body text-center shadow-lg p25">
        <div className="login-head">
          <Logo
            pathTo="/"
            src={smallLogo}
          />
        </div>
        <Divider />
        {
          !props.authUser.resetFinished || props.authUser.hasError
            ? (
              <div className="form flex direction-column login-login">
                <FormControl className="has-wrapper">
                  <TextField
                    id="email"
                    label="Email"
                    className="has-input input-lg"
                    value={email}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    onChange={onChangeEmail}
                  />
                  {props.authUser.hasError && (
                    <span>{props.authUser.errorMessage}</span>
                  )}
                  <span className="has-icon"><i className="ti-email" /></span>
                </FormControl>
                <FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit}
                  >
                    <IntlMessages id="text.resetPasswordButton" />
                  </Button>
                </FormControl>
              </div>
            ) : <IntlMessages id="text.resetPasswordSuccessMessage" />
        }
      </Block>
    </div>
  );
};

ForgotPassword.propTypes = {
  requestResetUserPasswordInFirebase: func.isRequired,
  authUser: shape({
    hasError: bool,
    errorMessage: string,
  }).isRequired,
};

const mapDispatchToProps = {
  requestResetUserPasswordInFirebase,
};

const mapStateToProps = ({ authUser }) => ({
  authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
