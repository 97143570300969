import { camelize } from 'humps';

const types = {
  catalog: 'catalog',
  system: 'failures',
  productsHistory: 'Virtual category',
};

export default () => {
  const key = window.location.pathname.split('/').filter((el) => el)[0];
  return types[camelize(key)] || [];
};
