/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { aggregation } from '../../../../../utils/variables';
import getIntervalValue from '../../../../../utils/getIntervalValue';

const useMessage = (state, props) => {
  useEffect(() => {
    if (!isEmpty(state.filter) && !props.message.fetching) {
      props.fetchMessageList({
        aggregations: [{
          field: aggregation.messageId,
          operator: 'terms',
          pagination: {
            page: 1,
            limit: 100,
          },
          aggregations: [{
            field: aggregation.timestamp,
            operator: 'date_histogram',
            calendarInterval: getIntervalValue(state.timeFrameValue),
          }],
        }],
        fields: ['message_id'],
        pagination: state.pagination,
        filter: state.filter,
      });
    }
  }, [
    state.filter,
    state.refreshFilter,
  ]);
};

export default useMessage;
