export const errorLevelList = [{
  value: 'warning',
  label: 'Warning',
}, {
  value: 'critical',
  label: 'Critical',
}];

export default {
  errorLevelList,
};
