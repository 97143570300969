import React from 'react';
import { isEmpty } from 'lodash';

import Table from '../../../components/Table';

import { rowsPerPageOptions } from '../../../utils/defaultData';
import { defaultProps, propTypes } from './propTypes';

const CatalogBody = (props) => {
  const {
    loading, list, chart, listTotal, selectedError, tablePlaceholder,
    header, logType,
  } = props;
  let extra = {};
  if (chart.selected && !isEmpty(chart.selected)) {
    extra = {
      title: 'text.errorsType',
      subtitle: 'text.mapping',
    };
  }

  return (
    <Table
      extra={extra}
      loading={loading.table}
      list={list}
      header={header}
      total={listTotal}
      logType={logType}
      onTableRowClick={props.onTableRowClick}
      onPaginationChange={props.onPaginationChange}
      selectedError={selectedError}
      onErrorDetailsClose={props.onErrorDetailsClose}
      tablePlaceholder={tablePlaceholder}
      pagination={{
        rowsPerPageOptions,
        data: props.pagination,
      }}
    />
  );
};

CatalogBody.propTypes = propTypes;
CatalogBody.defaultProps = defaultProps;

export default CatalogBody;
