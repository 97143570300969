
import moment from 'moment';
import { isEmpty } from 'lodash';

export default (dateString) => {
  if (isEmpty(dateString)) {
    return moment(new Date()).format();
  }

  const dateOptions = dateString.split('_');
  const rangeType = dateOptions.pop();
  const rangeNumber = Number(dateOptions.slice(1, 2));
  return moment(new Date()).add(-Math.abs(rangeNumber), rangeType).format();
};
