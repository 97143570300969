/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty, isEqual, sortBy } from 'lodash';
import getLogType from '../../../../../utils/getLogType';
import getDateRangeFilter from '../../../utils/getDateRangeFilter';
import capitalizeString from '../../../../../utils/capitalizeString';

const useSystemLog = (state, props) => {
  const fetchSystemLogs = () => {
    if (
      props.eventMessageType.searched
      && !isEmpty(props.settings.statusOptions)
      && !props.systemLog.fetching
    ) {
      let filter = [];
      let timeRangeGroup = [];
      let messageGroup = [];
      let errorLevelGroup = [];
      const origin = state.originFilter;
      const timeRange = [...state.timeFrameFilter];
      const message = [...state.messageFilter];

      const logTypesSearcFilter = {
        field: 'log_type',
        value: [getLogType(), capitalizeString(getLogType())],
        operator: 'in',
      };

      const groups = [origin, timeRange, logTypesSearcFilter].flat();

      if (!isEmpty(message)) {
        messageGroup = {
          condition: 'and',
          group: message,
        };
      }

      if (isEmpty(timeRange)) {
        timeRangeGroup = [{
          condition: 'and',
          group: getDateRangeFilter(state.timeFrameValue),
        }];
      }

      if (!isEmpty(state.errorLevelFilterGroup)) {
        errorLevelGroup = state.errorLevelFilterGroup;
      }

      if (!isEmpty(groups)) {
        filter = [
          {
            condition: 'and',
            group: groups,
          }, timeRangeGroup,
        ].flat();
        if (!isEqual(sortBy(filter), sortBy(state.filter))) {
          state.setFilter(filter);
        }
      }

      const searchFilter = {
        filter: filter.flat(),
        pagination: state.pagination,
      };

      const systemValues = {
        origins: state.originFilter,
        pagination: state.pagination,
        messages: state.messageFilter,
        timeFrameValue: state.timeFrameValue,
        originValue: state.originValue.toLowerCase(),
      };

      sessionStorage.setItem('systemValues', JSON.stringify(systemValues));
      if (!isEmpty(messageGroup)) searchFilter.filter.push(messageGroup);
      if (!isEmpty(errorLevelGroup)) searchFilter.filter.push(errorLevelGroup);
      props.fetchSystemLogList(searchFilter);
    }
  };

  useEffect(() => {
    fetchSystemLogs('system');
  }, [
    props.eventMessageType.searched,
    state.originFilter,
    state.sort,
    state.pagination,
    state.fields,
    state.logType,
    state.messageFilter,
    state.refreshFilter,
    !isEmpty(props.settings.statusOptions),
    state.timeFrameFilter,
    state.errorLevelFilterGroup,
  ]);
};

export default useSystemLog;
