import axios from 'axios';
import { isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
  getLogPath,
} from '../utils/paths';
import handleError from '../utils/handleError';

const logActions = new RestActions('system_log');
const {
  fetchFinished,
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
} = logActions;

export const fetchSystemLogList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => (dispatch, getState) => {
  const { searchList } = getState().eventMessageType;
  const { monitoring } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  const getEventMessage = (messageId) => {
    const messageItem = searchList.find((l) => l.messageId === messageId);
    return !messageItem ? messageId : messageItem;
  };

  dispatch(fetchAllStart());
  return axios.post(
    `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
    decamelizeKeys(body),
  ).then((res) => {
    const logList = res.data.data;
    const mappedList = logList && !isEmpty(logList) ? logList.map((log) => ({
      ...log,
      message: getEventMessage(log.message_id),
    })) : [];
    dispatch(fetchAllFinished({
      list: camelizeKeys(mappedList),
      total: res.data.total,
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
    }));
  });
};

export const updateSystemLog = (log) => (dispatch, getState) => {
  const { monitoring } = getState().settings.serviceConfigs;
  const updatedLog = omit(log, 'message');
  dispatch(updateStart());
  return axios.put(getLogPath(monitoring.apiPath, updatedLog.id), {
    data: decamelizeKeys(updatedLog),
  }).then(() => {
    // TODO check response after the API deploy
    dispatch(updateFinished({
      success: true,
      hasErrors: false,
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(updateFinished({
      error: errorRes, hasErrors: true,
    }));
  });
};

export const updateSystemLogMultiple = (array) => async (dispatch, getState) => {
  const { monitoring } = getState().settings.serviceConfigs;
  dispatch(updateMultipleStart());
  try {
    await axios.all(array.map((item) => axios.put(getLogPath(monitoring.apiPath, item.id), {
      data: decamelizeKeys(item),
    })));
    dispatch(updateMultipleFinished({ success: true }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(updateMultipleFinished({
      error: errorRes,
      hasError: true,
    }));
  }
};

export const setSystemLog = (item) => (dispatch) => {
  dispatch(fetchFinished({ item }));
};

export const clearSystemLog = () => (dispatch) => {
  dispatch(fetchFinished({ item: {} }));
};

export const setSystemLogList = (list) => (dispatch) => {
  dispatch(fetchAllFinished({ list }));
};

export const clearFromSystemLogState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
