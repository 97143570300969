/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useMount = (props) => {
  useEffect(() => {
    sessionStorage.removeItem('virtualCategoryValues');
    sessionStorage.removeItem('catalogValues');
    props.clearProductsHistoryTableSelectedState({});
  }, []);
};

export default useMount;
