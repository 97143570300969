
import getDate from '../../../utils/getDate';

const getRangeFilter = (from, to) => ([{
  field: '@timestamp',
  value: {
    from: {
      operator: 'gte',
      value: from,
    },
    to: {
      operator: 'lte',
      value: to,
    },
  },
  operator: 'range',
}]);

export default (range) => getRangeFilter(getDate(range), getDate());
