import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import ConnectedIntlProvider from './ConnectedIntlProvider';

import Catalog from '../pages/Catalog';
import System from '../pages/System';
import ProductsHistory from '../pages/ProductsHistory';
import Auth from '../pages/Auth';

import {
  rootAppPath,
  catalogAppPath,
  systemAppPath,
  signinAppPath,
  forgotPasswordAppPath,
  changePasswordAppPath,
  productsHistoryAppPath,
} from '../utils/paths';


export default () => {
  useEffect(() => {
    if (window.location.pathname === rootAppPath) {
      window.location.replace(catalogAppPath);
    }
  }, []);

  return (
    <ConnectedIntlProvider>
      <NotificationContainer />
      <Router>
        <Switch>
          <Route path={catalogAppPath} component={Catalog} />
          <Route path={systemAppPath} component={System} />
          <Route path={productsHistoryAppPath} component={ProductsHistory} />
          <Route exact path={signinAppPath} component={Auth} />
          <Route exact path={forgotPasswordAppPath} component={Auth} />
          <Route exact path={changePasswordAppPath} component={Auth} />
        </Switch>
      </Router>
    </ConnectedIntlProvider>
  );
};
