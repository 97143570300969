import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import { Button } from '@material-ui/core';

import Charts from '../../../components/Charts';

import Table from '../../../../../components/Table';
import Title from '../../../../../components/Title';
import ExtraActions from '../../../../../components/ExtraActions';
import Select from '../../../../../components/Select';

import IntlMessages from '../../../../../utils/IntlMessages';
import { catalogAppPath } from '../../../../../utils/paths';
import scrollTo from '../../../../../utils/scrollTo';
import { errorLevelList } from '../../../../../utils/staticData';

import useStateStore from '../hooks/useState';
import useDetailsLogMessage from '../hooks/useDetailsLogMessage';
import usePhysicalCategories from '../hooks/usePhysicalCategories';
import useDetailsLog from '../hooks/useDetailsLog';
import useMerchantLog from '../hooks/useMerchantLog';
import useMecrhant from '../hooks/useMecrhant';
import useAttribute from '../hooks/useAttribute';
import useAttributeOptions from '../hooks/useAttributeOptions';
import useUnmount from '../hooks/useUnmount';

import Log from '../components/Log';
import { getTableHeader } from '../utils/headCells';
import getTableRows from '../utils/getTableRows';
import getAttributeTableRows from '../../../utils/getAttributeTableRows';
import getToolbarActions from '../utils/getToolbarActions';

import getGroupFilter from '../../../utils/getGroupFilter';
import { defaultProps, propTypes } from './propTypes';
import { mapStateToProps, mapDispatchToProps } from './connect';
import bulkLogsStatusUpdate from '../../../utils/bulkLogsStatusUpdate';
import { mappingFields } from '../../../../../utils/variables';
import { defaultPagination } from '../../../utils/defaultData';
import getDateRangeFilter from '../../../utils/getDateRangeFilter';
import attributeAggList from '../../../../../utils/stash/attributeAggList';

const attributeSet = new Set(['byAttributes', 'byAttributesOrigin']);

const CatalogBody = (props) => {
  const state = useStateStore(props);
  useDetailsLogMessage(state, props);
  usePhysicalCategories(state, props);
  useDetailsLog(state, props);
  useMerchantLog(state, props);
  useMecrhant(state, props);
  useAttribute(state, props);
  useAttributeOptions(state, props);
  useUnmount(state, props);

  const aggregationValue = !isEmpty(state.aggregationType) ? state.aggregationType.value : '';

  const {
    log, detailsLogMessage, settings, detailsLog, physicalCategory,
    attribute, attributeOption,
  } = props;
  const { statusOptions } = settings;

  const logName = !isEmpty(log.item) ? log.item.name || log.item.key : <IntlMessages id="text.loading" />;
  const logPathByName = !isEmpty(log.item) ? log.item.pathByName || log.item.key : <IntlMessages id="text.loading" />;
  const { timeFrameValue } = JSON.parse(sessionStorage.getItem('catalogValues'));
  const timeFrameFilter = getDateRangeFilter(timeFrameValue)[0].value;

  const resetPagination = (limit) => {
    const data = {
      page: 1,
      limit: limit || state.pagination.limit,
    };
    state.setPagination(data);
    state.setPaginationDirectionKey('');
    props.deleteAttributeBeforeKeys();
  };

  const goToCatalogListPage = () => {
    const catalogValues = JSON.parse(sessionStorage.getItem('catalogValues'));
    catalogValues.messageFilter = [];
    sessionStorage.setItem('catalogValues', JSON.stringify(catalogValues));
    props.history.push(catalogAppPath);
  };

  const getDate = (date) => moment(date).format('D MMM YY');

  const handleTableRowClick = (row) => {
    if (!attributeSet.has(aggregationValue)) {
      const selectedLog = detailsLog.list.find((l) => l.sqsId === row.id);
      const logIndex = state.tableRows.findIndex((t) => t.sqsId === row.id);
      state.setSelectedLog({
        index: logIndex,
        log: selectedLog,
      });
      scrollTo({
        element: document.querySelector('.app-main.container-details'),
        behavior: 'smooth',
        top: document.body.scrollHeight,
      });
      // TODO fetch attribute set by "log.properties.attributeSetId"
    }
  };

  const handleLogClose = () => {
    state.setSelectedLog({});
  };

  const handleTableRowCheckClick = (selectedLogIds) => {
    handleLogClose();
    state.setSelectedLogIds(selectedLogIds);
  };

  const updateSelectedLogStatus = (e) => {
    const updateLog = {
      ...state.selectedLog.log,
      recordStatus: e.value,
    };
    state.setSelectedLog({ ...state.selectedLog, log: updateLog });
  };

  const handleLogStatusChange = (e, row, rowIndex) => {
    const rows = [...state.tableRows];
    const selectedRowIndex = !rowIndex && rowIndex !== 0 ? state.selectedLog.index : rowIndex;
    const keys = {
      recordStatus: e.origValue.label,
      item: {
        ...row.item,
        recordStatus: e.origValue.label,
      },
      data: {
        ...row.data,
        recordStatus: e.origValue.label,
      },
      updatedLog: {
        ...row.updatedLog,
        recordStatus: e.origValue.label,
      },
    };
    const updatedRow = ({
      ...row,
      ...keys,
    });

    if (selectedRowIndex !== -1) {
      rows[selectedRowIndex] = updatedRow;
    }

    state.setTableRows([...rows]);
    const dataToUpdate = {
      id: updatedRow.id,
      recordStatus: updatedRow.recordStatus,
    };

    props.updateLog(dataToUpdate);

    if (state.selectedLog && !isEmpty(state.selectedLog)) {
      updateSelectedLogStatus(e);
    }
  };

  const handleAggregationTypeChange = (data) => {
    handleLogClose();
    state.setAggregationType(data);
    props.clearFromAttributeState({ after: {} });
    resetPagination();

    if (!isEmpty(data.origValue)) {
      const newAggregation = [{
        operator: 'terms',
        field: data.origValue.field,
      }];
      state.setMessageAttributeAggregation(newAggregation);
    } else {
      state.setMessageAttributeAggregation([]);
    }
  };

  const handlePanelClick = (data) => {
    if (!isEmpty(data) && !isEmpty(data.row)) {
      const prefix = state.aggregationType.origValue.subAggregationPrefix;
      const { code } = data.row;
      const newAggregation = [{
        operator: 'terms',
        field: `${prefix}.${code}.keyword`,
        sort: {
          field: '_count',
          order: 'desc',
        },
      }];
      state.setMessageAttributeOptionAggregation(newAggregation);
      state.setSelectedAttribute(data);

      const newAttributeList = props.attribute.list.map((al) => {
        if (al.code === data.row.code) return ({ ...al, loading: true });
        return ({ ...al, loading: false });
      });
      props.replaceAttributeList(newAttributeList);
    } else {
      state.setMessageAttributeOptionAggregation([]);
      state.setSelectedAttribute({});
    }
  };

  const handleErrorLevelChange = (data) => {
    handleLogClose();
    let errorLevelFilterGroup = {};
    if (data.value) {
      errorLevelFilterGroup = {
        condition: 'and',
        group: [{
          field: mappingFields.level,
          value: data.origValue.label,
          operator: 'eq',
        }],
      };
    }

    state.setErrorLevel(data);
    state.setErrorLevelFilterGroup(errorLevelFilterGroup);
  };

  const handleBulkStatusUpdate = (recordStatus) => {
    const { tableRows, selectedLogIds } = state;
    const updatedLogs = bulkLogsStatusUpdate({ tableRows, selectedLogIds, recordStatus });
    state.setTableRows([...updatedLogs]);
  };

  const handleNewClick = () => {
    handleBulkStatusUpdate('New');
  };

  const handleAckClick = () => {
    handleBulkStatusUpdate('Ack');
  };

  const handleResolveClick = () => {
    handleBulkStatusUpdate('Resolved');
  };

  const handleIgnoreClick = () => {
    handleBulkStatusUpdate('Ignored');
  };

  const handleApplyClick = () => {
    handleLogClose();
    const selectedRows = state.tableRows.filter(
      (tr) => state.selectedLogIds.some((sl) => sl === tr.sqsId),
    );
    const rowsToUpdate = selectedRows.map((sr) => ({ id: sr.id, recordStatus: sr.recordStatus }));
    props.updateLogMultiple(rowsToUpdate);
  };

  const hadleChartDelete = () => {
    state.setSelectedChart({});
    state.setMessageFilter([]);
    state.setAggregationType({});
    state.setSelectedAttribute({});
    state.setMessageAttributeAggregation({});
    handleLogClose();
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
    if (!isEmpty(attribute.list)) props.updateAttributeList();
    if (!isEmpty(attributeOption.list)) props.updateAttributeOptionList();
  };

  const handleChartClick = (chart) => {
    handleLogClose();
    state.setSelectedChart(chart);
    state.setMessageFilter(getGroupFilter({
      field: mappingFields.messageId,
      list: [chart],
      operator: 'eq',
    }));

    if (
      !isEmpty(state.selectedChart)
      && chart.id === state.selectedChart.id
    ) {
      hadleChartDelete();
    }
    state.setPagination({
      page: defaultPagination.page,
      limit: state.pagination.limit,
    });
  };

  const handlePaginationChange = (data, paginationDirectionKey) => {
    handleLogClose();
    state.setPagination(data);

    if (data.page === 1) {
      resetPagination(data.limit);
    } else {
      state.setPaginationDirectionKey(paginationDirectionKey);
    }
  };

  const toolbarActions = {
    onNewClick: handleNewClick,
    onAckClick: handleAckClick,
    onResolveClick: handleResolveClick,
    onIgnoreClick: handleIgnoreClick,
    onApplyClick: handleApplyClick,
  };

  const tableActions = {
    onStatusChange: handleLogStatusChange,
  };

  const handleTableRows = () => {
    const disableExpand = state.aggregationType ? state.aggregationType.value === 'byAttributesOrigin' : false;
    const panelSettings = {
      className: disableExpand ? 'expansion-disabled' : '',
      isAllCollapsed: attribute.fetched,
    };
    switch (aggregationValue) {
      case 'byAttributes':
      case 'byAttributesOrigin':
        return getAttributeTableRows(attribute.list, handlePanelClick, panelSettings);

      default:
        return getTableRows(state.tableRows, tableActions, statusOptions);
    }
  };

  const isCheckboxVisible = () => {
    switch (aggregationValue) {
      case 'byAttributes':
      case 'byAttributesOrigin':
        return false;

      default:
        return true;
    }
  };

  const updatedTableRows = handleTableRows();
  const tableScrol = updatedTableRows.length > 9;
  const hasLog = state.selectedLog && !isEmpty(state.selectedLog);
  const extraHeight = hasLog ? 'table-details-open' : 'table-details-closed';
  const extraActionsClass = (
    !isEmpty(state.selectedChart) && !isEmpty(state.selectedLog.log)
  ) || !isEmpty(state.selectedLog.log)
    ? 'disabled'
    : '';

  const loading = detailsLog.fetching
    || attribute.fetching
    || attributeOption.fetching;

  const loaded = detailsLog.fetched
    || attribute.fetched
    || attributeOption.fetched;


  const isCategory = window.location.pathname.includes('category');
  return (
    <>
      <div className="container-fluid">
        <Title
          className="mt15"
          size="lg"
          prefix={(
            <KeyboardArrowLeftRoundedIcon
              onClick={goToCatalogListPage}
              className="events-all"
            />
          )}
          innerClassName="items-baseline events-no"
          title={!state.logTitle ? logName : state.logTitle}
          titleSecondary={`${getDate(timeFrameFilter.from.value)} - ${getDate(timeFrameFilter.to.value)}`}
          contentClassName="rct-title-content-secondary"
        >
          {isCategory && <>{!state.logPathByName ? logPathByName : state.logPathByName}</>}
        </Title>
      </div>

      <div className="section-block min-h-lg">
        {detailsLogMessage.fetching && (
          <div className="pos-abs full section-loader">
            <LinearProgress />
          </div>
        )}
        <Charts
          chartList={detailsLogMessage.list}
          loading={detailsLogMessage.fetching}
          selected={state.selectedChart}
          deleteSelected={hadleChartDelete}
          onChartClick={handleChartClick}
        />
        <Title
          className="flex justify-between items-center container-fluid pt0 pb0"
          innerClassName="no-events flex"
          titleSecondary={`Showing ${updatedTableRows.length} issues`}
        />
      </div>

      <Table
        headCells={getTableHeader(aggregationValue)}
        loading={loading}
        update={!loading && loaded}
        wrapperClassName={`table-with-details ${extraHeight}`}
        table={{
          hideColumns: ['id', 'className'],
          columnsWithExtraAction: ['status'],
          rowClassName: 'tr-wrapper',
          tableSticky: true,
          className: 'table-issues table-cell-sm table-cell-md',
          orderBy: 'id',
          rowsPerPage: 5,
          total: !isEmpty(state.messageAttributeAggregation) ? attribute.total : detailsLog.total,
        }}
        pagination={{
          pagination: state.pagination,
          rowsPerPageOptions: [5, 10, 25, 50, 100],
        }}
        checkbox={{
          visible: isCheckboxVisible(),
        }}
        onPaginationChange={handlePaginationChange}
        selectedRowId={hasLog ? state.selectedLog.log.sqsId : ''}
        rows={updatedTableRows}
        onRowClick={handleTableRowClick}
        onRowCheckClick={handleTableRowCheckClick}
        rowDetails={(
          <Log
            className={`flex direction-column ${tableScrol ? 'drawer-parent-scrol' : 'no'}`}
            statuses={statusOptions}
            selected={hasLog ? state.selectedLog.log : {}}
            category={physicalCategory.item}
            onClose={handleLogClose}
            onStatusChange={handleLogStatusChange}
          />
        )}
        toolbar={{
          className: 'table-toolbar-secondary fz-12',
          show: true,
          content: (
            getToolbarActions(toolbarActions).map((barButton) => (
              <Button
                key={barButton.label}
                onClick={barButton.onClick}
                color="primary"
                className={`btn-xs float-right btn-${barButton.type}-hover-sec ${barButton.className || ''}`}
              >
                <IntlMessages id={barButton.label} />
              </Button>
            ))
          ),
          extraContent: (
            <ExtraActions
              className={extraActionsClass}
              selectClassName="extra-actions-main-wrapper"
              staticLabel={<IntlMessages id="catalog.table.extarActions.label.filterBy" />}
              errorLevel={{
                name: 'errorLevel',
                onChange: handleErrorLevelChange,
                options: errorLevelList,
                staticLabelId: 'catalog.table.extarActions.label.errorLevel',
                size: 'xs',
                value: state.errorLevel.value,
              }}
              toggler={{
                className: 'toggler-secondary',
              }}
              extraList={state.selectedChart.id && [{
                value: 'filter',
                className: 'enabled-content',
                content: (
                  <Select
                    displayEmpty
                    className="extra-actions-main-wrapper"
                    size="xs"
                    value={state.logType}
                    staticLabel={<IntlMessages id="catalog.details.table.extarActions.label.aggregationType" />}
                    name="aggregateBy"
                    options={attributeAggList}
                    onChange={handleAggregationTypeChange}
                  />
                ),
                disabled: true,
              }]}
            />
          ),
        }}
      />
    </>
  );
};

CatalogBody.propTypes = propTypes;
CatalogBody.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogBody));
