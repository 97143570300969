import React from 'react';
import {
  oneOfType, number, string, node, arrayOf, object, func, bool,
  shape,
} from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { isEmpty } from 'lodash';

import useStateStore from './hooks/useState';
import useSelectValue from './hooks/useSelectValue';

const RCTSelect = (props) => {
  const {
    label,
    className,
    options,
    helperText,
    displayEmpty,
    emptyPlaceholder,
    staticLabel,
    name,
    size,
    disabledEmpty,
    defaultValue,
    inputProps,
  } = props;

  const state = useStateStore(props);
  useSelectValue(state, props);

  const handleChange = (event) => {
    const { target } = event;
    if (props.onChange) {
      props.onChange({
        name,
        value: target.value,
        origValue: options.find((o) => o.value === event.target.value),
      });
    }
    state.setSelectValue(event.target.value);
  };

  return (
    <FormControl className={`${className} ${staticLabel ? 'form-control-label-static flex items-baseline direction-row' : ''} select-${size}`}>
      {label && <InputLabel id="select-label-id">{label}</InputLabel>}
      {staticLabel && <div className="label-static">{staticLabel}</div>}
      <Select
        name={name}
        value={state.selectValue}
        defaultValue={defaultValue}
        onChange={handleChange}
        displayEmpty={displayEmpty}
        inputProps={inputProps}
      >
        {displayEmpty && <MenuItem disabled={disabledEmpty} value=""><em>{emptyPlaceholder}</em></MenuItem>}
        {options && !isEmpty(options) && options.map((o) => (
          <MenuItem
            key={`#${options.indexOf(o)}_${o.value}_${o.label}`}
            value={o.value}
          >
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

RCTSelect.propTypes = {
  className: string,
  defaultValue: string,
  label: string,
  size: string,
  name: string,
  staticLabel: oneOfType([string, node]),
  emptyPlaceholder: oneOfType([string, node]),
  helperText: oneOfType([string, node]),
  options: arrayOf(oneOfType([object, string, number])),
  onChange: func,
  displayEmpty: bool,
  disabledEmpty: bool,
  inputProps: shape({
    name: string,
    id: string,
  }),
};

RCTSelect.defaultProps = {
  className: '',
  defaultValue: '',
  label: '',
  size: 'default',
  name: '',
  staticLabel: '',
  emptyPlaceholder: 'All',
  helperText: '',
  options: [],
  onChange: null,
  displayEmpty: false,
  disabledEmpty: false,
  inputProps: {
    name: 'name',
    id: 'select-native',
  },
};

export default RCTSelect;
