/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getAggregationField } from '../../../../../utils/getAggregationField';
import { keywordTypeSet, mappingFields } from '../../../../../utils/variables';
import getIntervalValue from '../../../../../utils/getIntervalValue';
import getUniqArrayOfObjects from '../../../../../utils/getUniqArrayOfObjects';

const useDetailsLogMessage = (state, props) => {
  useEffect(() => {
    if (
      !props.detailsLogMessage.fetched
      && props.eventMessageType.searched
      && !isEmpty(state.generalFilter)
    ) {
      const catalogValues = JSON.parse(sessionStorage.getItem('catalogValues'));
      const categoryLogSearchFilter = { ...state.generalFilter };
      const catalogCategoryId = window.location.pathname.split('/').slice(-1).join();

      let aggField = '';

      switch (catalogValues.logType) {
        case 'category':
        case 'categories':
          aggField = mappingFields.propertiesCategories;
          break;

        default:
          aggField = getAggregationField({
            prefix: 'properties',
            key: catalogValues.logType,
            suffix: keywordTypeSet.has(catalogValues.logType) ? 'keyword' : false,
          });
          break;
      }

      categoryLogSearchFilter.filter.push({
        condition: 'and',
        group: [
          {
            field: aggField,
            value: catalogCategoryId,
            operator: 'eq',
          },
        ],
      });

      const extraAggregation = {
        field: mappingFields.timestamp,
        operator: 'date_histogram',
        calendarInterval: getIntervalValue(catalogValues.timeFrameValue),
      };

      const parentAggregation = [{
        field: aggField,
        operator: 'terms',
        aggregations: [
          [...categoryLogSearchFilter.aggregations].shift().aggregations,
          extraAggregation,
        ].flat(),
      }];

      delete extraAggregation.aggregations;

      categoryLogSearchFilter.aggregations = parentAggregation;
      const mappedFilter = getUniqArrayOfObjects(categoryLogSearchFilter.filter);
      categoryLogSearchFilter.filter = mappedFilter;
      props.fetchDetailsLogMessageList(categoryLogSearchFilter);
    }
  }, [
    props.detailsLogMessage.fetched,
    props.eventMessageType.searched,
  ]);
};

export default useDetailsLogMessage;
