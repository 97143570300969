import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';

import {
  catalogAppPath,
  systemAppPath,
  settingsAppPath,
  notificationsAppPath,
  productsHistoryAppPath,
} from '../../utils/paths';


export const headerList = [
  {
    id: 0,
    key: 'catalog-h',
    value: 'Catalog',
    valueId: 'text.catalog',
    activeClassName: 'list-item-link-active',
    route: catalogAppPath,
  }, {
    id: 1,
    key: 'system',
    value: 'System',
    valueId: 'text.system',
    activeClassName: 'list-item-link-active',
    route: systemAppPath,
  }, {
    id: 2,
    key: 'productsHistory',
    value: 'Products history',
    valueId: 'header.nav.label.productsHistory',
    activeClassName: 'list-item-link-active',
    route: productsHistoryAppPath,
  },
];

export const headerRightList = ({ userName, onLogoutClick }) => [
  {
    id: 0,
    key: 'settings',
    value: 'Settings',
    icon: <SettingsIcon />,
    valueId: 'text.settings',
    hideLabel: true,
    className: 'label-hide disabled hidden',
    activeClassName: 'list-item-link-active',
    route: settingsAppPath,
  }, {
    id: 1,
    key: 'bell',
    value: 'Notifications',
    icon: <NotificationsIcon />,
    badgeClassName: 'badge-sm',
    valueId: 'text.notifications',
    hideLabel: true,
    className: 'label-hide disabled hidden',
    activeClassName: 'list-item-link-active',
    notifications: 1,
    route: notificationsAppPath,
  }, {
    id: 2,
    key: 'username',
    value: userName,
    className: 'no-event disabled',
    valueId: '',
    activeClassName: 'list-item-link-active',
    route: '/profile',
    ignoreTranslation: true,
  }, {
    id: 3,
    key: 'logout',
    value: 'Log out',
    valueId: 'text.logout',
    activeClassName: '',
    route: window.location.pathname,
    onClick: onLogoutClick,
  },
];


export default {
  headerList,
  headerRightList,
};
