import React, { useState, useEffect } from 'react';
import {
  shape, bool, arrayOf, string, func, number, object, any, array,
  oneOfType, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { CircularProgress, TablePagination } from '@material-ui/core';

import TableBody from './TableBody';
import Title from '../../../components/Title';
import Select from '../../../components/Select';
import IntlMessages from '../../../utils/IntlMessages';
import attributeAggList from '../../../utils/stash/attributeAggList';
import sortBy from '../utils/sortBy';
import Block from '../../../components/Block';
import Skeleton from '../../../components/Skeleton';
import { defaultPagination } from '../utils/defaultData';
import Pagination from '../../../components/Pagination';

const Table = (props) => {
  const {
    loading, list, logType, extra, pagination, total, extraActions, useTablePagination,
  } = props;
  const disabledClassName = loading ? 'disabled-loading' : '';
  const [rowsPerPage, setRowsPerPage] = useState(defaultPagination.limit);
  const [page, setPage] = useState(0);
  const showExtraPagination = !isEmpty(list.list) && list.list.length > 10;

  useEffect(() => {
    setRowsPerPage(props.pagination.data.limit);
    setPage(props.pagination.data.page - 1);
  }, [props.pagination.data]);

  const handleChangePage = (event, newPage) => {
    const directionKey = page > newPage ? 'before' : 'after';
    props.onPaginationChange({
      page: newPage + 1,
      limit: rowsPerPage,
    }, directionKey);
  };

  const handleChangeRowsPerPage = (event) => {
    props.onPaginationChange({
      page: 1,
      limit: parseInt(event.target.value, 10),
    });
  };

  const tableTitile = attributeAggList.some((agg) => agg.value === logType)
    ? attributeAggList.find((agg) => agg.value === logType).label
    : logType;

  const paginationClass = total === 0 ? 'disabled' : '';

  return (
    <div className="table-container container container-fluid pagination-sm">
      <Title
        secondary
        className="flex justify-between items-center first-letter-up container-sticky"
        innerClassName="no-events flex"
        contentClassName="flex items-center"
        title={(
          <>
            {`${tableTitile} issues`}
            {loading && <CircularProgress className="m-8-l" size={20} />}
          </>
        )}
        titleSecondary={`Showing ${list.list.length} issues`}
      >
        {!useTablePagination && (
          <Pagination
            size="small"
            className="flex items-center"
            disabled={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total / rowsPerPage}
            staticLabel="catalog.pagination.rowsPerPage.label"
            onPageChange={props.onPaginationChange}
            onRowsPerPageChange={props.onPaginationChange}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
        {useTablePagination && (
          <TablePagination
            component="div"
            disabled={loading}
            labelRowsPerPage="Rows"
            className={`${paginationClass} ${loading ? 'disabled' : ''}`}
            count={total}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
        {!extraActions && (
          <Block className={`dib ${disabledClassName}`} size="sm">
            <Select
              size="sm"
              staticLabel={<IntlMessages id="text.by" />}
              name="sortBy"
              value={logType}
              options={sortBy}
              onChange={props.onLogTypeChange}
            />
          </Block>
        )}
        {extraActions && (
          <Block className={`dib ${disabledClassName}`} size="sm">
            <div className="extra-actions">{extraActions}</div>
          </Block>
        )}
      </Title>
      <div className={`flex flex-wrap pos-rel ${disabledClassName}`}>
        {!loading && isEmpty(list.list) && (
          <Skeleton
            type="tableCard"
            message={<IntlMessages id="text.noResult" />}
          />
        )}
        <TableBody
          extra={extra}
          sourceData={list}
          onTableCellTitleClick={props.onTableCellTitleClick}
        />
        {showExtraPagination && !useTablePagination && (
          <Pagination
            size="small"
            showFirstButton
            showLastButton
            className="pagination-footer items-center justify-center direction-row-rev last-end block"
            page={page}
            rowsPerPage={rowsPerPage}
            count={total / rowsPerPage}
            staticLabel="catalog.pagination.rowsPerPage.label"
            onPageChange={props.onPaginationChange}
            onRowsPerPageChange={props.onPaginationChange}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
        {showExtraPagination && useTablePagination && (
          <TablePagination
            component="div"
            className={`${paginationClass} ${loading ? 'disabled' : ''}`}
            count={total}
            page={page}
            labelRowsPerPage="Rows"
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  loading: bool,
  extra: shape(),
  list: oneOfType([object, array, any]),
  logType: string,
  onLogTypeChange: func,
  onTableCellTitleClick: func,
  onPaginationChange: func,
  pagination: shape({
    className: string,
    component: string,
    backIconButton: shape(),
    nextIconButton: shape(),
    rowsPerPageOptions: arrayOf(oneOfType([object, number, string])),
    data: shape({
      page: number,
      limit: number,
    }),
  }),
  total: number,
  extraActions: node,
  useTablePagination: bool,
};

Table.defaultProps = {
  loading: false,
  extra: {},
  list: [],
  logType: 'merchant',
  onLogTypeChange: null,
  onTableCellTitleClick: null,
  onPaginationChange: null,
  pagination: {
    component: 'div',
    backIconButton: {
      'aria-label': 'previous page',
    },
    nextIconButton: {
      'aria-label': 'next page',
    },
    rowsPerPageOptions: null,
  },
  total: 0,
  extraActions: null,
  useTablePagination: false,
};

export default Table;
