import getDate from '../../../utils/getDate';


export const defaultPagination = {
  limit: 100,
  page: 1,
};

export const rowsPerPageOptions = [
  {
    label: 5,
    value: 5,
  }, {
    label: 10,
    value: 10,
  }, {
    label: 20,
    value: 20,
  }, {
    label: 50,
    value: 50,
  }, {
    label: 100,
    value: 100,
  },
];

export const defaultTimeRange = [{
  field: '@timestamp',
  value: {
    from: {
      operator: 'gte',
      value: getDate('last_24_hours'),
    },
    to: {
      operator: 'lte',
      value: getDate(),
    },
  },
  operator: 'range',
}];

export const defaultTimestampSort = {
  field: '@timestamp',
  order: 'desc',
};

export default {
  defaultPagination,
  defaultTimeRange,
  rowsPerPageOptions,
  defaultTimestampSort,
};
