import React from 'react';
import {
  shape, arrayOf, string, func,
} from 'prop-types';
import { isEmpty } from 'lodash';
import TableCell from './TableCell';

const TableBody = (props) => {
  const { list, extra, selected, selectedColumn } = props;
  return (
    <tbody className="MuiTableBody-root">
      {list && !isEmpty(list) && list.map((cell) => (
        <TableCell
          extra={extra}
          key={`${list.indexOf(cell)}_${cell.sqsId}_${cell.id}`}
          onTableRowClick={props.onTableRowClick}
          sourceData={cell}
          selectedColumn={selectedColumn}
          className={selected.sqsId === cell.sqsId ? 'tr-active' : ''}
        />
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  extra: shape().isRequired,
  selected: shape({
    sqsId: string,
  }).isRequired,
  list: arrayOf(shape({
    id: string,
  })).isRequired,
  onTableRowClick: func.isRequired,
  selectedColumn: shape().isRequired,
};

export default TableBody;
