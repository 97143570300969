export const scopeValidations = {
  searchCategories: [
    {
      type: 'notOnlySymbols',
      message: 'productsHistory.scopeDialog.errors.alphanumeric',
    },
  ],
};

export default {
  scopeValidations,
};
