export const defaultHeader = [
  {
    id: 'id',
    disablePadding: true,
    label: '#ID',
  }, {
    id: 'className',
    disablePadding: true,
    label: 'TR className',
  }, {
    id: 'description',
    disablePadding: true,
    label: 'Error description',
    className: 'text-nowrap',
  }, {
    id: 'errorLevel',
    disablePadding: true,
    label: 'Error level',
    className: 'text-nowrap',
  }, {
    id: 'operationId',
    disablePadding: false,
    label: 'Operation ID',
    className: 'text-nowrap',
  }, {
    id: 'source',
    disablePadding: false,
    label: 'Source',
  }, {
    id: 'messageId',
    disablePadding: false,
    label: 'Message ID',
    className: 'text-nowrap',
  }, {
    id: 'fixAction',
    disablePadding: false,
    label: '',
  }, {
    id: 'lastModified',
    disablePadding: false,
    label: 'Last modified',
    className: 'text-nowrap',
  }, {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },
];

export const attributeHeaer = [{
  id: 'id',
  disablePadding: true,
  label: '#ID',
}, {
  id: 'attributeName',
  disablePadding: false,
  label: 'Attribute name',
}];

export const getTableHeader = (key) => {
  switch (key) {
    case 'byAttributes':
    case 'byAttributesOrigin':
      return attributeHeaer;
    default:
      return defaultHeader;
  }
};

export default {
  defaultHeader,
  attributeHeaer,
  getTableHeader,
};
