/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, shape, bool } from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import QueryString from 'query-string';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Block from '../../../components/Block';
import Logo from '../../../components/Header/Logo';
import IntlMessages from '../../../utils/IntlMessages';
import smallLogo from '../../../assets/img/logo-small.png';

import {
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '../../../actions/auth';

import {
  signinAppPath,
} from '../utils/paths';

const ChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);

  useEffect(() => {
    if (!verificationCode) {
      const parsedQueryString = QueryString.parse(location.search);
      const code = parsedQueryString.oobCode;
      setVerificationCode(code);
      props.verifyPasswordResetCode(code);
    }
  }, [props, verificationCode]);

  useEffect(() => {
    if (props.authUser.confirmResetFinished && !props.authUser.hasError) {
      props.history.push(signinAppPath);
    }
  }, [props, props.authUser.confirmResetFinished]);

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    props.confirmPasswordReset(newPassword, verificationCode);
  };

  return (
    <div className="login-wrapper flex justify-center items-center h-100">
      <Block className="login-body text-center shadow-lg p25">
        <div className="login-head">
          <Logo
            pathTo="/"
            src={smallLogo}
          />
        </div>
        <Divider />
        {!props.authUser.codeInvalid && (
          <div className="form flex direction-column login-login">
            <FormControl variant="outlined">
              <InputLabel htmlFor="newPassword">
                <IntlMessages id="text.password" />
              </InputLabel>
              <OutlinedInput
                name="newPassword"
                id="newPassword"
                className="has-input input-lg"
                type="password"
                value={newPassword}
                onChange={onChangeNewPassword}
                labelWidth={70}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <FormControl>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSubmit}
              >
                <IntlMessages id="text.changePasswordButton" />
              </Button>
            </FormControl>
          </div>
        )}
        {props.authUser.codeInvalid && <IntlMessages id="text.codeIsInvalid" />}
        {
          props.authUser.hasError && props.authUser.confirmResetFinished
          && <IntlMessages id="text.codeIsInvalid" />
        }
      </Block>
    </div>
  );
};

ChangePassword.propTypes = {
  confirmPasswordReset: func.isRequired,
  verifyPasswordResetCode: func.isRequired,
  authUser: shape({
    isInvalid: bool,
    confirmResetFinished: bool,
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapDispatchToProps = {
  confirmPasswordReset,
  verifyPasswordResetCode,
};

const mapStateToProps = ({ authUser }) => ({
  authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
