import {
  setProductsHistoryTableSelectedColumn,
  clearProductsHistoryTableSelectedState,
  getProductsHistoryTableDelta,
} from '../../../../actions/productsHistoryTable';

import {
  fetchProductsHistoryList,
  fetchAggregatedProductsHistoryList,
  clearFromProductsHistoryState,
  setProductsHistoryBeforeKey,
  deleteProductsHistoryBeforeKeys,
  setToProductsHistoryState,
} from '../../../../actions/productsHistory';

import {
  searchScopeCategories,
  fetchScopeCategoriesByIds,
  clearSearchListState,
  onToggleScopeCategoryTreeCollapse,
  fetchChildScopeCategories,
  handleLoadScopeSubcategories,
  fetchRootScopeCategories,
  clearFromScopeCategoryState,
} from '../../../../actions/scopeCategory';

import {
  fetchNavigationList,
} from '../../../../actions/navigation';

export const mapStateToProps = (state) => ({
  productsHistory: state.productsHistory,
  scopeCategory: state.scopeCategory,
  session: state.session,
  productsHistoryTable: state.productsHistoryTable,
  navigation: state.navigation,
});

export const mapDispatchToProps = {
  searchScopeCategories,
  fetchScopeCategoriesByIds,
  clearSearchListState,
  onToggleScopeCategoryTreeCollapse,
  fetchChildScopeCategories,
  handleLoadScopeSubcategories,
  fetchRootScopeCategories,
  fetchProductsHistoryList,
  fetchAggregatedProductsHistoryList,
  clearFromScopeCategoryState,
  clearFromProductsHistoryState,
  setProductsHistoryTableSelectedColumn,
  getProductsHistoryTableDelta,
  setProductsHistoryBeforeKey,
  deleteProductsHistoryBeforeKeys,
  clearProductsHistoryTableSelectedState,
  fetchNavigationList,
  setToProductsHistoryState,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
