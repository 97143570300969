export default (elementId) => {
  const scrollingContainer = document.getElementById(elementId);
  if (elementId && scrollingContainer) {
    let isScrolling = null;
    scrollingContainer.addEventListener('scroll', () => {
      if (!scrollingContainer.className.includes('no-events-inside')) {
        scrollingContainer.className += ' no-events-inside';
      }
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        scrollingContainer.classList.remove('no-events-inside');
      }, 300);
    });
  }
};
