import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
  grtProductAttributesSearchPath,
} from '../utils/paths';
import handleError from '../utils/handleError';

const logActions = new RestActions('attribute_option');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  updateAllStart,
  updateAllFinished,
} = logActions;

export const fetchAttributeOptionAggregationList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
  isExtraFetch = false,
}) => async (dispatch, getState) => {
  const { monitoring, cdms } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch(fetchAllStart());

  try {
    const res = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );

    const aggregationsByFieldBuckets = !isEmpty(aggregations)
      ? res.data.aggregations[aggregations[0].field].buckets
      : [];


    const filters = !isEmpty(aggregationsByFieldBuckets) && isExtraFetch
      ? [{
        filter: [{
          group: [{
            field: 'code',
            value: aggregationsByFieldBuckets.map((aB) => aB.key),
            operator: 'in',
          }],
        }],
        fields: ['code', 'label'],
        pagination: { page: 1, limit: 100 },
      }]
      : [];

    const promises = await !isEmpty(filters)
      ? filters.map(async (f) => {
        const postRest = await axios.post(
          grtProductAttributesSearchPath(cdms.apiPath), f,
        );

        const camelizedRes = camelizeKeys(postRest.data.data);
        return camelizedRes;
      })
      : [];

    const result = await Promise.all(promises);
    const productAttribute = result.flat();

    const mappedBuckets = aggregationsByFieldBuckets.map((a) => ({ ...a, key: a.key || 'no key' }));
    const mappetBuckets = mappedBuckets.map((b) => ({
      code: b.key,
      field: aggregations[0].field,
      count: b.doc_count,
      attribute: productAttribute.find((pA) => pA.code === b.key),
      options: [],
    }));

    const total = !isEmpty(aggregationsByFieldBuckets)
      ? res.data.aggregations[aggregations[0].field].total
      : 0;

    dispatch(fetchAllFinished({
      total,
      list: camelizeKeys(mappetBuckets),
    }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
      total: 0,
    }));
  }
};

export const updateAttributeOptionList = (list = []) => (dispatch) => {
  dispatch(updateAllStart());
  dispatch(updateAllFinished({ list }));
};


export const clearFromAttributeOptionState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
