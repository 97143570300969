/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const getFilter = (categoryIds) => {
  const groups = [{
    field: 'id',
    value: categoryIds,
    operator: 'in',
  }];
  return [{
    condition: 'or',
    group: groups,
  }];
};

const usePhysicalCategories = (state, props) => {
  useEffect(() => {
    if (
      !props.log.fetching && props.log.fetched
      && state.logType === 'category'
    ) {
      const categoryIds = props.log.list.map((l) => l.key);
      if (!isEmpty(categoryIds)) {
        props.searchPhysicalCategoriesByIds({
          pagination: {
            page: 1,
            limit: state.pagination.limit,
          },
          filter: getFilter(categoryIds),
        });
      }
    }
  }, [props.log.fetched]);

  useEffect(() => {
    if (state.categoriesFilter && !props.physicalCategory.searchedSelected) {
      const categoryIds = state.categoriesFilter.map((filter) => filter.value).filter((f) => f);
      if (!isEmpty(categoryIds)) {
        props.searchSelectedPhysicalCategoriesByIds({ filter: getFilter(categoryIds) });
      }
    }
  }, [state.categoriesFilter]);

  useEffect(() => {
    if (props.physicalCategory.searchedSelected && !props.physicalCategory.searchingSelected) {
      state.setTargetList(props.physicalCategory.selectedList);
    }
  }, [props.physicalCategory.searchedSelected, props.physicalCategory.searchingSelected]);
};

export default usePhysicalCategories;
