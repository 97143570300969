import React from 'react';
import {
  shape, string, number, func, oneOfType,
} from 'prop-types';

import Group from '../../../components/Group';
import DateTimeLabel from '../../../components/DateTimeLabel';
import getIconByLevel from '../../../utils/getIconByLevel';

const TableCell = (props) => {
  const {
    message,
    operationId,
    origin,
    submissionTimestamp,
    messageId,
    level,
  } = props.sourceData;

  const handleTableRowClick = () => props.onTableRowClick(props.sourceData);

  const levelClassName = level ? `tr-${level.toLowerCase()}` : 'tr-row';

  return (
    <tr
      className={`MuiTableRow-root tr-wrapper hover-pointer ${props.className} ${levelClassName}`}
      onClick={handleTableRowClick}
    >
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-message"><Group secondary>{message.message || '-'}</Group></td>
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-error-level"><Group secondary>{getIconByLevel(level) || '-'}</Group></td>
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-operationId"><Group secondary>{operationId || '-'}</Group></td>
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-origin"><Group secondary>{origin || '-'}</Group></td>
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-messageId"><Group secondary>{messageId || '-'}</Group></td>
      <td className="MuiTableCell-root MuiTableCell-body table-cell cell-submissionTimestamp">
        <Group secondary>
          {
            submissionTimestamp
              ? (
                <DateTimeLabel
                  value={submissionTimestamp}
                  format="MM/DD/YY LT"
                />
              )
              : '-'
          }
        </Group>
      </td>
    </tr>
  );
};

TableCell.propTypes = {
  sourceData: shape({
    sqsId: string,
    operationId: oneOfType([string, number]),
    messageId: oneOfType([string, number]),
    origin: string,
    submissionTimestamp: oneOfType([string, number]),
  }).isRequired,
  onTableRowClick: func,
  className: string,
};

TableCell.defaultProps = {
  onTableRowClick: null,
  className: '',
};

export default TableCell;
