import React from 'react';
import {
  func, shape, arrayOf, string, oneOfType, array, object,
} from 'prop-types';
import { isEmpty } from 'lodash';
import TableBody from '@material-ui/core/TableBody';

import Row from './Row';

const Body = (props) => {
  const {
    rows, selected, checkbox, hideColumns, selectedRowId, rowClassName,
    columnsWithExtraAction,
  } = props;
  const isSelected = (id) => selected.indexOf(id) !== -1;


  return (
    <TableBody>
      {
        !isEmpty(rows) && Array.isArray(rows)
          ? rows.map((row, index) => {
            const isItemSelected = isSelected(row.id);

            return (
              <Row
                checkbox={checkbox}
                hideColumns={hideColumns}
                row={row}
                className={rowClassName}
                selectedRowId={selectedRowId}
                index={index}
                onCheckClick={props.onRowCheckClick}
                onRowClick={props.onRowClick}
                key={`${row.id}_${rows.indexOf(row)}_${row.lastModified}`}
                selected={selected}
                isSelected={isItemSelected}
                columnsWithExtraAction={columnsWithExtraAction}
              />
            );
          })
          : rows
      }
    </TableBody>
  );
};

Body.propTypes = {
  rowClassName: string.isRequired,
  selectedRowId: string.isRequired,
  selected: arrayOf(string).isRequired,
  hideColumns: arrayOf(string).isRequired,
  columnsWithExtraAction: arrayOf(string).isRequired,
  rows: oneOfType([array, object]).isRequired,
  checkbox: shape({
    className: string,
    color: string,
  }).isRequired,
  onRowClick: func.isRequired,
  onRowCheckClick: func.isRequired,
};

export default Body;
