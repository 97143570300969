/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useTablePagination = (state, props) => {
  const { page, limit } = props.pagination.pagination;
  useEffect(() => {
    state.setPage(page - 1);
    state.setRowsPerPage(limit);
  }, [
    props.pagination.pagination,
    props.pagination.rowsPerPageOptions,
  ]);
};

export default useTablePagination;
