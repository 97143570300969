/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  oneOfType, array, node, func, shape, string,
} from 'prop-types';
import { setRequestInterceptors } from '../../utils/setRequestInterceptors';
import { auth } from '../../firebase';
import { fetchSession, clearSessionState } from '../../actions/session';
import { fetchAppSettings } from '../../actions/settings';
import { PermissionsContext } from '../PermissionsContext';
import ability from '../../utils/ability';
import {
  signinAppPath,
  forgotPasswordAppPath,
  changePasswordAppPath,
} from '../../utils/paths';

const Auth = (props) => {
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [permissions, setPermissions] = useContext(PermissionsContext);


  useEffect(() => {
    if (!subscribed) {
      const { fetchedOne, fetchingOne } = props.session;

      auth.onAuthStateChanged((user) => {
        if (user) {
          setAuthorized(true);
          setLoading(false);
          if (!fetchedOne && !fetchingOne) {
            props.fetchSession(user.uid);
            props.fetchAppSettings(user);
          }
        } else {
          const { pathname } = location;

          setAuthorized(false);
          setLoading(false);

          if (pathname !== signinAppPath
            && pathname !== forgotPasswordAppPath
            && pathname !== changePasswordAppPath) {
            location.replace(signinAppPath);
          }
        }
      });
      setSubscribed(true);
    }
  }, [props, subscribed]);


  useEffect(() => {
    if (props.session.fetchedOne) {
      setPermissions(props.session.item.permissions);
      if (
        !isEmpty(props.settings.serviceConfigs.cdms)
        && !isEmpty(props.settings.serviceConfigs.monitoring)
      ) {
        setRequestInterceptors(props.settings.serviceConfigs);
      }
      const abilityRules = [];
      const { permissions: sessionPermissions } = props.session.item;
      Object.keys(sessionPermissions).forEach((p) => {
        abilityRules.push({
          subject: p,
          actions: Object.keys(sessionPermissions[p]).map((k) => (sessionPermissions[p][k])),
        });
      });
      ability.update(abilityRules);
    }
  }, [props, props.session, props.session.fetchedOne]);
  return (
    (!loading && authorized && !isEmpty(permissions))
      || (!loading && !authorized)
      || props.authUser.success
      ? props.children
      : <div className="lds-dual-ring" />
  );
};

Auth.propTypes = {
  children: oneOfType([array, node]).isRequired,
  fetchSession: func.isRequired,
  clearSessionState: func.isRequired,
  fetchAppSettings: func.isRequired,
  session: shape({
    item: shape({
      permissions: shape({}),
    }),
  }).isRequired,
  settings: shape({
    serviceConfigs: shape({
      apiPath: string,
      token: string,
    }),
  }).isRequired,
  authUser: shape({
    success: false,
  }).isRequired,
};

Auth.defaultProps = {};

const mapStateToProps = (state) => ({
  session: state.session,
  authUser: state.authUser,
  settings: state.settings,
});

const mapDispatchToProps = {
  fetchSession,
  fetchAppSettings,
  clearSessionState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
