import React from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router-dom';
import {
  shape, func, node, bool,
} from 'prop-types';

import List from '../List';
import Logo from './Logo';
import { headerRightList } from '../../pages/utils/headerList';
// import LanguageProvider from './LanguageProvider';

import { logOut } from '../../actions/auth';
import logoSrc from '../../assets/img/logo-small.png';
import { catalogAppPath } from '../../utils/paths';

const Header = (props) => {
  const onLogoutClick = () => {
    props.logOut();
  };

  const { displayName } = props.session.item;
  const listParams = {
    onLogoutClick,
    userName: displayName || '',
  };
  const {
    horizontalMenu, subHeaderContent,
  } = props;

  return (
    <AppBar position="static" color="default" className="header">
      <Toolbar className="header-toolbar flex justify-content-between direction-column w-100">
        <div key="1" className="flex align-items-center w-100 h-100">
          {horizontalMenu && (
            <Logo
              src={logoSrc}
              pathTo={catalogAppPath}
            />
          )}
          <div className="lists-group items-center flex justify-between w-100">
            {subHeaderContent && (
              <div key="2" className="flex align-center">
                {subHeaderContent}
              </div>
            )}
            <List
              dir="horizontal"
              className="flex align-center list-item-clear"
              dataSource={headerRightList(listParams)}
              // last={<LanguageProvider />}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  session: shape({
    item: shape(),
  }).isRequired,

  logOut: func.isRequired,
  subHeaderContent: node,
  horizontalMenu: bool,
};

Header.defaultProps = {
  subHeaderContent: null,
  horizontalMenu: false,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  session: state.session,
});

const mapDispatchToProps = {
  logOut,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
