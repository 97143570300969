/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  isEmpty, sortBy, isEqual, uniq,
} from 'lodash';
import moment from 'moment';
import { emptyPagination, logTypeOptions } from '../../../utils/defaultData';
import { aggregation } from '../../../../../utils/variables';
import IntlMessages from '../../../../../utils/IntlMessages';
import getCompositeAggregations from '../../../utils/getCompositeAggregations';
import getDateRangeFilter from '../../../utils/getDateRangeFilter';

const useProductsHistory = (state, props) => {
  useEffect(() => {
    props.setProductsHistoryBeforeKey({
      data: props.productsHistory.after,
      directionKey: state.paginationDirectionKey,
    });
  }, [
    state.pagination,
  ]);


  const isFilterNotEmpty = (
    !isEmpty(state.productsHistoryFilter) && !isEmpty(state.dateRangeFilter)
  ) || (isEmpty(state.productsHistoryFilter) && !isEmpty(state.dateRangeFilter));

  const fetchProductsHistory = () => {
    let filter = {};
    let compositeDateRangeGroup = {};
    const compositeDateRange = [...state.compositeDateRangeFilter];
    const productsHistoryFilter = [...state.productsHistoryFilter];
    const sortColumn = props.productsHistoryTable.column;

    const logTypesSearcFilter = {
      field: 'log_type',
      value: logTypeOptions.find((lo) => lo.value === state.logType).label,
      operator: 'eq',
    };

    const groups = [productsHistoryFilter, logTypesSearcFilter].flat();

    if (!isEmpty(groups)) {
      filter = [
        {
          condition: 'and',
          group: groups,
        },
      ].flat();
      if (!isEqual(sortBy(filter), sortBy(state.filter))) {
        state.setFilter(filter);
      }
    }

    const defaultAggregation = [{
      field: 'count_products',
      operator: 'max',
    }];

    const compositeData = !isEmpty(state.paginationDirectionKey)
      ? ({
        order: sortColumn.order,
        size: state.pagination.limit,
        aggregations: defaultAggregation,
        suffix: {
          after: state.paginationDirectionKey === 'after'
            ? props.productsHistory[state.paginationDirectionKey]
            : props.productsHistory.beforeKeys[props.productsHistory.beforeKeys.length - 2],
        },
      })
      : ({
        order: sortColumn.order,
        size: state.pagination.limit,
        aggregations: defaultAggregation,
      });

    const searchFilter = {
      aggregations: getCompositeAggregations(compositeData),
      filter,
      pagination: state.pagination,
    };

    if (!isEmpty(compositeDateRange)) {
      compositeDateRangeGroup = {
        condition: 'and',
        group: compositeDateRange,
      };
      searchFilter.filter.push(compositeDateRangeGroup);
    }

    props.fetchProductsHistoryList(searchFilter);
  };

  useEffect(() => {
    if (
      isFilterNotEmpty
      && !props.productsHistory.fetching
      && !props.productsHistory.fetched
    ) {
      fetchProductsHistory(state.logType);
    }
  }, [
    state.productsHistoryFilter,
    state.dateRangeFilter,
    state.dateRangeValue,
    state.pagination,
    state.refreshFilter,
    state.compositeDateRangeFilter,
    state.logType,
    props.productsHistoryTable.column,
  ]);

  useEffect(() => {
    const {
      fetched, fetching, aggregateList, uniqIdsList, exludedDates, recursiveFetching,
      fetchAggregatedProductsHistoryStart, fetchAggregatedProductsHistoryFinished,
    } = props.productsHistory;
    if (
      exludedDates.length === state.tableDateRange.length
      && recursiveFetching
    ) {
      props.clearFromProductsHistoryState({
        recursiveFetching: false,
      });
    }
    if (
      isFilterNotEmpty
      && fetched
      && !fetching
      && isEmpty(aggregateList)
      && isEmpty(uniqIdsList)
      && !fetchAggregatedProductsHistoryStart
      && !fetchAggregatedProductsHistoryFinished
      && state.tableDateRange.length > 1
      && exludedDates.length !== state.tableDateRange.length
    ) {
      const nextDateToCall = [...state.tableDateRange.filter(
        (tdr) => !props.productsHistory.exludedDates.includes(tdr.origDate),
      )].shift();

      if (!isEmpty(nextDateToCall)) {
        props.clearFromProductsHistoryState({
          fetched: false,
          fetching: false,
        });
        props.setToProductsHistoryState({
          recursiveFetching: true,
        });
        const compositeRangeValue = {
          from: moment(nextDateToCall.origDate).startOf('day').format(),
          to: moment(nextDateToCall.origDate).endOf('day').format(),
        };
        const compositeTimeRangeFilter = getDateRangeFilter(compositeRangeValue);

        state.setCompositeDateRangeFilter(compositeTimeRangeFilter);
      }
    }
  }, [
    props.productsHistory.fetched,
    props.productsHistory.fetching,
    props.productsHistory.aggregateList,
    props.productsHistory.uniqIdsList,
    props.productsHistory.exludedDates,
    props.productsHistory.fetchAggregatedProductsHistoryStart,
    props.productsHistory.fetchAggregatedProductsHistoryFinished,
  ]);

  useEffect(() => {
    const { fetched } = props.productsHistory;
    if (fetched && isEmpty(state.tableDateRange)) {
      state.setTableBody([]);
      state.setTablePlaceholderMessage(null);
    }
  }, [state.tableDateRange]);

  useEffect(() => {
    const {
      fetched, fetchAggregatedProductsHistoryStart, uniqIdsList,
    } = props.productsHistory;

    let dateRangeGroup = {};
    const productsHistoryIdsFilter = !isEmpty(uniqIdsList)
      ? {
        condition: 'and',
        group: [{
          field: aggregation.entityId,
          value: uniq(uniqIdsList),
          operator: 'in',
        }],
      }
      : [];
    const filter = [...state.filter, productsHistoryIdsFilter].flat();

    const sortColumn = props.productsHistoryTable.column;
    const subAggregationSort = !isEmpty(sortColumn)
      ? {
        field: 'count_products.value',
        order: sortColumn.order,
      }
      : {};

    const updatedFilter = filter.map((f) => (f.group.some((g) => g.field !== '@timestamp') ? f : [])).flat();
    const searchFilter = {
      aggregations: [{
        field: aggregation.timestamp,
        operator: 'date_range',
        ranges: state.dateRangeAggregation,
        pagination: {
          page: state.pagination.page,
          limit: state.pagination.limit,
        },
        aggregations: [{
          field: aggregation.entityId,
          operator: 'terms',
          aggregations: [{
            field: aggregation.countProducts,
            operator: 'max',
          }],
        }],
      }],
      filter: updatedFilter,
      pagination: emptyPagination,
      logType: logTypeOptions.find((lo) => lo.value === state.logType),
    };

    if (!isEmpty(subAggregationSort)) {
      searchFilter.aggregations[0].aggregations[0].sort = subAggregationSort;
    }

    if (!isEmpty(state.dateRangeFilter)) {
      dateRangeGroup = {
        condition: 'and',
        group: state.dateRangeFilter,
      };
      searchFilter.filter.push(dateRangeGroup);
    }

    if (
      fetched && !isEmpty(uniqIdsList)
      && !fetchAggregatedProductsHistoryStart
    ) {
      props.fetchAggregatedProductsHistoryList(searchFilter);
    }

    if (fetched && isEmpty(uniqIdsList) && !isEmpty(state.tableDateRange)) {
      state.setTableBody([]);
      state.setTablePlaceholderMessage((
        <div className="skeleton-message-inner flex direction-column items-center">
          <IntlMessages id="productsHistory.table.placeholder.noPeriodResult" />
          <span className="skeleton-message-secondary mt10">
            {` ${moment(state.dateRangeValue[0]).format('ll')} - ${moment(state.dateRangeValue[1]).format('ll')}`}
          </span>
        </div>
      ));
    }
  }, [
    state.refreshFilter,
    props.productsHistory.fetched,
  ]);
};

export default useProductsHistory;
