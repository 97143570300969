import React from 'react';
import {
  string, func, oneOfType, number, arrayOf, bool, object,
} from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import IntlMessages from '../../utils/IntlMessages';
import Select from '../Select';

const PaginationCustom = (props) => {
  const {
    className, rowsPerPageOptions, staticLabel,
    count, page, size,
  } = props;
  const handlePageChange = (el, newPage) => {
    props.onPageChange({
      page: newPage,
      limit: props.rowsPerPage,
    });
  };

  const handleRowsPerPageChange = (selected) => {
    props.onRowsPerPageChange({
      page: props.page + 1,
      limit: selected.value,
    });
  };

  const disabledClass = props.disabled ? 'disabled' : '';

  return (
    Math.floor(count) > 0 && (
      <div className={`pagination-wrapper ${className} ${disabledClass}`}>
        <Select
          size="sm"
          className={`select-rows ${props.selectClassName}`}
          value={props.rowsPerPage}
          staticLabel={<IntlMessages id={staticLabel} />}
          name="limit"
          options={rowsPerPageOptions}
          onChange={handleRowsPerPageChange}
        />
        <Pagination
          size={size}
          page={page + 1}
          count={Math.floor(count)}
          showFirstButton={props.showFirstButton}
          showLastButton={props.showLastButton}
          onChange={handlePageChange}
        />
      </div>
    )
  );
};

PaginationCustom.propTypes = {
  className: string,
  selectClassName: string,
  size: string,
  count: number,
  page: number,
  staticLabel: string,
  rowsPerPage: oneOfType([object, number, string]),
  rowsPerPageOptions: arrayOf(oneOfType([object, number, string])),
  onPageChange: func,
  onRowsPerPageChange: func,
  showFirstButton: bool,
  showLastButton: bool,
  disabled: bool,
};

PaginationCustom.defaultProps = {
  className: '',
  selectClassName: 'medium',
  size: 'medium',
  staticLabel: '',
  count: null,
  page: null,
  rowsPerPage: {},
  rowsPerPageOptions: null,
  onPageChange: null,
  onRowsPerPageChange: null,
  showFirstButton: false,
  showLastButton: false,
  disabled: false,
};

export default PaginationCustom;
