export const getTinyLineSettings = ({ chart }) => {
  const getXPadding = () => {
    switch (chart.timeRange) {
      case 'last_24_hours':
        return 10;
      case 'last_6_hours':
        return 6;

      default:
        return 12;
    }
  };
  const getFontSize = () => {
    switch (chart.timeRange) {
      case 'last_24_hours':
      case 'last_30_days':
      case 'last_6_hours':
        return '9px';

      default:
        return '10px';
    }
  };
  const getXInterval = () => {
    switch (chart.timeRange) {
      case 'last_6_hours':
      case 'last_24_hours':
      case 'last_30_days':
        return 'preserveStartEnd';

      default:
        return 0;
    }
  };
  const getRotate = () => {
    switch (chart.timeRange) {
      default:
        return 'rotate(0)';
    }
  };
  const exclude = chart.timeRange === 'last_30_days' || chart.timeRange === 'last_24_hours';
  const displayCompact = chart.timeRange === 'last_30_days' || chart.timeRange === 'last_24_hours' || chart.timeRange === 'last_6_hours';

  const yAxis = {
    domain: chart.domain,
  };
  const xAxis = {
    rotate: getRotate(),
    x: getXPadding(chart.timeRange),
    y: displayCompact ? 10 : 12,
    fontSize: getFontSize(chart.timeRange),
    tickLine: displayCompact,
  };
  const dot = displayCompact ? { strokeWidth: 0, r: 0 } : { strokeWidth: 0, r: 0 };

  return ({
    height: 120,
    fill: '#fff',
    strokeWidth: 2,
    dot,
    dataKey: 'issues',
    className: displayCompact && !exclude ? 'move-first-tick' : '',
    yAxis: {
      type: 'number',
      domain: yAxis.domain || [0, 20],
      customTick: true,
      axisLine: false,
      tickLine: false,
      tickMargin: 0,
      width: 0,
      minTickGap: 3,
      rotate: yAxis.rotate,

      customTickSettings: {
        x: 18,
        y: 3,
        textAnchor: 'end',
        fill: '#b6b6b6',
        fontSize: '8px',
      },
    },
    xAxis: {
      axisLine: false,
      tickLine: xAxis.tickLine,
      customTick: true,
      rotate: xAxis.rotate,
      interval: getXInterval(chart.timeRange),
      minTickGap: 0,

      customTickSettings: {
        x: xAxis.x,
        y: xAxis.y,
        textAnchor: 'end',
        fill: '#b6b6b6',
        fontSize: xAxis.fontSize,
      },
    },
  });
};

export default {
  getTinyLineSettings,
};
