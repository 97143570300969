export default ({
  onNewClick, onAckClick, onResolveClick, onIgnoreClick, onApplyClick,
}) => [
  {
    label: 'text.new',
    type: 'primary',
    onClick: onNewClick,
  }, {
    label: 'text.ack',
    type: 'warning',
    onClick: onAckClick,
  }, {
    label: 'text.resolve',
    type: 'success',
    onClick: onResolveClick,
  }, {
    label: 'text.ignore',
    type: 'default',
    onClick: onIgnoreClick,
  }, {
    label: 'text.apply',
    type: 'info',
    className: 'btn-divider',
    onClick: onApplyClick,
  },
];
