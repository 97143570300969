import React from 'react';
import {
  shape, arrayOf, string, func, oneOfType, bool, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from '@material-ui/core';
import IntlMessages from '../../utils/IntlMessages';
import SourceItem from './SourceItem';
import TooltipWrapper from './TooltipWrapper';

const SourceList = (props) => {
  const {
    sourceList, targetList, tooltip, title, selectAll,
  } = props;

  const isEqual = targetList.length === sourceList.length;
  return (
    <div className="source-list-wrapper">
      {
        title && (
          <div className="source-list-title">
            {title}
            {
              selectAll && (
                <Button
                  onClick={props.onSelectAll}
                  color="primary"
                  className="btn-xs float-right"
                  disabled={isEmpty(sourceList) || isEqual}
                >
                  <IntlMessages id="text.selectAllCategories" />
                </Button>
              )
            }
          </div>
        )
      }
      <ul className="transfer-list transfer-source-list">
        {sourceList.map((sItem) => (
          <li
            key={sItem.id || sItem.value}
            className={`transfer-list-item ${targetList.find((tItem) => tItem.id === sItem.id) ? 'transfer-list-item-disabled' : ''}`}
          >
            <TooltipWrapper
              title={sItem.pathByName}
              tooltip={tooltip}
            >
              <SourceItem
                dataSource={{ targetList, sItem }}
                onClick={props.onClick}
              />
            </TooltipWrapper>
          </li>
        ))}
      </ul>
    </div>
  );
};

SourceList.propTypes = {
  selectAll: bool,
  sourceList: arrayOf(shape({})),
  targetList: arrayOf(shape({})),
  title: oneOfType([string, node]),
  onClick: func,
  onSelectAll: func,
  tooltip: shape({
    placement: string,
  }),
};

SourceList.defaultProps = {
  selectAll: false,
  title: '',
  sourceList: [],
  targetList: [],
  onClick: null,
  onSelectAll: null,
  tooltip: {},
};

export default SourceList;
