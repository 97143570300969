import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  FETCH_ROOT_SCOPE_CATEGORIES_START,
  FETCH_ROOT_SCOPE_CATEGORIES_FINISHED,
  FETCH_CHILD_SCOPE_CATEGORIES_START,
  FETCH_CHILD_SCOPE_CATEGORIES_FINISHED,
  CLEAR_SCOPE_CATEGORIES_STATE,
  SEARCH_SCOPE_CATEGORIES_START,
  SEARCH_SCOPE_CATEGORIES_FINISHED,
  CLEAR_FETCH_SCOPE_CATEGORY_STATE,
  TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE,
  HANDLE_LOAD_SCOPE_SUBCATEGORIES,
  CLEAR_SCOPE_CATEGORY_TREE_STATE,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START,
  FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED,
  SEARCH_VIRTUAL_CATEGORIES_FILTER_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_START,
  FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED,
  FETCH_PARENT_CATEGORIES_BY_IDS_START,
  FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED,
} from '../actions/types';

const initialState = {
  treeItems: [],
  searchResults: [],
  searched: false,
  searching: false,
  collapse: [],
  loadedKeys: [],
  parentWidthChildren: [],
  categoriesByIds: [],
};
const scopeCategoryRestTypes = restTypes('scope_category');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case FETCH_ROOT_SCOPE_CATEGORIES_START:
      return {
        ...state,
        fetchRootStarted: true,
        fetchRootFinished: false,
      };
    case FETCH_ROOT_SCOPE_CATEGORIES_FINISHED:
      return {
        ...state,
        treeItems: action.payload.list,
        fetchRootStarted: false,
        fetchRootFinished: true,
      };
    case FETCH_CHILD_SCOPE_CATEGORIES_START:
      return {
        ...state,
        fetchChildrenStarted: true,
        fetchChildrenFinished: false,
      };
    case FETCH_CHILD_SCOPE_CATEGORIES_FINISHED:
      return {
        ...state,
        treeItems: [
          ...state.treeItems,
          ...action.payload.list,
        ],
        fetchChildrenStarted: false,
        fetchChildrenFinished: true,
      };
    case CLEAR_SCOPE_CATEGORIES_STATE:
      return { ...restInitState, ...initialState };
    case CLEAR_FETCH_SCOPE_CATEGORY_STATE:
      return {
        ...state,
        item: {},
        fetchingOne: false,
        fetchedOne: false,
      };
    case SEARCH_SCOPE_CATEGORIES_START:
      return {
        ...state,
        searching: true,
        searched: false,
      };
    case SEARCH_SCOPE_CATEGORIES_FINISHED:
      return {
        ...state,
        searching: false,
        searched: true,
        searchResults: action.payload.list,
      };
    case scopeCategoryRestTypes.clearSearchListFinished:
      return {
        ...state,
        searchResults: [],
        searching: false,
        searched: false,
      };
    case scopeCategoryRestTypes.fetchOneStart:
      return {
        ...state,
        ...restStates.fetchOneStart,
      };
    case scopeCategoryRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case TOGGLE_SCOPE_CATEGORY_TREE_COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case HANDLE_LOAD_SCOPE_SUBCATEGORIES:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_SCOPE_CATEGORY_TREE_STATE:
      return {
        ...state,
        loadedKeys: [],
        collapse: [],
      };
    case FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_START:
      return {
        ...state,
      };
    case FETCH_ALL_CHILD_CATEGORIES_BY_PARENT_ID_FINISHED:
      return {
        ...state,
        parentWidthChildren: [
          ...state.parentWidthChildren,
          ...action.payload.parentWidthChildren,
        ],
      };
    case SEARCH_VIRTUAL_CATEGORIES_FILTER_START:
      return {
        ...state,
        searchFilterStarted: true,
        searchFilterFinished: false,
      };
    case SEARCH_VIRTUAL_CATEGORIES_FILTER_FINISHED:
      return {
        ...state,
        searchFilterStarted: false,
        searchFilterFinished: true,
        searchFilterResults: action.payload.list,
      };
    case scopeCategoryRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case FETCH_SCOPE_CATEGORIES_BY_IDS_START:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_SCOPE_CATEGORIES_BY_IDS_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_PARENT_CATEGORIES_BY_IDS_START:
      return {
        ...state,
        fetchingParentCatrgoiresByIds: true,
        fetchedParentCatrgoiresByIds: false,
      };
    case FETCH_PARENT_CATEGORIES_BY_IDS_FINISHED:
      return {
        ...state,
        ...action.payload,
        fetchingParentCatrgoiresByIds: false,
        fetchedParentCatrgoiresByIds: true,
      };
    default: return state;
  }
};
