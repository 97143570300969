import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START,
  PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED,
  PRODUCTS_HISTORY_SET_BEFORE_KEYS,
  PRODUCTS_HISTORY_DELETE_BEFORE_KEYS,
  PRODUCTS_HISTORY_SET_TO_STATE_FINISHED,
} from '../actions/types';

const initialState = {
  uniqIdsList: [],
  beforeKeys: [],
  exludedDates: [],
  aggregateList: [],
};
const productsHistoryTypeRestTypes = restTypes('products_history');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case productsHistoryTypeRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case productsHistoryTypeRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case productsHistoryTypeRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case productsHistoryTypeRestTypes.clearStateFinished:
      return {
        ...restInitState,
        ...restStates.clearStateFinished,
      };
    case PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START:
    case PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCTS_HISTORY_SET_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCTS_HISTORY_DELETE_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    case PRODUCTS_HISTORY_SET_TO_STATE_FINISHED:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
