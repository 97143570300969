import React from 'react';
import {
  Line, XAxis, LineChart,
} from 'recharts';
import {
  shape, arrayOf, object, string, bool, oneOfType, number,
} from 'prop-types';

const Chart = (props) => {
  const {
    width,
    height,
    sourceData,
    xAxis,
    dataKey,
  } = props;
  const customizedAxisTick = (tick) => {
    const { x, y, payload } = tick;
    const { customTickSettings } = xAxis;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={customTickSettings.x}
          y={customTickSettings.y}
          dy={customTickSettings.dy}
          textAnchor={customTickSettings.textAnchor}
          fill={customTickSettings.fill}
          fontSize={customTickSettings.fontSize}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <LineChart
      width={width}
      height={height}
      data={sourceData.data}
    >
      {xAxis && (
        <XAxis
          dataKey="name"
          axisLine={xAxis.axisLine}
          tickLine={xAxis.tickLine}
          tick={xAxis.customTick && customizedAxisTick}
          padding={xAxis.padding}
          margin={xAxis.margin}
        />
      )}
      <Line type="monotone" dataKey={dataKey} stroke={sourceData.color} strokeWidth={2} />
    </LineChart>
  );
};

Chart.propTypes = {
  dataKey: string,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  sourceData: shape({
    color: string,
    data: arrayOf(object),
  }).isRequired,
  xAxis: shape({
    axisLine: bool,
    tickLine: bool,
    customTick: bool,
    padding: shape(),
    margin: shape(),
  }),
};

Chart.defaultProps = {
  dataKey: 'issues',
  xAxis: null,
  width: 300,
  height: 100,
};

export default Chart;
