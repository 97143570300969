import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export default (current, prev) => {
  if (prev === 0 && current > prev) {
    return ({
      value: 100,
      type: 'danger',
      icon: <ArrowUpwardIcon />,
    });
  }
  if (prev > current) {
    return ({
      value: Number(100 - (current * 100) / prev),
      type: 'success',
      icon: <ArrowDownwardIcon />,
    });
  }
  return ({
    value: Number(100 - (current * 100) / prev),
    type: 'danger',
    icon: <ArrowUpwardIcon />,
  });
};
