import { isEmpty } from 'lodash';

const tableHeader = [
  {
    value: 'entityName',
    label: 'Name',
    className: 'events-no',
  },
];

const getDelta = (counts) => {
  if (counts.length > 1) {
    const firsInt = counts.find((i) => i > 0);
    const lastInt = counts.reverse().find((i) => i > 0);
    if (firsInt <= 0 && lastInt <= 0) return 0;
    return firsInt - lastInt;
  } return null;
};

export const getTableHeader = (cells) => ([...tableHeader, ...cells]);

export const getTableBody = (tHead, list) => {
  if (!isEmpty(list)) {
    return list.map((l, index) => {
      const counts = l.row.map((r) => {
        if (r.virtualCategory) return r.virtualCategory.countProducts;
        return 0;
      }).flat();
      return ({
        id: l.id,
        sqsId: l.sqsId || Math.random().toString(),
        pathByName: l.pathByName || { en: '' },
        name: l.name,
        type: l.type || '',
        urlKey: l.urlKey || '',
        logType: l.logType,
        cells: [
          {
            label: 'entityName',
            value: l.name || { en: '', he: '' },
            index,
            cellType: 'shapeLang',
          },
          ...tHead.map((th) => l.row.find((lr) => (lr.value === th.value))),
          l.row.length > 1
            ? [{
              label: 'Delta',
              className: 'events-no',
              value: getDelta(counts),
            }]
            : [],
        ].flat(),
      });
    });
  }
  return [];
};

export default {
  getTableHeader,
  getTableBody,
};
