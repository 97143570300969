/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '@material-ui/core/Link';
import { isEmpty } from 'lodash';
import moment from 'moment';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Select from '../../../../../components/Select';
import IntlMessages from '../../../../../utils/IntlMessages';
import getIconByLevel from '../../../../../utils/getIconByLevel';

const levels = {
  critical: 'danger-sec',
  warning: 'warning',
  error: 'danger',
};

const getDescription = (text, level) => (
  <div className="td-content flex items-center">
    <span className="td-text">{text || '-'}</span>
    {level && getIconByLevel(level) && <span className="td-icon">{getIconByLevel(level)}</span>}
  </div>
);

export default (rows, { onStatusChange }, statuses) => (
  !isEmpty(rows) && !isEmpty(statuses)
    ? rows.map((row, index) => {
      const handleClick = (e) => {
        onStatusChange(e, row, index);
      };
      const handleFixClick = () => {
        // TODO: redirect by action_url path. Check it later
        if (row.actionUrl) {
          window.open(row.actionUrl, '_blank');
        }
      };

      const { recordStatus } = row;
      const defaultStatus = statuses.find((s) => s.value === 'ignored');
      const isExist = recordStatus && statuses.find(
        (s) => s.key.includes(recordStatus.toLocaleLowerCase())
          || s.key === recordStatus.toLocaleLowerCase(),
      );

      const status = !isExist ? defaultStatus : isExist;
      const mappedStatuses = statuses.map(({ value, label }) => ({ value, label }));

      const messageId = row.message && !isEmpty(row.message)
        ? row.message.messageId || row.message.message
        : row.messageId;

      const className = row.level && row.level.toLowerCase() && levels[row.level.toLowerCase()]
        ? `tr-${levels[row.level.toLowerCase()]}`
        : 'tr-row';

      return ({
        id: row.sqsId || '',
        className,
        description: getDescription(row.message.moreInfo),
        errorLevel: <span className="td-icon">{getIconByLevel(row.level)}</span>,
        operationId: row.operationId || '-',
        source: row.origin || '-',
        messageId: messageId || '-',
        fixAction: (
          <Link
            onClick={handleFixClick}
            underline="always"
            className="flex items-center"
          >
            <IntlMessages id="text.fix" />
            <ChevronRightRoundedIcon className="fz-13" />
          </Link>
        ),
        lastModified: <div className="text-muted-sec text-nowrap">{moment(row['@timestamp']).format('MM/DD/YY LT') || '-'}</div>,
        status: (
          <div
            style={{
              color: status ? status.color : '#000',
            }}
          >
            <Select
              className={`select-chip select-${status ? status.type : 'default'}`}
              size="xs"
              name="origin"
              value={status.key}
              options={mappedStatuses}
              onChange={handleClick}
            />
          </div>
        ),
      });
    })
    : []
);
