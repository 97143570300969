import React from 'react';

const ChartCard = () => (
  <div className="skeleton">
    <div className="card">
      <div className="card-title">
        <div className="line lg" />
      </div>
      <div className="card-subtitle">
        <div className="line xs" />
      </div>
      <div className="card-body">
        <div className="block lg" />
      </div>
    </div>
  </div>
);

export default ChartCard;
