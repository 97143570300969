/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useUnmount = (props) => {
  useEffect(() => () => {
    props.clearFromPhysicalCategoryState({
      item: {},
      fetchedOne: false,
      selectedList: [],
    });
  }, []);
};

export default useUnmount;
