import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './pages/Home';
import Detail from './pages/Detail';

import { searchEventMessages } from '../../actions/eventMessageType';
import { clearProductsHistoryTableSelectedState } from '../../actions/productsHistoryTable';

import {
  catalogDetailAppPath, catalogAppPath,
} from '../../utils/paths';

import useEventMessageType from './hooks/useEventMessageType';
import useMount from './hooks/useMount';
import useUnmount from './hooks/useUnmount';


const Catalog = (props) => {
  useMount(props);
  useEventMessageType(props);
  useUnmount();

  return (
    <>
      <Route exact path={catalogAppPath} component={Home} />
      <Route exact path={catalogDetailAppPath} component={Detail} />
    </>
  );
};

export const mapStateToProps = (state) => ({
  settings: state.settings,
});


const mapDispatchToProps = {
  searchEventMessages,
  clearProductsHistoryTableSelectedState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
