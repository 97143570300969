/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const useProductsHistoryTableTable = (state, props) => {
  useEffect(() => {
    const {
      productsHistoryTable: { column },
      productsHistory: { fetched, list, uniqIdsList },
    } = props;
    if (fetched && !isEmpty(list) && !column.label && !isEmpty(uniqIdsList)) {
      const defaultSelectedColumn = [...state.tableDateRange.filter(
        (tdr) => !props.productsHistory.exludedDates.includes(tdr.origDate),
      )].shift();
      defaultSelectedColumn.index = 1;
      props.setProductsHistoryTableSelectedColumn(defaultSelectedColumn);
    }
  }, [
    props.productsHistory.fetched,
  ]);
};

export default useProductsHistoryTableTable;
