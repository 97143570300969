import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './pages/Home';

import { searchEventMessages } from '../../actions/eventMessageType';
import { clearProductsHistoryTableSelectedState } from '../../actions/productsHistoryTable';
import { clearFromPhysicalCategoryState } from '../../actions/physicalCategory';

import {
  systemAppPath,
} from '../../utils/paths';

import useMount from './hooks/useMount';
import useUnmount from './hooks/useUnmount';
import useEventMessageType from './hooks/useEventMessageType';


const System = (props) => {
  useMount(props);
  useUnmount(props);
  useEventMessageType(props);

  return (
    <>
      <Route exact path={systemAppPath} component={Home} />
    </>
  );
};

export const mapStateToProps = () => ({});

const mapDispatchToProps = {
  searchEventMessages,
  clearProductsHistoryTableSelectedState,
  clearFromPhysicalCategoryState,
};

export default connect(mapStateToProps, mapDispatchToProps)(System);
