import React from 'react';
import {
  number, node, string, bool,
} from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import Title from '../Title';

const ToolbarWrapper = (props) => {
  const {
    numSelected, content, className, extraContent, showSelectedTitle,
  } = props;

  return (
    <Toolbar className={className}>
      <Title
        innerClassName="secondary"
        title={showSelectedTitle && `${numSelected} selected`}
      />

      {numSelected > 0 && content && <div className="toolbar-content-wrapper">{content}</div>}
      {extraContent && <div className="toolbar-content-wrapper-extra ml-auto">{extraContent}</div>}
    </Toolbar>
  );
};

ToolbarWrapper.propTypes = {
  className: string.isRequired,
  numSelected: number.isRequired,
  content: node.isRequired,
  extraContent: node,
  showSelectedTitle: bool.isRequired,
};

ToolbarWrapper.defaultProps = {
  extraContent: null,
};

export default ToolbarWrapper;
