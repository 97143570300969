import { useState } from 'react';

export const useStateHook = (props) => {
  const [selectValue, setSelectValue] = useState(props.value);

  const state = {
    selectValue,
  };

  const setState = {
    setSelectValue,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
