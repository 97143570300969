import { useState } from 'react';
import { defaultPagination, logTypeOptions } from '../../../utils/defaultData';

export const useStateHook = () => {
  const [pagination, setPagination] = useState(defaultPagination);
  const [filter, setFilter] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [productsHistoryFilter, setProductsHistoryFilter] = useState('');

  const [tableDateRange, setTableDateRange] = useState([]);
  const [refreshFilter, setRefreshFilter] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const [compositeDateRangeFilter, setCompositeDateRangeFilter] = useState([]);
  const [dateRangeAggregation, setDateRangeAggregation] = useState([]);
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [showCategoriesDialog, setShowCategoriesDialog] = useState(false);
  const [selectedTreeItems, setSelectedTreeItems] = useState([]);
  const [tablePlaceholderMessage, setTablePlaceholderMessage] = useState(null);
  const [paginationDirectionKey, setPaginationDirectionKey] = useState('');
  const [logType, setLogType] = useState(
    [...logTypeOptions].shift().value,
  );

  const state = {
    pagination,
    filter,
    refreshFilter,
    dateRangeFilter,
    dateRangeValue,
    showCategoriesDialog,
    selectedTreeItems,
    productsHistoryFilter,
    tableDateRange,
    tableBody,
    selectedRow,
    dateRangeAggregation,
    tablePlaceholderMessage,
    compositeDateRangeFilter,
    paginationDirectionKey,
    logType,
  };

  const setState = {
    setPagination,
    setFilter,
    setRefreshFilter,
    setDateRangeFilter,
    setDateRangeValue,
    setShowCategoriesDialog,
    setSelectedTreeItems,
    setProductsHistoryFilter,
    setTableDateRange,
    setTableBody,
    setSelectedRow,
    setDateRangeAggregation,
    setTablePlaceholderMessage,
    setCompositeDateRangeFilter,
    setPaginationDirectionKey,
    setLogType,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
