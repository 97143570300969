import { useState } from 'react';

export const useStateHook = (props) => {
  const { table, isSortable, rows } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(isSortable ? table.orderBy : '');
  const [selected, setSelected] = useState([]);
  const [tableRows, setTableRows] = useState(rows);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(table.rowsPerPage);

  const state = {
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    tableRows,
  };

  const setState = {
    setOrder,
    setOrderBy,
    setSelected,
    setPage,
    setRowsPerPage,
    setTableRows,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
