import React from 'react';
import {
  bool, node, func, string,
} from 'prop-types';
import {
  DialogTitle, DialogContent, DialogActions, Dialog, Button,
} from '@material-ui/core';

const FormDialog = (props) => {
  const {
    open,
    title,
    onClose,
    onSubmit,
    children,
    closeButtonTitle,
    submitButtonTitle,
    maxWidth,
    className,
    hideCloseButton,
    disabledFooter,
  } = props;
  return (
    <Dialog
      className={className}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={maxWidth}
    >
      {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {
        (onClose || onSubmit) && (
          <DialogActions>
            {onClose && !hideCloseButton && (
              <Button
                variant="contained"
                onClick={onClose}
                className="btn-muted"
                disabled={disabledFooter}
              >
                {closeButtonTitle}
              </Button>
            )}
            {onSubmit && (
              <Button
                variant="contained"
                onClick={onSubmit}
                className="btn-info"
                disabled={disabledFooter}
              >
                {submitButtonTitle}
              </Button>
            )}
          </DialogActions>
        )
      }
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: bool.isRequired,
  children: node.isRequired,
  onClose: func,
  onSubmit: func,
  title: node,
  closeButtonTitle: node,
  submitButtonTitle: node,
  maxWidth: string,
  className: string,
  hideCloseButton: bool,
  disabledFooter: bool,
};

FormDialog.defaultProps = {
  title: null,
  onSubmit: null,
  onClose: null,
  closeButtonTitle: '',
  submitButtonTitle: '',
  maxWidth: 'sm',
  className: '',
  hideCloseButton: false,
  disabledFooter: false,
};

export default FormDialog;
