import React from 'react';
import {
  shape, arrayOf, string, number, oneOfType, func,
} from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const SourceItem = (props) => {
  const { dataSource: { targetList, sItem } } = props;
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(sItem);
    }
  };

  return (
    <div className="transfer-list-item-wrapper">
      <span className="transfer-list-item-label">
        <span className="transfer-list-item-label-container">{sItem.title || sItem.label || sItem.name}</span>
        {sItem.id && <div className="transfer-list-item-sublabel">{`(${sItem.id})`}</div>}
      </span>
      {!targetList.find((tItem) => tItem.id === sItem.id)
        ? (
          <IconButton
            aria-label="Select"
            className={`btn-ico-sm text-info  ml-10${targetList.find((tItem) => tItem.id === sItem.id) ? 'item-disabled' : ''}`}
            onClick={handleClick}
          >
            <AddRoundedIcon fontSize="inherit" />
          </IconButton>
        )
        : (
          <IconButton
            aria-label="Access"
            className={`btn-ico-sm text-info ml-10 ${targetList.find((tItem) => tItem.id === sItem.id) ? 'item-disabled' : ''}`}
          >
            <AddRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
    </div>
  );
};

SourceItem.propTypes = {
  dataSource: shape({
    targetList: arrayOf(shape({})),
    sItem: shape({
      id: oneOfType([string, number]),
    }),
  }),
  onClick: func,
};

SourceItem.defaultProps = {
  dataSource: {
    targetList: [],
    sItem: {},
  },
  onClick: null,
};

export default SourceItem;
