import React, { useState } from 'react';
import {
  string, arrayOf, shape, func, bool, oneOfType,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  IconButton, Checkbox, ListItemText, ListItemSecondaryAction, ListItem, List,
} from '@material-ui/core';

import CtaegoriesTreeNodePopover from '../ScopePopupPopover';

import roles from '../../utils/roles';

const ListCheckbox = (props) => {
  const [hoveredID, setHoveredID] = useState('');
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const {
    list, isRadio, className, session, ignoreScopeValidation, enabledHoverEffects,
    settings,
  } = props;

  const handleOnClick = (item) => {
    if (props.onChange) props.onChange(item.id);
    if (props.handleOnClick) props.handleOnClick(item.id);
  };

  const getTitle = (data) => {
    if (data.name && typeof data.name !== 'string') {
      return data.name.en;
    }
    return data.label || data.title || data.name;
  };

  const onMouseLeave = () => {
    setHoveredID('');
    setPopoverAnchor(null);
  };

  const onMouseEnter = (event, id) => {
    setHoveredID(id);
    setPopoverAnchor(event.target);
  };

  const getTreeNode = () => (
    <CtaegoriesTreeNodePopover
      hoveredCategoryId={hoveredID}
      popoverAnchor={popoverAnchor}
      settings={settings}
    />
  );

  return (
    <>
      <List className={className}>
        {list && list.length > 0 && list.map((data) => {
          let disabled = session.fetchedOne
            ? (!session.item.scope || !session.item.scope.some((c) => c.id === data.id))
              && !session.item.roles.some((r) => r === roles.admin.name)
            : true;

          if (ignoreScopeValidation) disabled = false;

          return (
            <ListItem
              disabled={props.disabled || disabled}
              button
              onClick={() => handleOnClick(data)}
              key={`item_${list.indexOf(data)}_${getTitle(data) || 'd'}`}
              onMouseEnter={(e) => onMouseEnter(e, data.id)}
              onMouseLeave={onMouseLeave}
              className={
                `list-checkbox-item ${isRadio && !isEmpty(props.selected)
                && !props.selected.some((sL) => (sL.id || sL) === data.id)
                  ? 'list-item-disabled'
                  : ''}`
              }
            >
              <Checkbox
                className="list-checkbox"
                color="primary"
                checked={props.selected.some((s) => (s.id || s) === data.id)}
              />
              <ListItemText className="list-checkbox-title" primary={getTitle(data)} />
              {data.icon && (
                <ListItemSecondaryAction>
                  <IconButton className="text-indigo"><i className={data.icon} /></IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>
      {enabledHoverEffects && getTreeNode()}
    </>
  );
};

ListCheckbox.propTypes = {
  onChange: func,
  list: arrayOf(shape({
    id: string,
  })).isRequired,
  selected: arrayOf(oneOfType([
    shape(), string,
  ])),
  isRadio: bool,
  className: string,
  handleOnClick: func,
  session: shape(),
  settings: shape(),
  ignoreScopeValidation: bool,
  disabled: bool,
  enabledHoverEffects: bool,
};

ListCheckbox.defaultProps = {
  onChange: null,
  isRadio: false,
  className: '',
  handleOnClick: null,
  session: {},
  settings: {},
  ignoreScopeValidation: false,
  disabled: false,
  enabledHoverEffects: false,
  selected: [],
};

export const mapStateToProps = (state) => ({
  session: state.session,
  settings: state.settings,
});

const actionCreators = {};

export default withRouter(connect(mapStateToProps, actionCreators)(ListCheckbox));
