import {
  shape, bool, arrayOf, string, func, oneOfType, number,
} from 'prop-types';

export const propTypes = {
  session: shape(),
  loading: shape({
    charts: bool,
  }),
  chart: shape({
    list: arrayOf(shape()),
    onDelete: func,
    onClick: func,
    selected: shape(),
  }),
  aggregatedLogs: arrayOf(shape({
    name: oneOfType([number, string]),
  })),
  onTableCellTitleClick: func,
  aggregatedLogsTotal: number,
};

export const defaultProps = {
  session: {},
  loading: {
    charts: false,
  },
  chart: {
    list: [],
    selected: {},
    onClick: null,
    onDelete: null,
  },
  aggregatedLogs: [],
  onTableCellTitleClick: null,
  aggregatedLogsTotal: 0,
};

export default {
  propTypes,
  defaultProps,
};
