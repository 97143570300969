import axios from 'axios';
import { isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
} from '../utils/paths';

import getChartData from '../utils/getChartData';
import getTimeRange from '../utils/getTimeRange';
import { getAggregationField } from '../utils/getAggregationField';
import getMessageName from '../utils/getMessageName';
import { keywordTypeSet, mappingFields } from '../utils/variables';
import getMessageColor from '../utils/getMessageColor';
import handleError from '../utils/handleError';
import getPrevDocCount from '../utils/getPrevDocCount';
import getSubstructedDate from '../utils/getSubstructedDate';

const messagActios = new RestActions('details_log_message');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
} = messagActios;

export const fetchDetailsLogMessageList = ({
  filter,
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => async (dispatch, getState) => {
  const { searchList } = getState().eventMessageType;
  const { monitoring } = getState().settings.serviceConfigs;

  const catalogValues = sessionStorage.getItem('catalogValues');
  const values = !isEmpty(catalogValues) ? JSON.parse(catalogValues) : null;

  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination: {
      page: 1,
      limit: 10000,
    },
  };

  if (!isEmpty(aggregations)) {
    const ommitAggSort = aggregations.map((agg) => omit(agg, ['sort']));
    body.aggregations = ommitAggSort;
    body.aggregations[0].pagination = {
      page: 1,
      limit: 10000,
    };
  }
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  const getMaxDomain = (list, item, type) => {
    switch (type) {
      case 'global':
        return Math.max(...list.map((mL) => mL.docCount));
      case 'local':
        return item.docCount;
      default:
        return item.docCount;
    }
  };

  dispatch(fetchAllStart());

  try {
    const res = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );
    const { aggregations: resAgg } = res.data;
    const timeRange = !isEmpty(values) && values.timeFrameValue
      ? values.timeFrameValue
      : 'last_24_hours';

    const dateOptions = timeRange.split('_');
    const type = dateOptions.pop();
    const numbers = Number(dateOptions.slice(1, 2));
    const prevPeriodBody = {
      ...body,
      filter: filter.map((f) => ({
        ...f,
        group: f.group.map((g) => {
          if (g.field === '@timestamp') {
            return ({
              ...g,
              value: {
                from: {
                  ...g.value.from,
                  value: getSubstructedDate({ date: g.value.from.value, numbers, type }),
                },
                to: {
                  ...g.value.to,
                  value: getSubstructedDate({ date: g.value.to.value, numbers, type }),
                },
              },
            });
          }
          return g;
        }),
      })),
    };

    const prevPeriodRes = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(prevPeriodBody),
    );

    const timeRangeList = getTimeRange(timeRange);
    const { logType } = JSON.parse(sessionStorage.getItem('catalogValues'));
    let aggField = '';

    switch (logType) {
      case 'category':
        aggField = mappingFields.propertiesCategories;
        break;

      default:
        aggField = getAggregationField({
          prefix: 'properties',
          key: logType,
          suffix: keywordTypeSet.has(logType) ? 'keyword' : false,
        });
        break;
    }

    const logList = !isEmpty(resAgg) ? resAgg[aggField].buckets : resAgg;
    const catalogCategoryId = window.location.pathname.split('/').slice(-1).join();
    const messageList = !isEmpty(logList) && camelizeKeys(logList.find(
      (l) => (
        typeof l.key === 'string'
          ? l.key === catalogCategoryId
          : l.key === Number(catalogCategoryId)
      ),
    )[mappingFields.messageId].buckets);

    const messageItem = camelizeKeys(logList[0]);
    const { buckets } = prevPeriodRes.data.aggregations[aggField];
    const preMessageList = camelizeKeys(
      buckets.map((pml) => pml[mappingFields.messageId].buckets).flat(),
    );

    const mappedList = !isEmpty(messageList)
      ? messageList.map((m) => ({
        ...m,
        id: m.key,
        value: m.key,
        label: m.label || m.key || `${messageList.indexOf(m)}_${m.merchantId}`,
        name: getMessageName(m, searchList) || m.key,
        domain: [0, getMaxDomain(messageList, m, 'local')],
        color: getMessageColor(m, searchList),
        errors: {
          current: m.docCount,
          prev: getPrevDocCount(preMessageList, m),
        },
        timeRange,
        data: getChartData({
          timeRange,
          source: messageItem,
          timeRangeList,
          dataKey: 'issues',
        }),
      }))
      : [];

    dispatch(fetchAllFinished({ list: mappedList }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
    }));
  }
};

export const clearFromDetailsLogMessageState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
