import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  LOG_SET_BEFORE_KEYS,
  LOG_DELETE_BEFORE_KEYS,
} from '../actions/types';

const initialState = {
  beforeKeys: [],
};
const logRestTypes = restTypes('log');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case logRestTypes.fetchOneFinished:
      return {
        ...state,
        ...restStates.fetchOneFinished,
        ...action.payload,
      };
    case logRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case logRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case logRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case logRestTypes.updateStart:
      return {
        ...state,
        ...restStates.updateStart,
        ...action.payload,
      };
    case logRestTypes.updateFinished:
      return {
        ...state,
        ...restStates.updateFinished,
        ...action.payload,
      };
    case LOG_SET_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    case LOG_DELETE_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
