import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { decamelize } from 'humps';
import {
  string, func, shape, arrayOf,
} from 'prop-types';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import {
  Divider, Link, IconButton, Tooltip,
} from '@material-ui/core';

import IntlMessages from '../../../../../../utils/IntlMessages';
import Title from '../../../../../../components/Title';
import Group from '../../../../../../components/Group';
import Select from '../../../../../../components/Select';
import getIconByLevel from '../../../../../../utils/getIconByLevel';

const Log = (props) => {
  const {
    className, selected, category, statuses, onStatusChange, onFixClick,
  } = props;
  const { recordStatus } = selected;

  const isSelected = selected && !isEmpty(selected);
  const defaultStatus = statuses.find((s) => s.value === 'ignored');

  const drawerIn = isSelected ? 'drawer-in' : 'drawer-out';
  const mappedStatuses = isSelected && statuses.map(({ value, label }) => ({ value, label }));
  const hasRecordStatus = recordStatus && isSelected
    && statuses.find((s) => s.key === recordStatus.toLocaleLowerCase());

  const status = hasRecordStatus
    ? statuses.find((s) => s.key === recordStatus.toLocaleLowerCase())
    : defaultStatus;

  const handleChange = (e) => {
    onStatusChange(e, selected);
  };

  const getAttributeList = () => (
    !isEmpty(selected.properties.attributes)
      ? (
        Object.keys(selected.properties.attributes).map((aKey) => (
          <div className="dtr" key={aKey}>
            <div className="rct-group-key dtc">{`${decamelize(aKey) || '-'}: `}</div>
            <div className="rct-group-value dtc">{selected.properties.attributes[aKey] || '-'}</div>
          </div>
        ))
      )
      : '-'
  );

  const getAttributeOriginList = () => (
    !isEmpty(selected.properties.attributesOrigin)
      ? (
        Object.keys(selected.properties.attributesOrigin).map((aKey) => (
          <div className="dtr" key={aKey}>
            <div className="rct-group-key dtc">{`${decamelize(aKey) || '-'}: `}</div>
            <div className="rct-group-value dtc">{selected.properties.attributesOrigin[aKey] || '-'}</div>
          </div>
        ))
      )
      : '-'
  );

  const getCategoryList = (list) => (!isEmpty(list) ? <div className="rct-group-value lh-md">{list.join(', ')}</div> : '-');

  const handleFixClick = () => {
    // TODO: redirect by action_url path. Check it later
    if (selected.actionUrl) {
      window.open(selected.actionUrl, '_blank');
    }
    if (onFixClick) onFixClick();
  };

  return (
    <div className={`drawer drawer-shadow log-details-wrapper ${className} ${drawerIn}`}>
      <Title
        size="md"
        suffix={(
          <div className="actions-wrapper flex items-baseline events-all">
            {isSelected && (
              <div
                className="flex self-center mr10"
                style={{
                  color: status ? status.color : '#000',
                }}
              >
                <Select
                  className={`select-chip select-${status ? status.type : 'default'}`}
                  size="xs"
                  name="origin"
                  value={status.key}
                  options={mappedStatuses}
                  onChange={handleChange}
                />
              </div>
            )}
            <Divider orientation="vertical" light flexItem variant="middle" />
            <Tooltip title={<IntlMessages id="text.fix" />}>
              <IconButton aria-label="fix" color="primary" onClick={handleFixClick}>
                <BuildRoundedIcon
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
            <IconButton aria-label="close" color="secondary" onClick={props.onClose}>
              <CloseRoundedIcon
                fontSize="small"
              />
            </IconButton>
          </div>
        )}
        innerClassName="items-baseline justify-between events-no block"
        title={<IntlMessages id="text.errorDesctirption" />}
        titleSecondary={isSelected ? selected.message.moreInfo : '-'}
        secondaryTitleClassName="mr-auto"
      />

      {isSelected && (
        <div className="group-column scroll-y scroll-white">
          <Group secondary>
            <div className="text-muted-sec">
              {moment(selected['@timestamp']).format('MM/DD/YY LT')}
            </div>
          </Group>
          {selected.level && (
            <Group
              secondary
              withIntl
              title="catalog.table.logDetails.label.errorLevel"
              contentClassName={`flex items-center text-${selected.level.toLowerCase() || 'default'}`}
            >
              {getIconByLevel(selected.level)}
              <span className="group-text ml5 lh-sm">{selected.level || '-'}</span>
            </Group>
          )}

          <Group secondary withIntl title="text.productId">{selected.properties.productId || '-'}</Group>
          <Group secondary withIntl title="text.idType" contentClassName="text-up">{selected.properties.idType || '-'}</Group>
          <Group secondary withIntl title="text.origin">{selected.origin || '-'}</Group>
          <Group secondary withIntl title="text.operationId">{selected.operationId || '-'}</Group>

          <Group secondary withIntl wrapper title="text.properties">
            <Group secondary withIntl title="text.userId">{selected.properties.userId || '-'}</Group>
            <Group secondary withIntl title="text.merchantId">{selected.properties.merchantId || '-'}</Group>
            <Group secondary withIntl title="text.categoryName">{category.name || selected.properties.category || '-'}</Group>
            <Group secondary withIntl title="catalog.details.table.draw.attributeSetId.label">{selected.properties.attributeSetId || '-'}</Group>
            <Group secondary withIntl contentClassName="dt th" title="text.attributes">{getAttributeList()}</Group>
            <Group secondary withIntl contentClassName="dt th" title="catalog.details.table.draw.attributesOrigin.label">
              {getAttributeOriginList()}
            </Group>
            <Group secondary withIntl title="catalog.details.table.draw.categories.label">{getCategoryList(selected.properties.categories)}</Group>
          </Group>

          <Group>
            <Divider className="mtb-lg dotted" />
          </Group>

          <Group secondary withIntl><IntlMessages id="text.moreInfo" /></Group>
          <Group secondary withIntl title="text.ip">{selected.ip || '-'}</Group>
          <Group secondary withIntl title="text.clientIp">{selected.clientIp || '-'}</Group>
          <Group secondary withIntl title="text.thread">{selected.thread || '-'}</Group>
          <Group secondary withIntl title="text.level">{selected.level || '-'}</Group>
          <Group secondary withIntl title="text.origin">{selected.origin || '-'}</Group>
          <Group secondary withIntl title="text.logType">{selected.ilogTypep || '-'}</Group>
          <Group secondary withIntl title="text.messageId">
            {selected.message.messageId || '-'}
            {selected.message.id && <small className="text-muted-sec ml5">{`(${selected.message.id || '-'})`}</small>}
          </Group>
          <Group secondary withIntl title="text.reasonCode">{selected.reasonCode || '-'}</Group>
          <Group secondary withIntl title="text.responseCode">{selected.responseCode || '-'}</Group>
          <Group secondary withIntl title="text.trace">{selected.trace || '-'}</Group>
          <Group secondary withIntl title="text.host">{selected.host || '-'}</Group>
          <Group secondary withIntl title="text.url">
            {(
              <Link
                target="_blank"
                rel="noreferrer"
                href={selected.url || '#'}
              >
                {selected.url || '-'}
              </Link>
            )}
          </Group>
          <Group secondary withIntl title="text.operationName">{selected.operationName || '-'}</Group>
          <Group secondary withIntl title="text.environment">{selected.environment || '-'}</Group>
          <Group secondary withIntl title="text.agent">{selected.agent || '-'}</Group>
        </div>
      )}
    </div>
  );
};

Log.propTypes = {
  className: string,
  onFixClick: func,
  onStatusChange: func,
  onClose: func,
  selected: shape({
    origin: string,
  }),
  category: shape({
    name: string,
  }),
  statuses: arrayOf(shape()).isRequired,
};

Log.defaultProps = {
  className: '',
  onFixClick: null,
  onStatusChange: null,
  onClose: null,
  selected: {},
  category: {},
};

export default Log;
