import React, { useState, useEffect } from 'react';
import {
  shape, bool, arrayOf, string, func, number, object, oneOfType, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { CircularProgress } from '@material-ui/core';

import Title from '../../../components/Title';
import IntlMessages from '../../../utils/IntlMessages';
import Skeleton from '../../../components/Skeleton';
import { defaultPagination } from '../utils/defaultData';
import Pagination from '../../../components/Pagination';
import Block from '../../../components/Block';

import TableHeader from './TableHeader';
import TableBody from './TableBody';
import ErrorDetails from './ErrorDetails';

const Table = (props) => {
  const {
    loading, list, logType, extra, pagination, total, selectedError, extraActions,
  } = props;
  const disabledClassName = loading ? 'disabled-loading' : '';
  const [rowsPerPage, setRowsPerPage] = useState(defaultPagination.limit);
  const [page, setPage] = useState(0);

  const showExtraPagination = !isEmpty(list) && list.length > 10;

  useEffect(() => {
    setRowsPerPage(props.pagination.data.limit);
    setPage(props.pagination.data.page - 1);
  }, [props.pagination.data]);

  const handlePerRowChange = (data) => {
    props.onPaginationChange({
      page: 1,
      limit: data.limit,
    });
  };

  const drawerIn = isEmpty(selectedError) ? 'table-details-closed' : 'table-details-open';

  return (
    <div className={`table-container container container-fluid pagination-sm ${drawerIn}`}>
      <ErrorDetails
        className="drawer-fixed flex direction-column"
        selected={selectedError}
        onClose={props.onErrorDetailsClose}
      />
      <div className="container-sticky pb0">
        <Title
          secondary
          className="flex justify-between items-center first-letter-up"
          innerClassName="no-events flex"
          contentClassName="flex items-center"
          title={(
            <>
              {`${logType} issues`}
              {loading && <CircularProgress className="m-8-l" size={20} />}
            </>
          )}
          titleSecondary={`Showing ${list.length} issues`}
        >
          <Pagination
            size="small"
            className="flex items-center"
            disabled={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            count={total / rowsPerPage}
            staticLabel="catalog.pagination.rowsPerPage.label"
            onPageChange={props.onPaginationChange}
            onRowsPerPageChange={handlePerRowChange}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
          {extraActions && (
            <Block className={`dib ${disabledClassName}`} size="sm">
              <div className="extra-actions">{extraActions}</div>
            </Block>
          )}
        </Title>
        {!isEmpty(list) && (
          <table className="table no-shadow table-cell-sm table-cell-md block mt-20">
            <TableHeader
              list={props.tableHeader}
            />
          </table>
        )}
      </div>
      <div className={`flex flex-wrap pos-rel ${disabledClassName}`}>
        {!loading && isEmpty(list) && (
          <Skeleton
            type="tableCell"
            rowLength={5}
            message={<IntlMessages id="text.noResult" />}
          />
        )}
        {!isEmpty(list) && (
          <table className="table table-cell-sm table-cell-md block table-with-details">
            <TableBody
              extra={extra}
              list={list}
              selected={selectedError}
              onTableCellTitleClick={props.onTableCellTitleClick}
              onTableRowClick={props.onTableRowClick}
            />
          </table>
        )}
        {showExtraPagination && (
          <Pagination
            showFirstButton
            showLastButton
            className="pagination-footer items-center justify-center direction-row-rev last-end block"
            page={page}
            rowsPerPage={rowsPerPage}
            count={total / rowsPerPage}
            staticLabel="catalog.pagination.rowsPerPage.label"
            onPageChange={props.onPaginationChange}
            onRowsPerPageChange={props.onPaginationChange}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
          />
        )}
      </div>
    </div>
  );
};

Table.propTypes = {
  loading: bool,
  extra: shape(),
  selectedError: shape(),
  list: arrayOf(shape()),
  tableHeader: arrayOf(object),
  logType: string,
  onTableCellTitleClick: func,
  onTableRowClick: func,
  onPaginationChange: func,
  onErrorDetailsClose: func,
  pagination: shape({
    className: string,
    component: string,
    backIconButton: shape(),
    nextIconButton: shape(),
    rowsPerPageOptions: arrayOf(oneOfType([object, number, string])),
    data: shape({
      page: number,
      limit: number,
    }),
  }),
  total: number,
  extraActions: node,
};

Table.defaultProps = {
  loading: false,
  extra: {},
  selectedError: {},
  list: [],
  tableHeader: [],
  logType: 'failure',
  onTableCellTitleClick: null,
  onTableRowClick: null,
  onPaginationChange: null,
  onErrorDetailsClose: null,
  pagination: {
    component: 'div',
    backIconButton: {
      'aria-label': 'previous page',
    },
    nextIconButton: {
      'aria-label': 'next page',
    },
    rowsPerPageOptions: null,
  },
  total: 0,
  extraActions: null,
};

export default Table;
