/* eslint-disable no-use-before-define */

import React, { useState, useEffect } from 'react';
import {
  arrayOf, shape, string, func, oneOfType, number,
} from 'prop-types';
import Select from 'react-select';

const SelectAutocomplete = (props) => {
  const [value, setValue] = useState([]);
  const staticLabelClass = props.staticLabel ? 'form-control-label-static flex items-center direction-row' : '';

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (selectedList) => {
    props.onChange(selectedList);
    setValue(selectedList);
  };

  return (
    <div className={`${props.className} ${staticLabelClass} select-size-${props.size} select-variant-${props.variant}`}>
      {props.staticLabel && <div className="label-static">{props.staticLabel}</div>}
      <Select
        onChange={handleChange}
        value={value}
        isMulti
        name={props.name}
        options={props.options}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
};

SelectAutocomplete.propTypes = {
  className: string,
  staticLabel: oneOfType([shape(), string]),
  name: string,
  size: string,
  variant: string,
  value: arrayOf(shape({
    value: oneOfType([number, string]),
    label: oneOfType([number, string]),
  })),
  options: arrayOf(shape({
    value: oneOfType([number, string]),
    label: oneOfType([number, string]),
  })),
  onChange: func,
};

SelectAutocomplete.defaultProps = {
  className: '',
  name: '',
  size: 'medium',
  variant: 'outlined',
  staticLabel: '',
  value: [],
  options: [],
  onChange: null,
};

export default SelectAutocomplete;
