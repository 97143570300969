import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
} from '../utils/paths';
import { aggregation } from '../utils/variables';
import handleError from '../utils/handleError';

const merchantActions = new RestActions('merchant');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
} = merchantActions;


export const fetchMerchantList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => (dispatch, getState) => {
  const { monitoring } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch(fetchAllStart());
  return axios.post(
    `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
    decamelizeKeys(body),
  ).then((res) => {
    const merchantList = camelizeKeys(
      res.data.aggregations[aggregation.merchantId].buckets,
    );
    const mappedList = merchantList.map((m) => ({
      ...m,
      id: m.key,
      value: m.key,
      label: m.label || m.key || `${merchantList.indexOf(m)}_${m.merchantId}`,
    }));
    dispatch(fetchAllFinished({ list: mappedList }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
    }));
  });
};

export const clearFromMerchantState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
