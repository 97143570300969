import axios from 'axios';

export default (setAPIError) => {
  const statuses = [400, 403, 500, 503];
  axios.interceptors.response.use((response) => response, (error) => {
    let errorBody = {};
    if (error.response) {
      errorBody = {
        hasError: true,
        name: error.name,
        title: error.response.statusText,
        statusCode: error.response.status,
        message: error.response.data.message || error.response.data.error.message,
        extraDetails: {
          message: error.message || '',
          configData: error.config.data || '',
          responseURL: error.request.responseURL || '',
          stack: error.stack,
        },
        error,
      };
    } else {
      errorBody = {
        hasError: true,
        title: error.message,
        statusCode: '503',
        message: 'Service Unavailable',
        error,
      };
    }

    if (statuses.some((status) => error.response && status === error.response.status)) {
      setAPIError({ errors: errorBody });
    }
    return Promise.reject(error);
  });
};
