import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';

import {
  ATTRIBUTE_SET_BEFORE_KEYS,
  ATTRIBUTE_DELETE_BEFORE_KEYS,
} from './types';

import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
  grtProductAttributesSearchPath,
} from '../utils/paths';
import { aggregation } from '../utils/variables';
import handleError from '../utils/handleError';

const logActions = new RestActions('attribute');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  updateAllStart,
  updateAllFinished,
  replaceAllFinished,
} = logActions;

const getCachedList = (list) => list.map((mB) => ({
  ...mB,
  options: list.some((item) => item.code === mB.code)
    ? list.find((item) => item.code === mB.code).options
    : [],
}));

export const fetchAttributeAggregationList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
  isExtraFetch = false,
}) => async (dispatch, getState) => {
  const { list, after: afterKey } = getState().attribute;
  const { monitoring, cdms } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch(fetchAllStart());

  try {
    const res = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );

    const clonedAgg = [...aggregations].shift();
    const aggregationsByFieldBuckets = !isEmpty(aggregations)
      ? res.data.aggregations[clonedAgg.bucket].buckets
      : [];

    const after = !isEmpty(aggregations)
      ? camelizeKeys(res.data.aggregations[clonedAgg.bucket][aggregation.afterKey])
      : {};

    const filters = !isEmpty(aggregationsByFieldBuckets) && isExtraFetch
      ? [{
        filter: [{
          group: [{
            field: 'code',
            value: aggregationsByFieldBuckets.map((aB) => aB.key[clonedAgg.bucket]),
            operator: 'in',
          }],
        }],
        fields: ['code', 'label'],
        pagination: { page: 1, limit: 100 },
      }]
      : [];

    const promises = await !isEmpty(filters)
      ? filters.map(async (f) => {
        const postRest = await axios.post(
          grtProductAttributesSearchPath(cdms.apiPath), f,
        );

        const camelizedRes = camelizeKeys(postRest.data.data);
        return camelizedRes;
      })
      : [];

    const result = await Promise.all(promises);
    const productAttribute = result.flat();

    const mappetBuckets = aggregationsByFieldBuckets.map((b) => ({
      code: b.key[clonedAgg.bucket],
      field: clonedAgg.bucket,
      count: b.doc_count,
      attribute: productAttribute.find((pA) => pA.code === b.key),
      options: [],
    }));

    const aggField = [...aggregations].shift().bucket;
    const attributeAggregationResult = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys({
        ...body,
        aggregations: [{
          operator: 'terms',
          field: aggField,
          pagination: {
            page: 1,
            limit: 1,
          },
        }],
      }),
    );
    const extraCallTotal = attributeAggregationResult.data.aggregations[aggField].total;

    const attributeList = !isEmpty(list) ? getCachedList(mappetBuckets) : mappetBuckets;
    const defaultTotal = attributeList.length < pagination.limit ? 1 : res.data.total;
    const total = extraCallTotal || defaultTotal;

    dispatch(fetchAllFinished({
      total,
      list: camelizeKeys(attributeList),
      after: isEmpty(after) ? afterKey : after || {},
    }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
      total: 0,
      after: {},
    }));
  }
};

export const updateAttributeList = (
  attributeOptionList = [],
  selectedAttribute = [],
) => (dispatch, getState) => {
  dispatch(updateAllStart());
  const { list } = getState().attribute;
  const notEmpty = !isEmpty(attributeOptionList) && !isEmpty(selectedAttribute);
  const updatedList = notEmpty && list.map((item) => {
    if (item.code === selectedAttribute.titleId) {
      return ({
        ...item,
        loading: false,
        options: attributeOptionList,
      });
    } return ({
      ...item,
      loading: false,
    });
  });
  dispatch(updateAllFinished({
    list: updatedList,
  }));
};


export const setAttributeBeforeKey = ({ data, directionKey }) => (dispatch, getState) => {
  const { beforeKeys } = getState().log;
  const keys = [...beforeKeys];
  if (directionKey === 'after') {
    keys.push(data);
  } else {
    keys.pop();
  }
  dispatch({
    type: ATTRIBUTE_SET_BEFORE_KEYS,
    payload: { beforeKeys: keys },
  });
};

export const deleteAttributeBeforeKeys = () => (dispatch) => {
  dispatch({
    type: ATTRIBUTE_DELETE_BEFORE_KEYS,
    payload: { beforeKeys: [] },
  });
};

export const replaceAttributeList = (newList) => (dispatch) => {
  dispatch(replaceAllFinished({
    list: newList,
  }));
};

export const clearFromAttributeState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
