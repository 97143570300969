/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import IntlMessages from '../../../../utils/IntlMessages';
import {
  catalogAppPath,
} from '../../../../utils/paths';

const useTableRows = (state, props) => {
  useEffect(() => {
    if (props.session.fetchedOne && props.authUser.success) {
      NotificationManager.success(<IntlMessages id="text.userAuthSuccess" />);
      props.history.push(catalogAppPath);
    }
  }, [props.session.fetchedOne, props.authUser.success]);
};

export default useTableRows;
