import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import IntlMessages from '../../utils/IntlMessages';

const Logo = (props) => (
  <div className="site-logo">
    <Link to={props.pathTo} className="logo-normal header-brand">
      <img src={props.src} alt="site logo" />
      <span className="rct-badge-logo">
        <IntlMessages id="text.monitoringTool" />
      </span>
    </Link>
  </div>
);

Logo.propTypes = {
  pathTo: string,
  src: string,
};

Logo.defaultProps = {
  pathTo: '',
  src: 'string',
};

export default Logo;
