export const tableHeader = [
  {
    value: 'message',
    label: 'Error description',
  }, {
    value: 'errorLevel',
    label: 'Error level',
  }, {
    value: 'operationId',
    label: 'Operation ID',
  }, {
    value: 'origin',
    label: 'Source',
  }, {
    value: 'messageId',
    label: 'Message ID',
  }, {
    value: 'submissionTimestamp',
    label: 'Last modified',
  },
];

export default {
  tableHeader,
};
