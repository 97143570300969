import React from 'react';
import {
  string, any, oneOfType, object,
} from 'prop-types';
import moment from 'moment';

const DateTimeLabel = (props) => {
  const { value, format } = props;
  return (
    <div className="date-time-wrapper">
      {moment(value).format(format)}
    </div>
  );
};

DateTimeLabel.propTypes = {
  value: oneOfType([object, string, any]),
  format: string,
};

DateTimeLabel.defaultProps = {
  format: 'MM/DD/YYYY',
  value: new Date(),
};

export default DateTimeLabel;
