import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

export const setRequestInterceptors = (configs) => {
  axios.interceptors.request.use(
    (axiosConfig) => {
      const pureConfig = { ...axiosConfig };
      const url = new URL(pureConfig.url);
      const pureApiUrl = pureConfig.url.split('?')[0];
      const params = queryString.parse(url.search);
      const serviceConfig = Object.values(configs).find((c) => url.href.includes(c.apiPath));

      if (!isEmpty(serviceConfig)) {
        return {
          ...axiosConfig,
          url: `${pureApiUrl}?${queryString.stringify(params)}`,
          headers: {
            common: {
              Authorization: `Bearer ${serviceConfig.token}`,
            },
          },
        };
      }
      return {
        ...axiosConfig,
      };
    },
  );
};

export default {
  setRequestInterceptors,
};
