import React from 'react';
import { isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

import Select from '../Select';
import Autocomplete from '../Autocomplete';
import Popover from '../Popover';
import SelectCategories from '../SelectCategories';
import ScopeDialog from '../ScopeDialog';
import DateRangePicker from '../DatePicker/DateRangePicker';
import IntlMessages from '../../utils/IntlMessages';
import { defaultProps, propTypes } from './propTypes';

const FilterNav = (props) => {
  const {
    content, transferSourceData, popoverSettings, sourceOptions,
    merchant, timeFrame, originSource, className, loading, useCategory,
    useMerchant, useSource, useTimeRange, useDateRangePicker, dateRangePicker,
    useScopeDialog, scopeDialog, refreshButton, prefix,
  } = props;

  const selectCategoriesValue = !isEmpty(transferSourceData.targetList)
    ? transferSourceData.targetList.map((t) => t.name).join(', ')
    : 'Select';

  return (
    <div className={`nav nav-box nav-inline ${className}`}>
      <div className="nav-group">
        <div className="nav-title">
          <IntlMessages id="text.filterBy" />
        </div>
      </div>
      {prefix}
      {useSource && (
        <div className="nav-group">
          <Select
            displayEmpty
            size="sm"
            staticLabel={<IntlMessages id="text.source" />}
            name="origin"
            value={originSource.value}
            options={sourceOptions}
            onChange={props.onOriginChange}
          />
        </div>
      )}
      {useScopeDialog && (
        <div className="nav-group">
          <div className="flex items-center text-nowrap">
            <IntlMessages id="productsHistory.header.filterNav.label.virtualCatrgory" />
            {scopeDialog.button}
          </div>
          <ScopeDialog
            dialog={{
              closeButtonTitle: scopeDialog.dialog.closeButtonTitle,
              submitButtonTitle: scopeDialog.dialog.submitButtonTitle,
            }}
            search={{
              noResult: scopeDialog.search.noResult,
              placeholder: scopeDialog.search.placeholder,
              list: scopeDialog.search.list,
              errorMessage: scopeDialog.search.errorMessage,
              queryLengthLimit: 170,
            }}
            showScopeDialog={scopeDialog.showScopeDialog}
            collapsed={scopeDialog.collapsed}
            loadedKeys={scopeDialog.loadedKeys}
            selectedTreeItems={scopeDialog.selectedTreeItems}
            treeItems={scopeDialog.treeItems}
            closeScopeDialog={scopeDialog.closeScopeDialog}
            onSubmit={scopeDialog.onSubmit}
            onToggleTree={scopeDialog.onToggleTree}
            fetchChildScopeCategories={scopeDialog.fetchChildScopeCategories}
            onSearchSubmit={scopeDialog.onSearchSubmit}
            onToggleScopeCategoryTreeCollapse={scopeDialog.onToggleScopeCategoryTreeCollapse}
            handleLoadSubcategories={scopeDialog.handleLoadSubcategories}
            loading={scopeDialog.loading}
            multiple={scopeDialog.multiple}
          />
        </div>
      )}
      {useCategory && (
        <div className="nav-group">
          <Popover
            footer
            className="lh-0 events-value"
            settings={popoverSettings}
            onOk={props.onSelectCategoriesOk}
            disabledOk={isEmpty(transferSourceData.targetList)}
            onCancel={props.onSelectCategoriesCancel}
            onClose={props.onSelectCategoriesClose}
            button={{
              className: 'btn-clear',
              title: (
                <div className="popover-title">
                  <IntlMessages id="text.category" />
                </div>
              ),
              value: content.valuePreloader
                ? <IntlMessages id="text.loading" />
                : <div className="title-trim title-trim-md">{selectCategoriesValue}</div>,
            }}
            content={(
              <SelectCategories
                className="p25 popover-lg"
                loading={content.loading}
                noResult={content.noResult}
                onQueryChange={content.onQueryChange}
                onSearchSubmit={content.onSearchSubmit}
                query={content.query}
                transferSourceData={{
                  tooltip: transferSourceData.tooltip,
                  sourceTitle: transferSourceData.sourceTitle,
                  targetTitle: transferSourceData.targetTitle,
                  sourceList: transferSourceData.sourceList,
                  targetList: transferSourceData.targetList,
                  onChange: transferSourceData.onChange,
                  selectAll: transferSourceData.selectAll,
                  unselectAll: transferSourceData.unselectAll,
                }}
              />
            )}
          />
        </div>
      )}
      {useTimeRange && (
        <div className="nav-group">
          <Select
            size="sm"
            value={timeFrame.value}
            staticLabel={<IntlMessages id="text.timeRange" />}
            name="timeFrame"
            options={timeFrame.options}
            onChange={props.onTimeFrameChange}
          />
        </div>
      )}
      {useMerchant && (
        <div className="nav-group">
          <Autocomplete
            name="merchantSelect"
            className="max-w-600"
            value={merchant.value}
            staticLabel={<IntlMessages id="text.merchant" />}
            options={merchant.options}
            onChange={props.onMerchantChange}
            optionLabel="label"
            size="small"
            variant="standard"
          />

        </div>
      )}
      {useDateRangePicker && (
        <DateRangePicker
          wrapperClassName={dateRangePicker.wrapperClassName}
          className={dateRangePicker.className}
          staticLabel={<IntlMessages id="text.timeRange" />}
          value={dateRangePicker.value}
          onChange={dateRangePicker.onChange}
          rangeLimit={dateRangePicker.rangeLimit}
          maxDate={dateRangePicker.maxDate}
          onClear={dateRangePicker.onClear}
        />
      )}
      <div className="nav-group justify-end-self btn-group">
        <Button
          onClick={props.refreshFilter || refreshButton.onClick}
          variant="outlined"
          color="primary"
          size="small"
          className="btn-sm"
          disabled={refreshButton.disabled}
        >
          <RefreshRoundedIcon className={loading ? 'animation-circle' : ''} fontSize="small" />
          <IntlMessages id="text.refreshFilter" />
        </Button>
        {props.clearFilter && (
          <Button
            onClick={props.clearFilter}
            variant="outlined"
            color="secondary"
            size="small"
            className="btn-sm"

          >
            <IntlMessages id="fiterNav.button.label.clearFilter" />
          </Button>
        )}
      </div>
    </div>
  );
};

FilterNav.propTypes = propTypes;
FilterNav.defaultProps = defaultProps;

export default FilterNav;
