import React from 'react';

const TableCard = () => (
  <div className="skeleton">
    <div className="card">
      <div className="card-title">
        <div className="line xs" />
      </div>
      <div className="flex card-body">
        {Array.from({ length: 3 }, (v, i) => (
          <div className="body-block" key={i}>
            <div className="line xs" />
            <div className="block xs" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default TableCard;
