import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

const initialState = {};
const physicalCategoryRestTypes = restTypes('navigation');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case physicalCategoryRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
        ...action.payload,
      };
    case physicalCategoryRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case physicalCategoryRestTypes.clearStateFinished:
      return {
        ...initialState,
        ...restStates.clearStateFinished,
      };
    default: return state;
  }
};
