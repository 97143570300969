import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import Moment from 'moment';

import { extendMoment } from 'moment-range';

import { Button, Badge } from '@material-ui/core';

import Layout from '../../../../components/Layout';
import List from '../../../../components/List';
import FilterNav from '../../../../components/FilterNav';
import Select from '../../../../components/Select';
import SelectNavigationDialog from '../../../../components/SelectNavigationDialog';
import { headerList } from '../../../utils/headerList';

import { getTableHeader } from '../../utils/tableSettings';
import getDateRangeFilter from '../../utils/getDateRangeFilter';

import useStateStore from './hooks/useState';
import useProductsHistory from './hooks/useProductsHistory';
import useTableBody from './hooks/useTableBody';
import useProductsHistoryTableTable from './hooks/useProductsHistoryTableTable';
// import useNavigation from './hooks/useNavigation';

import { mapStateToProps, mapDispatchToProps } from './connect';
import Body from './conainers';
import { propTypes, defaultProps } from './propTypes';
import { defaultPagination, deltaHeaderCell, logTypeOptions } from '../../utils/defaultData';
import IntlMessages from '../../../../utils/IntlMessages';
import { mappingFields } from '../../../../utils/variables';
import { getFullTextSearch } from '../../utils/getSearchScopeCategoryFilter';

const moment = extendMoment(Moment);

const getIds = (array) => array.map((a) => {
  if (typeof a !== 'string') return a.id;
  return a;
});

const ProductsHistory = (props) => {
  const state = useStateStore(props);

  useProductsHistory(state, props);
  useTableBody(state, props);
  useProductsHistoryTableTable(state, props);
  // useNavigation(state, props);

  const { productsHistory, scopeCategory, navigation } = props;

  const resetPagination = (limit) => {
    const data = {
      page: 1,
      limit: limit || state.pagination.limit,
    };
    state.setPagination(data);
    state.setPaginationDirectionKey('');
    props.deleteProductsHistoryBeforeKeys();
  };

  const handleRefreshFilter = () => {
    state.setRefreshFilter(!state.refreshFilter);
    const data = !isEmpty(state.pagination)
      ? state.pagination
      : defaultPagination;
    state.setPagination(data);
  };

  const handlePaginationChange = (data, paginationDirectionKey) => {
    state.setPagination(data);
    props.clearFromProductsHistoryState({
      fetched: false,
      fetching: false,
    });
    if (data.page === 1) {
      resetPagination(data.limit);
    } else {
      state.setPaginationDirectionKey(paginationDirectionKey);
    }
  };

  const handleTableRowClick = (row) => {
    state.setSelectedRow(row);
  };

  const handleTableHeaderClick = (cell) => {
    props.clearFromProductsHistoryState({
      fetched: false,
      fetching: false,
    });
    props.setProductsHistoryTableSelectedColumn(cell);
    const compositeRangeValue = {
      from: moment(cell.value).startOf('day').format(),
      to: moment(cell.value).endOf('day').format(),
    };
    const compositeTimeRangeFilter = getDateRangeFilter(compositeRangeValue);
    state.setCompositeDateRangeFilter(compositeTimeRangeFilter);
    resetPagination();
  };

  const handleGetDeltaClick = () => {
    props.getProductsHistoryTableDelta('test');
  };

  const handleDateRangePickerChange = (selectedRange) => {
    props.clearFromProductsHistoryState({
      total: 0,
      aggregateList: [],
      list: [],
      after: [],
      uniqIdsList: [],
      fetched: false,
      fetching: false,
      exludedDates: [],
      fetchAggregatedProductsHistoryFinished: false,
      fetchAggregatedProductsHistoryStart: false,
      clearingFromStateFinished: false,
      clearingStateFinished: false,
    });
    const { productsHistoryTable: { column } } = props;
    if (column.label) {
      props.clearProductsHistoryTableSelectedState();
    }
    const startDay = selectedRange[0];
    const endDay = selectedRange[1];

    const rangeValue = {
      from: startDay,
      to: endDay,
    };

    const compositeRangeValue = {
      from: startDay,
      to: moment(startDay).endOf('day').format(),
    };

    const start = moment(rangeValue.from);
    const end = moment(rangeValue.to);

    const range = moment.range(start, end);

    const acc = Array.from(range.by('day', { step: 1 }));

    const selectedDays = acc.map((m) => ({
      origDate: m.format(),
      value: m.format().substr(0, m.format().indexOf('T')),
      label: m.format('ll'),
    }));

    const dateRangeAggregation = acc.map((day) => ({
      from: moment(day).startOf('day').format(),
      to: moment(day).endOf('day').format(),
    }));

    const timeRangeFilter = getDateRangeFilter(rangeValue);
    const compositeTimeRangeFilter = getDateRangeFilter(compositeRangeValue);
    state.setDateRangeValue(selectedRange);
    state.setTableDateRange(selectedDays);
    state.setDateRangeFilter(timeRangeFilter);
    state.setCompositeDateRangeFilter(compositeTimeRangeFilter);
    state.setDateRangeAggregation(dateRangeAggregation);
    resetPagination();
  };

  const openSelectCategoriesButton = () => {
    state.setShowCategoriesDialog(true);
    props.fetchRootScopeCategories();
  };

  const closeSelectCategoriesButton = () => {
    state.setShowCategoriesDialog(false);
    props.clearSearchListState();
    props.clearFromScopeCategoryState({
      loadedKeys: [],
      collapse: [],
    });
  };

  const handleToggleTree = (isSubmited) => {
    props.clearSearchListState();
    if (isSubmited) {
      props.fetchRootScopeCategories();
    }
  };

  const handleDateRangeClear = () => {
    state.setTableBody([]);
    state.setDateRangeValue(null);
    state.setTableDateRange([]);
    state.setDateRangeFilter([]);
    state.setDateRangeAggregation([]);
    state.setFilter([]);
    props.clearFromProductsHistoryState({
      total: 0,
      aggregateList: [],
      list: [],
      after: [],
      uniqIdsList: [],
      fetched: false,
      fetching: false,
      exludedDates: [],
    });
    resetPagination();
  };

  const submitCheckedCategories = (selected) => {
    props.clearFromProductsHistoryState({
      fetched: false,
      fetching: false,
      list: [],
      exludedDates: [],
    });
    const mappedList = getIds(selected);

    state.setSelectedTreeItems(mappedList);

    if (!isEmpty(mappedList)) {
      const ids = uniq(mappedList);
      props.fetchScopeCategoriesByIds(ids);
      const productsHistoryFilter = [{
        field: mappingFields.entityId,
        value: ids,
        operator: 'in',
      }];
      state.setProductsHistoryFilter(productsHistoryFilter);
    }
    if (isEmpty(mappedList)) {
      state.setProductsHistoryFilter([]);
    }
    closeSelectCategoriesButton();
  };

  const handleSearchScopeCategory = (query) => {
    props.searchScopeCategories(getFullTextSearch(query));
  };

  const handleLogTypeChange = (data) => {
    state.setLogType(data.value);
    state.setSelectedTreeItems([]);
    state.setProductsHistoryFilter([]);
    props.clearFromProductsHistoryState({
      fetched: false,
      fetching: false,
      list: [],
      exludedDates: [],
    });
  };

  const handleSelectNavigationOkClick = (selectedIds) => {
    props.clearFromProductsHistoryState({
      fetched: false,
      fetching: false,
      list: [],
      exludedDates: [],
    });

    state.setSelectedTreeItems(selectedIds);

    if (!isEmpty(selectedIds)) {
      const ids = uniq(selectedIds);
      props.fetchScopeCategoriesByIds(ids);
      const productsHistoryFilter = [{
        field: mappingFields.entityId,
        value: ids,
        operator: 'in',
      }];
      state.setProductsHistoryFilter(productsHistoryFilter);
    }
    if (isEmpty(selectedIds)) {
      state.setProductsHistoryFilter([]);
    }
    closeSelectCategoriesButton();
  };

  const handleSelectNavigationCancelClick = () => {
    if (!isEmpty(navigation.list)) {
      const data = {
        pagination: defaultPagination,
      };
      props.fetchNavigationList(data);
    }
  };

  const handleSelectNavigationOpenClick = () => {
    if (!navigation.fetched) {
      const data = {
        pagination: defaultPagination,
      };
      props.fetchNavigationList(data);
    }
  };

  const handleNavigationSearchClear = (isSubmited) => {
    if (navigation.fetched && isSubmited) {
      const data = {
        pagination: defaultPagination,
      };
      props.fetchNavigationList(data);
      state.setProductsHistoryFilter([]);
    }
  };

  const handleNavigationDialogPagiationChange = (page) => {
    const data = {
      pagination: {
        page,
        limit: 100,
      },
    };
    props.fetchNavigationList(data);
  };

  const handleNavigationSearchSubmit = (query) => {
    const data = {
      fullText: {
        fields: ['name'],
        value: query,
      },
      pagination: defaultPagination,
    };
    props.fetchNavigationList(data);
  };

  const filterFetching = productsHistory.fetching
    || productsHistory.fetchAggregatedProductsHistoryStart
    || productsHistory.recursiveFetching;

  const diabledRefreshButton = isEmpty(state.tableDateRange)
    || (isEmpty(state.productsHistoryFilter) && isEmpty(state.tableDateRange));

  const useSelectNavigation = state.logType === 'navigation';

  return (
    <Layout
      horizontalMenu
      header={{
        className: 'header-fixed',
        subHeaderContent: (
          <List
            dir="horizontal"
            className="justify-center list-lg"
            dataSource={headerList}
          />
        ),
        content: (
          <FilterNav
            useDateRangePicker
            prefix={(
              <>
                <Select
                  className="extra-actions-main-wrapper"
                  size="xs"
                  value={state.logType}
                  staticLabel={<IntlMessages id="productsHistory.header.filterNav.label.type" />}
                  name="logType"
                  options={logTypeOptions}
                  onChange={handleLogTypeChange}
                />
                {useSelectNavigation && (
                  <SelectNavigationDialog
                    className="flex items-baseline nav-group"
                    noResult={isEmpty(navigation.list) && navigation.fetched}
                    onOpen={handleSelectNavigationOpenClick}
                    onOk={handleSelectNavigationOkClick}
                    onCancel={handleSelectNavigationCancelClick}
                    list={navigation.list}
                    search={{
                      onSubmit: handleNavigationSearchSubmit,
                      onClear: handleNavigationSearchClear,
                    }}
                    pagination={{
                      pages: navigation.pages,
                      total: navigation.total,
                      onChange: handleNavigationDialogPagiationChange,
                    }}
                    loading={navigation.fetching}
                  />
                )}
              </>
            )}
            refreshButton={{
              disabled: diabledRefreshButton,
              onClick: handleRefreshFilter,
            }}
            className={filterFetching ? 'disabled' : ''}
            loading={filterFetching}
            dateRangePicker={{
              wrapperClassName: 'flex items-baseline',
              className: 'date-picker-secondary',
              value: state.dateRangeValue,
              onChange: handleDateRangePickerChange,
              onClear: handleDateRangeClear,
              rangeLimit: 5,
              maxDate: new Date(),
              locale: 'he-IL',
            }}
            useScopeDialog={!useSelectNavigation}
            scopeDialog={{
              button: (
                <Badge badgeContent={state.selectedTreeItems.length} color="primary">
                  <Button
                    className="btn-xs text-nowrap block ml10"
                    variant="outlined"
                    color="primary"
                    disabled={filterFetching}
                    onClick={openSelectCategoriesButton}
                  >
                    <IntlMessages id="productsHistory.button.selectCategoriesLabel" />
                  </Button>
                </Badge>
              ),
              dialog: {
                closeButtonTitle: <IntlMessages id="productsHistory.scopeDialog.button.cancel" />,
                submitButtonTitle: <IntlMessages id="productsHistory.scopeDialog.button.select" />,
              },
              search: {
                noResult: scopeCategory.searched && isEmpty(scopeCategory.searchResults),
                placeholder: 'Search virtual categories...',
                list: scopeCategory.searchResults,
                errorMessage: 'productsHistory.scopeDialog.errors.lessThan170Characters',
                queryLengthLimit: 170,
              },
              showScopeDialog: state.showCategoriesDialog,
              collapsed: scopeCategory.collapse,
              loadedKeys: scopeCategory.loadedKeys,
              selectedTreeItems: state.selectedTreeItems,
              treeItems: scopeCategory.treeItems,
              closeScopeDialog: closeSelectCategoriesButton,
              onSubmit: submitCheckedCategories,
              onToggleTree: handleToggleTree,
              fetchChildScopeCategories: props.fetchChildScopeCategories,
              onSearchSubmit: handleSearchScopeCategory,
              onToggleScopeCategoryTreeCollapse: props.onToggleScopeCategoryTreeCollapse,
              handleLoadSubcategories: props.handleLoadScopeSubcategories,
              loading: filterFetching || scopeCategory.fetchRootStarted,
              multiple: true,
            }}
          />
        ),
      }}
      main={{
        fluid: true,
        className: 'container-home',
        content: <Body
          loading={{
            table: filterFetching,
          }}
          list={state.tableBody}
          logType={logTypeOptions.find((lgo) => lgo.value === state.logType).label}
          listTotal={productsHistory.total}
          onTableRowClick={handleTableRowClick}
          header={{
            list: getTableHeader(
              state.tableDateRange.length > 1
                ? [...state.tableDateRange, deltaHeaderCell]
                : state.tableDateRange,
            ),
            selectedColumn: props.productsHistoryTable.column,
            onClick: handleTableHeaderClick,
            onGetDeltaClick: handleGetDeltaClick,
          }}
          onPaginationChange={handlePaginationChange}
          pagination={state.pagination}
          tablePlaceholder={state.tablePlaceholderMessage}
        />,
      }}
    />
  );
};

ProductsHistory.propTypes = propTypes;
ProductsHistory.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsHistory));
