import axios from 'axios';
import { isEmpty, uniq, orderBy } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import moment from 'moment';

import {
  PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START,
  PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED,
  PRODUCTS_HISTORY_SET_BEFORE_KEYS,
  PRODUCTS_HISTORY_DELETE_BEFORE_KEYS,
  PRODUCTS_HISTORY_SET_TO_STATE_FINISHED,
} from './types';

import RestActions from '../utils/rest/actions';
import {
  getProductsHistorySearchPath,
  getCategoriesSearchPath,
  getNagigationsSearchPath,
} from '../utils/paths';
import { aggregation } from '../utils/variables';
import handleError from '../utils/handleError';


const logActions = new RestActions('products_history');
const {
  fetchFinished,
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  clearStateFinished,
} = logActions;

export const fetchAggregatedProductsHistoryList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
  logType = '',
}) => async (dispatch, getState) => {
  const { column } = getState().productsHistoryTable;
  const { monitoring, cdms } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch({
    type: PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_START,
    payload: {
      fetchAggregatedProductsHistoryStart: true,
      fetchAggregatedProductsHistoryFinished: false,
    },
  });

  try {
    const result = await axios.post(
      `${getProductsHistorySearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );

    const resAggregations = result.data.aggregations;
    const groupedAggregations = resAggregations[aggregation.timestamp].buckets;
    const mappedList = groupedAggregations.map((ga) => ({
      ...ga,
      virtualCategories: ga[aggregation.entityId].buckets.map((vc) => ({
        id: vc.key,
        countProducts: vc[aggregation.countProducts].value === 0
          ? vc[aggregation.countProducts].value
          : vc[aggregation.countProducts].value || '-',
      })),
    }));

    const uniqIdList = uniq(mappedList.map(
      (ml) => ml.virtualCategories.map((vc) => vc.id),
    ).flat());

    const filters = [{
      pagination: {
        page: 1,
        limit: uniqIdList.length,
      },
      filter: [{
        condition: 'and',
        group: [{
          field: 'id',
          value: uniqIdList,
          operator: 'in',
        }],
      }],
    }];

    const promises = await !isEmpty(filters) && filters.map(async (sVCFilter) => {
      let postRest = [];
      switch (logType.value) {
        case 'virtualCategory':
          postRest = await axios.post(getCategoriesSearchPath(cdms.apiPath), sVCFilter);
          break;

        case 'navigation':
          postRest = await axios.post(getNagigationsSearchPath(cdms.apiPath), sVCFilter);
          break;

        default:
          postRest = [];
          break;
      }

      const camelizedRes = camelizeKeys(postRest.data.data);
      return camelizedRes;
    });

    const res = await Promise.all(promises);
    const flattedResult = res.flat();

    const mergedList = uniqIdList.map((id) => {
      const currentItem = flattedResult.find((vcr) => vcr.id === id);
      return ({
        ...camelizeKeys(currentItem),
        id,
        logType,
        row: mappedList.map((ml) => {
          const momentDate = moment(ml[aggregation.toAsString]).format();
          return ({
            date: momentDate,
            value: momentDate.substr(0, momentDate.indexOf('T')),
            label: moment(ml[aggregation.toAsString]).format('ll'),
            virtualCategory: ml.virtualCategories.find((mlVC) => mlVC.id === id),
            cellType: 'date',
          });
        }),
      });
    });

    const orderedMergedList = orderBy(
      mergedList,
      (e) => {
        if (!isEmpty(e.row[column.index - 1].virtualCategory)) {
          return e.row[column.index - 1].virtualCategory.countProducts;
        } return e.row[column.index - 1];
      },
      [column.order],
    );

    const aggregateListNotFormatted = isEmpty(column.value) ? mergedList : orderedMergedList;
    dispatch({
      type: PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED,
      payload: {
        aggregateList: camelizeKeys(aggregateListNotFormatted),
        aggregateTotal: resAggregations[aggregation.timestamp].total,
        fetchAggregatedProductsHistoryStart: false,
        fetchAggregatedProductsHistoryFinished: true,
        recursiveFetching: false,
      },
    });
  } catch (error) {
    const errorRes = handleError(error);
    dispatch({
      type: PRODUCTS_HISTORY_FETCH_AGGREGATED_LIST_FINISHED,
      payload: {
        error: errorRes,
        hasErrors: true,
        aggregateList: [],
        fetchAggregatedProductsHistoryStart: false,
        fetchAggregatedProductsHistoryFinished: false,
        recursiveFetching: false,
      },
    });
  }
};

export const fetchProductsHistoryList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => (dispatch, getState) => {
  const { exludedDates } = getState().productsHistory;
  const { monitoring } = getState().settings.serviceConfigs;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch(fetchAllStart());
  return axios.post(
    `${getProductsHistorySearchPath(monitoring.apiPath)}${mappedSearchParams}`,
    decamelizeKeys(body),
  ).then((res) => {
    const resData = res.data.data;
    const resAggreagation = res.data.aggregations;
    const resAggreagationBuckets = resAggreagation[aggregation.categoryCount].buckets;
    const uniqIdsList = !isEmpty(resAggreagationBuckets)
      ? resAggreagationBuckets.map((rA) => rA.key[aggregation.entityId])
      : [];
    const after = camelizeKeys(resAggreagation[aggregation.categoryCount][aggregation.afterKey]);
    if (isEmpty(uniqIdsList) && !isEmpty(filter)) {
      const newExludedDate = filter.map(
        (f) => f.group.map((g) => (g.field === '@timestamp' ? g.value.from.value : null)),
      ).flat().filter((e) => e).shift();
      exludedDates.push(newExludedDate);
    }

    dispatch(fetchAllFinished({
      list: camelizeKeys(resData),
      uniqIdsList,
      total: res.data.total,
      after,
      exludedDates: uniq(exludedDates),
      aggregateList: [],
      fetchAggregatedProductsHistoryStart: false,
      fetchAggregatedProductsHistoryFinished: false,
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
      aggregateList: [],
      uniqIdsList: [],
      fetchAggregatedProductsHistoryStart: false,
      fetchAggregatedProductsHistoryFinished: false,
    }));
  });
};

export const setProductsHistoryBeforeKey = ({ data, directionKey }) => (dispatch, getState) => {
  const { beforeKeys } = getState().productsHistory;
  const keys = [...beforeKeys];
  if (directionKey === 'after') {
    keys.push(data);
  } else {
    keys.pop();
  }
  dispatch({
    type: PRODUCTS_HISTORY_SET_BEFORE_KEYS,
    payload: { beforeKeys: keys },
  });
};

export const deleteProductsHistoryBeforeKeys = () => (dispatch) => {
  dispatch({
    type: PRODUCTS_HISTORY_DELETE_BEFORE_KEYS,
    payload: { beforeKeys: [] },
  });
};

export const setProductsHistoryItem = (item) => (dispatch) => {
  dispatch(fetchFinished({ item }));
};

export const clearProductsHistoryItem = () => (dispatch) => {
  dispatch(fetchFinished({ item: {} }));
};

export const setProductsHistoryList = (list) => (dispatch) => {
  dispatch(fetchAllFinished({ list }));
};

export const clearFromProductsHistoryState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const setToProductsHistoryState = (payload) => (dispatch) => {
  dispatch({
    type: PRODUCTS_HISTORY_SET_TO_STATE_FINISHED,
    payload,
  });
};

export const clearProductsHistoryState = () => (dispatch) => {
  dispatch(clearStateFinished());
};
