
import React from 'react';
import { Provider } from 'react-redux';
import { shape } from 'prop-types';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';

import Router from './Router';
import Auth from './Auth';
import ErrorBoundary from './ErrorBoundary';
import { PermissionsProvider } from './PermissionsContext';
import { AbilityContext } from './AbilityContext';
import ability from '../utils/ability';

import '../firebase';

const App = ({ store }) => (
  <Provider store={store}>
    <PermissionsProvider>
      <AbilityContext.Provider value={ability}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Auth>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </Auth>
        </MuiPickersUtilsProvider>
      </AbilityContext.Provider>
    </PermissionsProvider>
  </Provider>
);

App.propTypes = {
  store: shape({}).isRequired,
};

export default App;
