import axios from 'axios';
import humps from 'humps';

import RestActions from '../utils/rest/actions';

import {
  getNavigationsWithLocaleSearchPath,
} from '../utils/paths';
import { mapObjectToArray } from '../utils/objectsToArray';

import handleError from '../utils/handleError';

const logActions = new RestActions('navigation');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  clearStateFinished,
} = logActions;

export const fetchNavigationList = (body) => async (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch(fetchAllStart());
  const res = await axios.post(
    getNavigationsWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(body),
  );
  try {
    dispatch(fetchAllFinished({
      list: humps.camelizeKeys(mapObjectToArray(res.data.data)),
      pages: res.data.pages,
      total: res.data.total,
      hasError: false,
    }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      list: [],
      error: errorRes,
      hasError: true,
    }));
  }
};

export const clearFromNavigationState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const clearPNavigationState = () => (dispatch) => {
  dispatch(clearStateFinished());
};
