import React from 'react';
import {
  node, string, oneOfType, bool, func,
} from 'prop-types';
import IntlMessages from '../../utils/IntlMessages';

const Title = (props) => {
  const {
    title, children, className, withIntl, titleSecondary,
    secondary, prefix, suffix, size, innerClassName, contentClassName,
    secondaryTitleClassName,
  } = props;

  const isSecondary = secondary ? 'secondary' : 'default';
  const secondarySingleClass = !title ? 'rct-label-secondary-single' : '';

  const label = typeof title === 'string' && withIntl
    ? <IntlMessages id={title} />
    : title;

  return (
    <div className={`rct-title-wrapper ${className} title-${isSecondary} rct-title-${size}`}>
      {(title || titleSecondary) && (
        <div className="rct-label">
          <div
            className={`rct-label-inner ${innerClassName}`}
            role="presentation"
            onClick={props.onClick}
          >
            {prefix && <div className="rct-title-prefix">{prefix}</div>}
            {label}
            {titleSecondary && <div className={`rct-label-secondary ${secondarySingleClass} ${secondaryTitleClassName}`}>{titleSecondary}</div>}
            {suffix && <div className="rct-title-suffix">{suffix}</div>}
          </div>
        </div>
      )}
      {children && <div className={`rct-title-content ${contentClassName}`}>{children}</div>}
    </div>
  );
};

Title.propTypes = {
  className: string,
  innerClassName: string,
  secondaryTitleClassName: string,
  size: string,
  contentClassName: string,
  title: oneOfType([node, string]),
  titleSecondary: oneOfType([node, string]),
  children: oneOfType([node, string]),
  prefix: oneOfType([node, string]),
  suffix: oneOfType([node, string]),
  withIntl: bool,
  secondary: bool,
  onClick: func,
};

Title.defaultProps = {
  className: '',
  innerClassName: '',
  secondaryTitleClassName: '',
  size: 'default',
  contentClassName: 'default',
  title: null,
  titleSecondary: null,
  children: null,
  onClick: null,
  prefix: null,
  suffix: null,
  withIntl: false,
  secondary: false,
};

export default Title;
