import React, { useState, useEffect } from 'react';
import {
  func, oneOfType, string, object, number, any, array,
} from 'prop-types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { DateRangeOutlined, CloseRounded } from '@material-ui/icons';
import IntlMessages from '../../utils/IntlMessages';

const DatePicker = (props) => {
  const [value, setValue] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const clearValue = () => {
    props.onClear();
    setValue(null);
  };

  const handleChange = (range) => {
    if (range) {
      const startDay = moment(range[0]);
      const endDay = moment(range[1]);
      const limitLenth = Math.abs(startDay.diff(endDay, 'days')) + 1;
      if (limitLenth > props.rangeLimit) {
        NotificationManager.error((
          <div>
            <IntlMessages id="productsHistory.notification.error.timeRange" />
            {` ${props.rangeLimit}`}
          </div>
        ));
        if (!hasError) setHasError(true);
        clearValue();
        return false;
      }
      if (hasError) setHasError(false);
      setValue(range);
      props.onChange(range);
    }
    return false;
  };

  const errorClassName = hasError ? 'has-error' : '';

  return (
    <div className={`date-picker-wrapper ${props.wrapperClassName} ${errorClassName}`}>
      {props.staticLabel && <div className="label-static">{props.staticLabel}</div>}
      <DateRangePicker
        name="dateRangePicker"
        className={props.className}
        onChange={handleChange}
        value={value}
        maxDate={props.maxDate}
        clearIcon={(
          <CloseRounded onClick={clearValue} fontSize="small" />
        )}
        calendarIcon={(
          <DateRangeOutlined fontSize="small" />
        )}
      />
    </div>
  );
};

DatePicker.propTypes = {
  wrapperClassName: string,
  className: string,
  onChange: func,
  value: oneOfType([object, string, any]),
  staticLabel: oneOfType([object, string]),
  rangeLimit: number,
  maxDate: oneOfType([object, string, array, any]),
  onClear: func,
};

DatePicker.defaultProps = {
  wrapperClassName: '',
  className: '',
  onChange: null,
  value: null,
  staticLabel: null,
  rangeLimit: null,
  onClear: null,
  maxDate: null,
};

export default DatePicker;
