
import moment from 'moment';

export default ({ from, to }) => ([{
  field: '@timestamp',
  value: {
    from: {
      operator: 'gte',
      value: moment(from).format(),
    },
    to: {
      operator: 'lte',
      value: moment(to).format(),
    },
  },
  operator: 'range',
}]);
