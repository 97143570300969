import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  string, func, shape,
} from 'prop-types';

import { CloseRounded, FiberManualRecord } from '@material-ui/icons';
import {
  Divider, Link, IconButton,
} from '@material-ui/core';

import IntlMessages from '../../../utils/IntlMessages';
import Title from '../../../components/Title';
import Group from '../../../components/Group';
import getIconByLevel from '../../../utils/getIconByLevel';

const ErrorDetails = (props) => {
  const {
    className, selected,
  } = props;

  const isSelected = !isEmpty(selected);

  const drawerIn = isSelected ? 'drawer-in' : 'drawer-out';


  const getClientIp = () => (
    !isEmpty(selected.clientip) && typeof selected.clientip !== 'string' && Array.isArray(selected.clientip)
      ? selected.clientip.join(', ')
      : selected.clientip || '-'
  );

  const getTrace = (trace) => (
    !isEmpty(trace) && typeof trace === 'string'
      ? trace.split('#').map((t, index) => index > 0 && (
        <div className="dtr" key={t}>
          <div className="rct-group-value dtc pt5 pb5">{`#${t}` || '-'}</div>
        </div>
      ))
      : trace || '-'
  );

  const showExtraLevel = selected && selected.level && selected.level.toLowerCase() === 'critical';

  return (
    <div className={`drawer drawer-shadow ErrorDetails-details-wrapper ${className} ${drawerIn}`}>
      <Title
        size="md"
        suffix={(
          <div className="actions-wrapper flex items-baseline events-all">
            <IconButton aria-label="close" color="secondary" onClick={props.onClose}>
              <CloseRounded
                fontSize="small"
              />
            </IconButton>
          </div>
        )}
        innerClassName="items-baseline justify-between events-no block"
        title={<IntlMessages id="text.errorDesctirption" />}
        titleSecondary={isSelected ? selected.message.moreInfo || selected.message.messageId : '-'}
        secondaryTitleClassName="mr-auto"
      />

      {isSelected && (
        <div className="group-column scroll-y scroll-white">
          <Group secondary>
            <div className="text-muted-sec">
              {moment(selected['@timestamp']).format('MM/DD/YY LT')}
            </div>
          </Group>
          {showExtraLevel && (
            <Group
              secondary
              contentClassName="flex items-end text-danger"
            >
              {getIconByLevel(selected.level)}
              <span className="group-text ml5 lh-sm">{selected.level || '-'}</span>
            </Group>
          )}

          <Group secondary withIntl title="text.id">{selected.id || '-'}</Group>
          <Group secondary withIntl title="text.origin">{selected.origin || '-'}</Group>
          <Group secondary withIntl title="text.operationId">{selected.operationId || '-'}</Group>
          <Group secondary withIntl title="text.sqsId" contentClassName="text-up">{selected.sqsId || '-'}</Group>

          {
            !isEmpty(selected.properties)
              ? (
                <Group secondary withIntl wrapper title="text.properties">
                  <Group secondary withIntl title="text.logType">
                    {
                      !isEmpty(selected.properties.fields)
                        ? selected.properties.fields.logType
                        : selected.logType || '-'
                    }
                  </Group>
                  <Group secondary withIntl title="text.messageId" contentClassName="flex items-center">
                    {selected.message.messageId || (selected.messageId || '-')}
                    {selected.message.id && <small className="text-muted-sec ml5">{`(${selected.message.id || '-'})`}</small>}
                    {selected.message.color ? <FiberManualRecord style={{ color: selected.message.color }} /> : ''}
                  </Group>
                  <Group secondary withIntl title="text.responseCode">{selected.responseCode || '-'}</Group>
                  <Group
                    secondary
                    withIntl
                    contentClassName="dt th"
                    title="text.trace"
                  >
                    {
                      !isEmpty(selected.properties.fields)
                        ? getTrace(selected.properties.fields.trace)
                        : getTrace(selected.trace)
                    }
                  </Group>
                </Group>
              )
              : (
                <Group secondary withIntl wrapper title="text.properties">
                  <Group secondary withIntl title="text.logType">{selected.logType || '-'}</Group>
                  <Group secondary withIntl title="text.messageId" contentClassName="flex items-center">
                    {selected.message.messageId || (selected.messageId || '-')}
                    {selected.message.id && <small className="text-muted-sec ml5">{`(${selected.message.id || '-'})`}</small>}
                    {selected.message.color ? <FiberManualRecord style={{ color: selected.message.color }} /> : ''}
                  </Group>
                  <Group secondary withIntl title="text.responseCode">{selected.responseCode || '-'}</Group>
                  <Group
                    secondary
                    withIntl
                    contentClassName="dt th"
                    title="text.trace"
                  >
                    {getTrace(selected.trace)}
                  </Group>
                </Group>
              )
          }

          <Group>
            <Divider className="mtb-lg dotted" />
          </Group>

          <Group secondary withIntl title="text.ip">{selected.ip || '-'}</Group>
          <Group secondary withIntl title="text.clientIp">{getClientIp()}</Group>
          <Group secondary withIntl><IntlMessages id="text.moreInfo" /></Group>
          <Group secondary withIntl title="text.thread">{selected.thread || '-'}</Group>
          <Group secondary withIntl title="text.level">{selected.level || '-'}</Group>
          <Group secondary withIntl title="text.origin">{selected.origin || '-'}</Group>
          <Group secondary withIntl title="text.reasonCode">{selected.reasonCode || '-'}</Group>
          <Group secondary withIntl title="text.host">{selected.host || '-'}</Group>
          <Group secondary withIntl title="text.url">
            {(
              <Link
                target="_blank"
                rel="noreferrer"
                href={selected.url || '#'}
              >
                {selected.url || '-'}
              </Link>
            )}
          </Group>
          <Group secondary withIntl title="text.operationName">{selected.operationName || '-'}</Group>
          <Group secondary withIntl title="text.environment">{selected.environment || '-'}</Group>
          <Group secondary withIntl title="text.agent">{selected.agent || '-'}</Group>
          <Group secondary withIntl title="text.response">{selected.response || '-'}</Group>
        </div>
      )}
    </div>
  );
};

ErrorDetails.propTypes = {
  className: string,
  onClose: func,
  selected: shape({
    origin: string,
  }),
};

ErrorDetails.defaultProps = {
  className: '',
  onClose: null,
  selected: {},
};

export default ErrorDetails;
