import { useState } from 'react';
import { isEmpty } from 'lodash';
import { defaultTablePagination } from '../../../utils/defaultData';
import getCatalogDetailsFilter from '../../../utils/getCatalogDetailsFilter';

export const useStateHook = (props) => {
  const catalogValues = sessionStorage.getItem('catalogValues');
  const values = !isEmpty(catalogValues) ? JSON.parse(catalogValues) : null;

  const [pagination, setPagination] = useState(defaultTablePagination);
  const [generalFilter, setGeneralFilter] = useState(getCatalogDetailsFilter(values));
  const [selectedChart, setSelectedChart] = useState(
    values && !isEmpty(values.messages)
      ? { id: values.messages[0].value }
      : {},
  );
  const [logTitle, setLogTitle] = useState('');
  const [logPathByName, setLogPathByName] = useState('');
  const [tableRows, setTableRows] = useState(props.detailsLog.list);
  const [messageFilter, setMessageFilter] = useState(values ? values.messages : []);
  const [selectedLog, setSelectedLog] = useState({});
  const [selectedLogIds, setSelectedLogIds] = useState({});
  const [errorLevel, setErrorLevel] = useState({ errorLevel: { value: 'all' } });
  const [aggregationType, setAggregationType] = useState({});
  const [errorLevelFilterGroup, setErrorLevelFilterGroup] = useState({});
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [messageAttributeAggregation, setMessageAttributeAggregation] = useState([]);
  const [messageAttributeOptionAggregation, setMessageAttributeOptionAggregation] = useState([]);
  const [paginationDirectionKey, setPaginationDirectionKey] = useState('');

  const state = {
    pagination,
    generalFilter,
    selectedChart,
    tableRows,
    logTitle,
    messageFilter,
    selectedLog,
    selectedLogIds,
    logPathByName,
    errorLevel,
    aggregationType,
    errorLevelFilterGroup,
    selectedAttribute,
    messageAttributeAggregation,
    messageAttributeOptionAggregation,
    paginationDirectionKey,
  };

  const setState = {
    setPagination,
    setGeneralFilter,
    setSelectedChart,
    setTableRows,
    setLogTitle,
    setMessageFilter,
    setSelectedLog,
    setSelectedLogIds,
    setLogPathByName,
    setErrorLevel,
    setAggregationType,
    setErrorLevelFilterGroup,
    setSelectedAttribute,
    setMessageAttributeAggregation,
    setMessageAttributeOptionAggregation,
    setPaginationDirectionKey,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
