import React from 'react';

const TableCell = () => (
  <div className="skeleton">
    <div className="card card-cell">
      <div className="flex card-body card-w-auto">
        {Array.from({ length: 5 }, (v, i) => (
          <div className="body-block" key={i}>
            <div className="block xs" />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default TableCell;
