import React from 'react';
import Chip from '@material-ui/core/Chip';
import {
  arrayOf, shape, string, func, oneOfType, node, bool,
} from 'prop-types';
import _ from 'lodash';

const Chips = (props) => {
  const { list, className, placeholder } = props;
  return (
    <div className="chips-row">
      {list && !_.isEmpty(list) ? list.map((chip) => (
        <Chip
          key={`${chip.label}_${chip.count}`}
          id={list.indexOf(chip)}
          label={`${chip.label} ${chip.count}`}
          clickable={chip.clickable}
          className={`chip-${chip.type} ${className}`}
          onClick={chip.handleClick}
          style={{
            color: chip.color,
            backgroundColor: `rgba(${chip.color}, 0.2)`,
          }}
        />
      )) : <div className="placeholder">{placeholder}</div>}
    </div>
  );
};

Chips.propTypes = {
  className: string,
  list: arrayOf(shape({
    label: oneOfType([node, string]),
    clickable: bool,
    color: string,
    onClick: func,
  })).isRequired,
  placeholder: oneOfType([node, string]),
};

Chips.defaultProps = {
  className: '',
  placeholder: null,
};

export default Chips;
