import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { configureStore } from './store';

import './assets/scss/index.scss';

const store = configureStore();

const render = () => {
  ReactDOM.render(<App store={store} />, document.getElementById('root'));
};

if (module.hot) {
  module.hot.accept(<App store={store} />, () => {
    ReactDOM.render(<App store={store} />, document.getElementById('root'));
  });
}

render();
