import React from 'react';
import {
  number, string, oneOfType, node,
} from 'prop-types';
import ChartCard from './ChartCard';
import TableCard from './TableCard';
import TableCell from './TableCell';

const SkeletonComponent = (props) => {
  const {
    rowLength, type, message, messageSize,
  } = props;
  const getSkeleton = (Card, clsssName) => (
    <div className={`skeleton-row ${clsssName}`}>
      <div className={`skeleton-message skeleton-message-${messageSize}`}>{message}</div>
      {Array.from({ length: rowLength }, (v, i) => <Card key={i} />)}
    </div>
  );

  const renderBody = () => {
    switch (type) {
      case 'chartCard':
        return getSkeleton(ChartCard);
      case 'tableCard':
        return getSkeleton(TableCard, 'direction-column skeleton-block');
      case 'tableCell':
        return getSkeleton(TableCell, 'direction-column skeleton-block skeleton-table');

      default:
        return <div className="skeleton-row"><ChartCard /></div>;
    }
  };
  return renderBody();
};

SkeletonComponent.propTypes = {
  type: string,
  rowLength: number,
  message: oneOfType([node, string]),
  messageSize: string,
};

SkeletonComponent.defaultProps = {
  type: 'chartCard',
  rowLength: 3,
  message: null,
  messageSize: 'default',
};

export default SkeletonComponent;
