import { isEmpty } from 'lodash';

import {
  PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN,
  PRODUCTS_HISTORY_TABLE_GET_DELTA,
  PRODUCTS_HISTORY_TABLE_RESET_STATE,
} from './types';

export const setProductsHistoryTableSelectedColumn = (column) => (dispatch, getState) => {
  const { column: originColumn } = getState().productsHistoryTable;
  const newColumn = {
    ...column,
    order: 'desc',
  };

  if (
    !isEmpty(originColumn) && !isEmpty(column)
    && originColumn.origDate === column.origDate
  ) {
    newColumn.order = originColumn.order === 'asc' ? 'desc' : 'asc';
  }

  dispatch({
    type: PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN,
    payload: { column: newColumn },
  });
};

export const clearProductsHistoryTableSelectedState = () => (dispatch) => {
  dispatch({
    type: PRODUCTS_HISTORY_TABLE_RESET_STATE,
  });
};

export const getProductsHistoryTableDelta = (delta) => (dispatch) => {
  dispatch({
    type: PRODUCTS_HISTORY_TABLE_GET_DELTA,
    payload: { delta },
  });
};
