/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useSelectValue = (state, props) => {
  useEffect(() => {
    state.setSelectValue(props.value);
  }, [props.value]);
};

export default useSelectValue;
