import { isEmpty } from 'lodash';
import attributeAggList from '../../../utils/stash/attributeAggList';

export const getExtraSortOptions = (messages) => {
  if (
    !isEmpty(messages)
    && messages.some((m) => attributeAggList.some((agl) => agl.id === m.value))
  ) {
    return attributeAggList;
  }

  return [];
};

export const getMessageAttributeAggregation = (aggregations) => {
  if (!isEmpty(aggregations)) {
    return [...aggregations].shift().sources;
  }

  return [];
};

export const getAggregationType = (aggregationType) => {
  if (!isEmpty(aggregationType) && attributeAggList.some((agl) => agl.value === aggregationType)) {
    return attributeAggList.find((agl) => agl.value === aggregationType);
  }

  return {};
};

export default {
  getExtraSortOptions,
  getMessageAttributeAggregation,
  getAggregationType,
};
