import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

import {
  ATTRIBUTE_SET_BEFORE_KEYS,
  ATTRIBUTE_DELETE_BEFORE_KEYS,
} from '../actions/types';

const initialState = {
  beforeKeys: [],
};

const logRestTypes = restTypes('attribute');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case logRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case logRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case logRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    case logRestTypes.updateAllStart:
      return {
        ...state,
        ...restStates.updateAllStart,
        ...action.payload,
      };
    case logRestTypes.updateAllFinished:
      return {
        ...state,
        ...restStates.updateAllFinished,
        ...action.payload,
      };
    case logRestTypes.replaceAllFinished:
      return {
        ...state,
        ...restStates.replaceAllFinished,
        ...action.payload,
      };
    case ATTRIBUTE_SET_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    case ATTRIBUTE_DELETE_BEFORE_KEYS:
      return {
        ...state,
        ...action.payload,
      };
    default: return state;
  }
};
