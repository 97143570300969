import React from 'react';
import {
  shape, arrayOf, string, number, func,
} from 'prop-types';
import { isEmpty } from 'lodash';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Tooltip from '@material-ui/core/Tooltip';

import Block from '../../../components/Block';
import Title from '../../../components/Title';
import Group from '../../../components/Group';
import Badges from '../../../components/Badges';
import Chips from '../../../components/Chips';
import DateTimeLabel from '../../../components/DateTimeLabel';
import IntlMessages from '../../../utils/IntlMessages';
import { aggregation } from '../../../utils/variables';

const TableCell = (props) => {
  const {
    name,
    key,
    docCount,
    messages,
    statuses,
    pathByName,
  } = props.sourceData;
  const onClick = () => {
    if (props.onTableCellTitleClick) {
      props.onTableCellTitleClick(props.sourceData);
    }
  };
  const isExtraTitle = props.extra && !isEmpty(props.extra);
  const contentClass = !isEmpty(messages) && messages.length > 10 ? 'mt0' : '';
  const tmpHidden = true; // TODO

  return (
    <Block
      direction="column"
      className="p15"
      contentClassName="flex flex-grid-3"
      title={(
        <Title
          title={(
            <Tooltip
              title={(
                <div className="flex">
                  <div className="text-nowrap text-semi mr5">
                    <IntlMessages id="catalog.list.table.log.title.tooltip.pathByName.label" />
                  </div>
                  <div>{pathByName}</div>
                </div>
              )}
              placement="top"
            >
              <div className="tooltip-content">
                {name}
                {key && <small className="text-gray text-regular text-no ml5">{`(${key})`}</small>}
              </div>
            </Tooltip>
          )}
          onClick={onClick}
          titleSecondary={(
            <>
              {`Total ${docCount}`}
              <ArrowForwardIosIcon />
            </>
          )}
        />
      )}
    >
      <Group
        withIntl
        contentClassName={isExtraTitle ? 'flex items-baseline' : ''}
        title={!isExtraTitle ? 'text.issues' : props.extra.title}
      >
        {isExtraTitle && <div className="table-title-extra"><IntlMessages id={props.extra.subtitle} /></div>}
        <Badges
          tooltip
          list={messages}
          placeholder={<IntlMessages id="text.noIssuses" />}
        />
      </Group>
      <Group
        withIntl
        title="text.status"
        contentClassName={contentClass}
      >
        <Chips
          className="chip-sm"
          list={statuses}
          placeholder={<IntlMessages id="text.noStatuses" />}
        />
      </Group>
      {!tmpHidden && (
        <Group
          withIntl
          title="text.lastModified"
          contentClassName={contentClass}
        >
          <DateTimeLabel
            value={props.sourceData[aggregation.timestamp].valueAsString}
            format="MM/DD/YY LT"
          />
        </Group>
      )}
    </Block>
  );
};

TableCell.propTypes = {
  extra: shape().isRequired,
  sourceData: shape({
    id: string,
    pathByName: string,
    total: number,
    issues: arrayOf(shape()),
    statuses: arrayOf(shape()),
    updatedDate: string,
  }).isRequired,
  onTableCellTitleClick: func,
};

TableCell.defaultProps = {
  onTableCellTitleClick: null,
};

export default TableCell;
