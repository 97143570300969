import React, { useEffect, useState } from 'react';
import {
  ResponsiveContainer, ComposedChart, Line, XAxis, YAxis, Tooltip,
} from 'recharts';
import {
  shape, arrayOf, object, string, bool, oneOfType, number,
} from 'prop-types';

const Chart = (props) => {
  const {
    width,
    height,
    sourceData,
    xAxis,
    yAxis,
    strokeWidth,
    dot,
    className,
    dataKey,
  } = props;
  const [chartDomain, setChartDomain] = useState([]);

  useEffect(() => {
    setChartDomain(yAxis.domain || sourceData.domain);
  }, [yAxis.domain, sourceData.domain]);

  const CustomizedXAxisTick = (tick) => {
    const { x, y, payload } = tick;
    const { customTickSettings } = xAxis;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={customTickSettings.x}
          y={customTickSettings.y}
          dy={customTickSettings.dy}
          textAnchor={customTickSettings.textAnchor}
          fill={customTickSettings.fill}
          fontSize={customTickSettings.fontSize}
          transform={xAxis.rotate}
        >
          {payload.value}
        </text>
      </g>
    );
  };
  const CustomizedYAxisTick = (tick) => {
    const { x, y, payload } = tick;
    const { customTickSettings } = yAxis;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={customTickSettings.x}
          y={customTickSettings.y}
          dy={customTickSettings.dy}
          textAnchor={customTickSettings.textAnchor}
          fill={customTickSettings.fill}
          fontSize={customTickSettings.fontSize}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer className={className}>
      <ComposedChart
        width={width}
        height={height}
        data={sourceData.data}
      >
        {(yAxis || chartDomain) && (
          <YAxis
            tickMargin={yAxis.tickMargin}
            type={yAxis.type}
            domain={chartDomain}
            width={yAxis.width}
            height={5}
            axisLine={yAxis.axisLine}
            tickLine={yAxis.tickLine}
            tick={yAxis.customTick && <CustomizedYAxisTick />}
            padding={yAxis.padding}
            margin={yAxis.margin}
            minTickGap={yAxis.minTickGap}
            interval={0}
          />
        )}
        {xAxis && (
          <XAxis
            dataKey="name"
            interval={xAxis.interval}
            axisLine={xAxis.axisLine}
            tickLine={xAxis.tickLine}
            tick={xAxis.customTick && <CustomizedXAxisTick />}
            padding={xAxis.padding}
            margin={xAxis.margin}
            minTickGap={xAxis.minTickGap}
          />
        )}
        <Tooltip />
        <Line type="monotone" dataKey={dataKey} stroke={sourceData.color} strokeWidth={strokeWidth} dot={dot} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  className: string.isRequired,
  dataKey: string.isRequired,
  strokeWidth: number.isRequired,
  dot: shape().isRequired,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  sourceData: shape({
    color: string,
    data: arrayOf(object),
  }).isRequired,
  yAxis: shape({
    type: string,
    domain: arrayOf(number),
  }),
  xAxis: shape({
    axisLine: bool,
    tickLine: bool,
    customTick: bool,
    padding: shape(),
    margin: shape(),
  }),
};

Chart.defaultProps = {
  yAxis: null,
  xAxis: null,
  width: 300,
  height: 100,
};

export default Chart;
