import axios from 'axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
} from '../utils/paths';

import getMessageName from '../utils/getMessageName';
import getMessageColor from '../utils/getMessageColor';
import getTimeRange from '../utils/getTimeRange';
import getChartData from '../utils/getChartData';
import { mappingFields } from '../utils/variables';
import handleError from '../utils/handleError';
import getSubstructedDate from '../utils/getSubstructedDate';
import getPrevDocCount from '../utils/getPrevDocCount';

const messagActios = new RestActions('message');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
} = messagActios;

export const fetchMessageList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
}) => async (dispatch, getState) => {
  const { searchList } = getState().eventMessageType;
  const { monitoring } = getState().settings.serviceConfigs;

  const catalogValues = sessionStorage.getItem('catalogValues');
  const values = !isEmpty(catalogValues) ? JSON.parse(catalogValues) : null;

  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  const getMaxDomain = (list, item, type) => {
    switch (type) {
      case 'global':
        return Math.max(...list.map((mL) => mL.docCount));
      case 'local':
        return item.docCount;
      default:
        return item.docCount;
    }
  };

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;

  dispatch(fetchAllStart());

  try {
    const res = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );
    const timeRange = !isEmpty(values) && values.timeFrameValue
      ? values.timeFrameValue
      : 'last_24_hours';

    const dateOptions = timeRange.split('_');
    const type = dateOptions.pop();
    const numbers = Number(dateOptions.slice(1, 2));
    const prevPeriodBody = {
      ...body,
      filter: filter.map((f) => ({
        ...f,
        group: f.group.map((g) => {
          if (g.field === '@timestamp') {
            return ({
              ...g,
              value: {
                from: {
                  ...g.value.from,
                  value: getSubstructedDate({ date: g.value.from.value, numbers, type }),
                },
                to: {
                  ...g.value.to,
                  value: getSubstructedDate({ date: g.value.to.value, numbers, type }),
                },
              },
            });
          }
          return g;
        }),
      })),
    };

    const prevPeriodRes = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(prevPeriodBody),
    );

    const timeRangeList = getTimeRange(timeRange);

    const aggKey = window.location.pathname.includes('system')
      ? mappingFields.messageIdKeyword
      : mappingFields.messageId;

    const messageList = camelizeKeys(res.data.aggregations[aggKey].buckets);
    const preMessageList = camelizeKeys(prevPeriodRes.data.aggregations[aggKey].buckets);

    const mappedList = messageList.map((m) => ({
      ...m,
      id: m.key,
      value: m.key,
      label: m.label || m.key || `${messageList.indexOf(m)}_${m.merchantId}`,
      name: getMessageName(m, searchList) || m.key,
      color: getMessageColor(m, searchList),
      errors: {
        current: m.docCount,
        prev: getPrevDocCount(preMessageList, m),
      },
      domain: [0, getMaxDomain(messageList, m, 'local')],
      timeRange,
      data: getChartData({
        timeRange,
        source: m,
        timeRangeList,
        dataKey: 'issues',
      }),
    }));
    dispatch(fetchAllFinished({ list: mappedList }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
    }));
  }
};

export const clearFromMessageState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
