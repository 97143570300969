import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

const initialState = {};
const errorRestTypes = restTypes('error');
const {
  setErrorFinished,
  clearErrorFinished,
} = errorRestTypes;

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case setErrorFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case clearErrorFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };

    default: return state;
  }
};
