import moment from 'moment';
import getDate from './getDate';

const getLastTimeRange = ({ number, format, timeKey }) => [...new Array(number)].map((i, idx) => moment().startOf(timeKey).subtract(idx, `${timeKey}s`)).map((d) => moment(d).format(format));
const getLastHours = (hoursNumber, format = 'HH:00') => [...new Array(hoursNumber)].map((i, idx) => moment().startOf(moment(new Date()).add(-1, 'days')).subtract(idx, 'hours')).map((d) => moment(d).format(format));
const getDiffNumber = (key) => moment().diff(getDate(key), 'days');

export default (key) => {
  const source = {
    number: getDiffNumber(key),
    format: 'MMM YY',
    timeKey: 'month',
  };

  const dateOptions = key.split('_');
  const rangeNumber = Number(dateOptions.slice(1, 2));

  switch (key) {
    case 'last_6_hours':
    case 'last_24_hours':
      return getLastHours(rangeNumber);
    case 'last_7_days':
      return moment.weekdaysShort();
    case 'last_30_days':
      return getLastTimeRange({
        number: 30,
        format: 'D MMM',
        timeKey: 'day',
      });
    case 'last_3_months':
      return getLastTimeRange({
        ...source,
        number: 3,
      });

    default:
      return getLastHours(24);
  }
};
