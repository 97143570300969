import React, { useState, useEffect } from 'react';
import {
  string, number, shape, bool, oneOfType, func, arrayOf, node,
} from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const Toggler = (props) => {
  const {
    grid, buttonGroup, list, staticLabel,
  } = props;
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (!list.some((l) => l.value === selected)) {
      setSelected('');
    }
  }, [selected, list]);

  const handleChange = (event, newAlignment) => {
    setSelected(newAlignment);
    props.onChange(newAlignment);
  };

  const getGroup = () => !isEmpty(list) && list.map((group) => (
    <ToggleButton
      key={`${list.indexOf(group)}_${group.value}`}
      value={group.value}
      className={group.className}
      disabled={group.disabled}
    >
      {group.content}
    </ToggleButton>
  ));

  return (
    <Grid
      className={grid.className}
      container={grid.container}
      spacing={grid.spacing}
      direction={grid.direction}
      alignItems={grid.alignItems}
    >
      <Grid className={staticLabel ? 'flex items-center' : ''} item>
        {staticLabel && <div className="fz-12 mr5">{staticLabel}</div>}
        <ToggleButtonGroup
          exclusive
          size={buttonGroup.size}
          value={selected}
          onChange={handleChange}
        >
          {getGroup()}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

Toggler.propTypes = {
  grid: shape({
    container: bool,
    item: bool,
    spacing: number,
    direction: string,
    alignItems: string,
  }),
  buttonGroup: shape({
    size: string,
  }),
  list: arrayOf(shape({
    value: string,
    content: oneOfType([node, string]),
    disabled: bool,
  })),
  staticLabel: oneOfType([node, string]),
  onChange: func,
};

Toggler.defaultProps = {
  grid: {
    container: false,
    item: false,
    spacing: 2,
    alignItems: 'center',
    direction: 'column',
  },
  buttonGroup: {
    size: 'small',
  },
  list: [],
  staticLabel: null,
  onChange: null,
};

export default Toggler;
