import { useState } from 'react';
import { isEmpty } from 'lodash';
import { defaultPagination } from '../../../utils/defaultData';
import getSystemFilter from '../../../utils/getSystemFilter';

export const useStateHook = (props) => {
  const systemValues = sessionStorage.getItem('systemValues');
  const values = !isEmpty(systemValues) ? JSON.parse(systemValues) : null;

  const [pagination, setPagination] = useState(
    values ? values.pagination : defaultPagination,
  );
  const [fields, setFields] = useState(props.fields);
  const [sort, setSort] = useState(props.fields);
  const [targetList, setTargetList] = useState([]);
  const [originFilter, setOriginFilter] = useState([]);
  const [originValue, setOriginValue] = useState(
    values && values.originValue ? values.originValue : '',
  );
  const [timeFrameFilter, setTimeFrameFilter] = useState([]);
  const [messageFilter, setMessageFilter] = useState(
    values && values.messages ? values.messages : [],
  );
  const [timeFrameValue, setTimeFrameValue] = useState(
    values && !isEmpty(values.timeFrameValue) ? values.timeFrameValue : 'last_24_hours',
  );
  const [selectedChart, setSelectedChart] = useState(
    values && !isEmpty(values.messages) ? { id: values.messages[0].value } : {},
  );
  const [searchFilter, setSearchFilter] = useState({});
  const [refreshFilter, setRefreshFilter] = useState(false);
  const [selectedError, setSelectedError] = useState({});
  const [filter, setFilter] = useState(getSystemFilter(values, props));
  const [messageAttributeAggregation, setMessageAttributeAggregation] = useState([]);
  const [aggregationType, setAggregationType] = useState({});
  const [errorLevelFilterGroup, setErrorLevelFilterGroup] = useState({});
  const [errorLevel, setErrorLevel] = useState({ errorLevel: { value: 'all' } });

  const state = {
    pagination,
    fields,
    sort,
    filter,
    targetList,
    originFilter,
    timeFrameValue,
    timeFrameFilter,
    selectedChart,
    messageFilter,
    searchFilter,
    originValue,
    refreshFilter,
    selectedError,
    messageAttributeAggregation,
    aggregationType,
    errorLevelFilterGroup,
    errorLevel,
  };

  const setState = {
    setPagination,
    setFields,
    setSort,
    setFilter,
    setTargetList,
    setOriginFilter,
    setTimeFrameValue,
    setTimeFrameFilter,
    setSelectedChart,
    setMessageFilter,
    setSearchFilter,
    setOriginValue,
    setRefreshFilter,
    setSelectedError,
    setMessageAttributeAggregation,
    setAggregationType,
    setErrorLevelFilterGroup,
    setErrorLevel,
  };

  return {
    ...state,
    ...setState,
  };
};

export default useStateHook;
