import { camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import firebase from 'firebase/app';
import RestActions from '../utils/rest/actions';
import { monitoringSettingsPath } from '../utils/paths';

import {
  RTL_LAYOUT,
  SET_LANGUAGE,
} from './types';

const timeFrameOptionsActions = new RestActions('settings');
const {
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
} = timeFrameOptionsActions;

export const rtlLayoutAction = (isRtlLayout) => ({
  type: RTL_LAYOUT,
  payload: isRtlLayout,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const fetchAppSettings = (user) => async (dispatch) => {
  dispatch(fetchAllStart());
  const monitoringSettings = await firebase.database().ref(monitoringSettingsPath).once('value');
  const monitoringSettingsObject = monitoringSettings.val();
  const monitoringSettingsResult = camelizeKeys(monitoringSettingsObject);
  const clonedRes = { ...monitoringSettingsResult };
  if (!isEmpty(user)) {
    const newConfigs = {
      ...monitoringSettingsResult.serviceConfigs,
      monitoring: {
        ...monitoringSettingsResult.serviceConfigs.monitoring,
        token: user.ra,
      },
    };

    clonedRes.serviceConfigs = newConfigs;
  }
  await dispatch(fetchAllFinished(clonedRes));
};

export const clearFromTimeFrameOptionsState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
