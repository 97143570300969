import React from 'react';
import {
  shape, string, func, node, oneOfType, bool,
} from 'prop-types';

import SourceList from './SourceList';
import TargetList from './TargetList';

import useStateStore from './hooks/useState';
import useUnmount from './hooks/useUnmount';
import useSourceList from './hooks/useSourceList';


const Transfer = (props) => {
  const state = useStateStore(props);

  useUnmount(state);
  useSourceList(state, props);

  const handleOnSourceItemClick = (item) => {
    const { targetList } = state;
    if (!targetList.find((el) => el.id === item.id)) {
      targetList.push(item);
    }
    state.setTargetList([...targetList]);

    if (props.onChange) props.onChange({ targetList: [...targetList] });
  };

  const handleOnTargetItemClick = (item) => {
    const { sourceList, targetList } = state;
    if (targetList.find((el) => el.id === item.id)) {
      const index = targetList.indexOf(item);
      targetList.splice(index, 1);
    }

    if (!sourceList.find((sItem) => sItem.id === item.id)) {
      sourceList.push(item);
    }
    state.setSourceList([...sourceList]);
    state.setTargetList([...targetList]);

    if (props.onChange) props.onChange({ targetList: [...targetList] });
  };

  const handleOnSelectAllClick = () => {
    const { targetList, sourceList } = state;

    const filteredSourceItems = sourceList.filter(
      (sl) => !targetList.find((tl) => tl.id === sl.id),
    );

    const targetListToUpdate = [...targetList, ...filteredSourceItems];

    state.setTargetList(targetListToUpdate);

    if (props.onChange) props.onChange({ targetList: targetListToUpdate });
  };

  const handleOnUnselectAllClick = () => {
    state.setTargetList([]);
    if (props.onChange) props.onChange({ targetList: [] });
  };

  const {
    className, sourceTitle, targetTitle, tooltip, selectAll, unselectAll,
  } = props;
  const { sourceList, targetList } = state;
  return (
    <div className={`transfer-wrapper ${className}`}>
      <SourceList
        title={sourceTitle}
        selectAll={selectAll}
        sourceList={sourceList}
        targetList={targetList}
        tooltip={tooltip}
        onClick={handleOnSourceItemClick}
        onSelectAll={handleOnSelectAllClick}
      />
      <TargetList
        title={targetTitle}
        unselectAll={unselectAll}
        targetList={targetList}
        tooltip={tooltip}
        onClick={handleOnTargetItemClick}
        onUnselectAll={handleOnUnselectAllClick}
      />
    </div>
  );
};

Transfer.propTypes = {
  className: string,
  targetTitle: oneOfType([string, node]),
  sourceTitle: oneOfType([string, node]),
  onChange: func,
  tooltip: shape({
    placement: string,
  }),
  selectAll: bool,
  unselectAll: bool,
};

Transfer.defaultProps = {
  onChange: null,
  sourceTitle: '',
  targetTitle: '',
  className: '',
  tooltip: null,
  selectAll: false,
  unselectAll: false,
};

export default Transfer;
