const config = {
  env: process.env.REACT_APP_ENV,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  apiPath: process.env.REACT_APP_API_PATH,
  apiToken: process.env.REACT_APP_API_TOKEN,
  monApiPath: process.env.REACT_APP_MON_API_PATH,
};

export default config;
