import React from 'react';
import {
  shape, any, func, oneOfType, array, object,
} from 'prop-types';
import { isEmpty } from 'lodash';
import TableCell from './TableCell';
import Block from '../../../components/Block';

const TableBody = (props) => {
  const { sourceData, extra } = props;

  const getTableBody = () => {
    switch (sourceData.contentType) {
      case 'node':
        return sourceData.list.map((cell) => (
          <Block
            direction="column"
            key={cell.id}
            className="catalog attribute-cell collapse-container-scroll p0"
          >
            {cell.attributeName}
          </Block>
        ));
      case 'array':
        return (
          sourceData.list.map((cell) => (
            <TableCell
              extra={extra}
              key={sourceData.list.indexOf(cell)}
              sourceData={cell}
              onTableCellTitleClick={props.onTableCellTitleClick}
            />
          ))
        );

      default:
        return sourceData;
    }
  };

  return sourceData && !isEmpty(sourceData) && (
    <div className="table-body block">
      {getTableBody()}
    </div>
  );
};

TableBody.propTypes = {
  extra: shape().isRequired,
  sourceData: oneOfType([object, array, any]),
  onTableCellTitleClick: func,
};

TableBody.defaultProps = {
  onTableCellTitleClick: null,
  sourceData: null,
};

export default TableBody;
