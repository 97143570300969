/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useMecrhant = (state) => {
  useEffect(() => {
    const isMerchant = window.location.pathname.includes('merchant');
    const merchantTitle = window.location.pathname.split('/').slice(-1).join('');
    if (isMerchant) {
      state.setLogTitle(merchantTitle);
    }
  }, [state.logTitle]);
};

export default useMecrhant;
