import {
  PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN,
  PRODUCTS_HISTORY_TABLE_GET_DELTA,
  PRODUCTS_HISTORY_TABLE_RESET_STATE,
} from '../actions/types';

import restInitState from '../utils/rest/initState';

const initialState = {
  column: {
    index: 1,
    order: 'desc',
  },
  delta: [],
};

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case PRODUCTS_HISTORY_TABLE_SET_SELECTED_COLUMN:
      return {
        ...state,
        ...action.payload,
      };

    case PRODUCTS_HISTORY_TABLE_RESET_STATE:
      return {
        ...state,
        ...initialState,
      };

    case PRODUCTS_HISTORY_TABLE_GET_DELTA:
      return {
        ...state,
        ...action.payload,
      };

    default: return { ...state };
  }
};
