export default ({ tableRows, selectedLogIds, recordStatus }) => {
  const selectedLogs = tableRows.map((tR) => {
    if (selectedLogIds.some((sL) => sL === tR.sqsId)) {
      return ({
        ...tR,
        recordStatus,
      });
    }
    return tR;
  });

  return selectedLogs;
};
