import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isEmpty } from 'lodash';

import Charts from '../../../components/Charts';
import Table from '../../../components/Table';

import { rowsPerPageOptions } from '../../../utils/defaultData';
import { defaultProps, propTypes } from './propTypes';

const CatalogBody = (props) => {
  const {
    loading, chart, logType, table,
  } = props;
  let extra = {};
  if (chart.selected && !isEmpty(chart.selected)) {
    extra = {
      title: 'text.errorsType',
      subtitle: 'text.mapping',
    };
  }

  return (
    <>
      <div className="section-block min-h-lg">
        {loading.charts && (
          <div className="pos-abs full section-loader">
            <LinearProgress />
          </div>
        )}
        <Charts
          chartList={chart.list}
          loading={loading.charts}
          selected={chart.selected}
          deleteSelected={chart.onDelete}
          onChartClick={chart.onClick}
        />
      </div>
      <Table
        extra={extra}
        useTablePagination={table.useTablePagination}
        extraActions={table.extraActions}
        loading={loading.logs || loading.charts}
        list={table.list}
        total={table.total}
        logType={logType}
        onLogTypeChange={props.onLogTypeChange}
        onTableCellTitleClick={props.onTableCellTitleClick}
        onPaginationChange={props.onPaginationChange}
        pagination={{
          rowsPerPageOptions,
          data: props.pagination,
        }}
      />
    </>
  );
};

CatalogBody.propTypes = propTypes;
CatalogBody.defaultProps = defaultProps;

export default CatalogBody;
