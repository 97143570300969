import React, { useState, useEffect, Fragment } from 'react';
import { isEmpty } from 'lodash';
import {
  string, arrayOf, shape, oneOfType, node, bool, func,
} from 'prop-types';
import {
  ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';


const Expansion = (props) => {
  const { panels, className, isAllCollapsed } = props;
  const [expanded, setExpanded] = useState(!isAllCollapsed ? 'panel0' : '');

  useEffect(() => {
    if (!isAllCollapsed && !isEmpty(expanded)) {
      setExpanded('');
    }
  }, [expanded, isAllCollapsed]);

  const handleChange = (panelName) => (event, newExpanded) => {
    setExpanded(newExpanded ? panelName : false);
  };

  const handlePanelClick = (panel) => {
    props.onPanelClick(!expanded || panel.titleId !== expanded ? panel : {});
  };

  return !isEmpty(panels) && (
    <div className={`expansion-panel ${className}`}>
      {
        panels.map((panel, index) => {
          const key = `panel-${index}-${panel.titleId}`;
          return (
            <Fragment key={key}>
              <ExpansionPanel
                square
                expanded={expanded === key}
                onChange={handleChange(key)}
                onClick={() => handlePanelClick(panel)}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`${key}-d-content`}
                  id={`${key}-d-content`}
                >
                  <div className="pane-title">{panel.title}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {panel.details}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Fragment>
          );
        })
      }
    </div>
  );
};

Expansion.propType = {
  titleId: string,
  panels: arrayOf(shape({
    titleId: string,
    title: oneOfType([string, node]),
    details: oneOfType([string, node]),
  })),
  isAllCollapsed: bool,
  onPanelClick: func,
};

Expansion.defaultTypes = {
  className: '',
  panels: [],
  isAllCollapsed: false,
  onPanelClick: null,
};

export default Expansion;
