export default ({
  order = 'desc',
  size = 100,
  suffix,
  aggregations,
}) => ([{
  type: 'composite',
  bucket: 'category_count',
  size,
  sources: [
    {
      field: 'count_products',
      order,
      operator: 'terms',
    },
    {
      field: 'entity_id',
      operator: 'terms',
    },
  ],
  ...aggregations,
  ...suffix,
}]);
