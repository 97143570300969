import React from 'react';
import {
  shape, string, oneOfType, node,
} from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const TooltipWrapper = (props) => (
  props.tooltip && props.tooltip.placement
    ? (
      <Tooltip
        title={props.title}
        placement={props.tooltip.placement}
      >
        <div className="wrapper">
          {props.children}
        </div>
      </Tooltip>
    )

    : props.children
);

TooltipWrapper.propTypes = {
  children: node,
  title: oneOfType([string, node]),
  tooltip: shape({
    placement: string,
  }),
};

TooltipWrapper.defaultProps = {
  title: '',
  tooltip: {},
  children: null,
};

export default TooltipWrapper;
