import restTypes from '../utils/rest/types';
import restStates from '../utils/rest/states';
import restInitState from '../utils/rest/initState';

const initialState = {};
const messageRestTypes = restTypes('system_log_message');

export default (state = { ...restInitState, ...initialState }, action = {}) => {
  switch (action.type) {
    case messageRestTypes.fetchAllStart:
      return {
        ...state,
        ...restStates.fetchAllStart,
      };
    case messageRestTypes.fetchAllFinished:
      return {
        ...state,
        ...restStates.fetchAllFinished,
        ...action.payload,
      };
    case messageRestTypes.clearFromStateFinished:
      return {
        ...state,
        ...restStates.clearFromStateFinished,
        ...action.payload,
      };
    default: return state;
  }
};
