import React from 'react';
import {
  shape, string, number, oneOfType, func,
} from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

const TargetItem = (props) => {
  const { dataSource: { tItem } } = props;
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(tItem);
    }
  };

  return (
    <div className="transfer-list-item-wrapper">
      <span className="transfer-list-item-label">
        <span className="transfer-list-item-label-container">{tItem.title || tItem.label || tItem.name}</span>
        {tItem.id && <div className="transfer-list-item-sublabel">{`(${tItem.id})`}</div>}
      </span>
      <IconButton
        aria-label="Delete"
        className="btn-ico-sm ml-10 text-danger"
        onClick={handleClick}
      >
        <CloseRoundedIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

TargetItem.propTypes = {
  dataSource: shape({
    tItem: shape({
      id: oneOfType([string, number]),
    }),
  }),
  onClick: func,
};

TargetItem.defaultProps = {
  dataSource: {
    tItem: {},
  },
  onClick: null,
};

export default TargetItem;
