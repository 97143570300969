import {
  fetchDetailsLogMessageList,
  clearFromDetailsLogMessageState,
} from '../../../../../actions/detailsLogMessage';

import {
  fetchDetailsLogList,
  clearFromDetailsLogState,
} from '../../../../../actions/detailsLog';

import {
  fetchAttributeAggregationList,
  updateAttributeList,
  replaceAttributeList,
  setAttributeBeforeKey,
  deleteAttributeBeforeKeys,
  clearFromAttributeState,
} from '../../../../../actions/attribute';

import {
  fetchAttributeOptionAggregationList,
  updateAttributeOptionList,
} from '../../../../../actions/attributeOption';

import {
  fetchPhysicalCategory,
  clearFromPhysicalCategoryState,
} from '../../../../../actions/physicalCategory';

import {
  updateLog,
  updateLogMultiple,
} from '../../../../../actions/log';

export const mapStateToProps = (state) => ({
  log: state.log,
  detailsLogMessage: state.detailsLogMessage,
  eventMessageType: state.eventMessageType,
  detailsLog: state.detailsLog,
  settings: state.settings,
  physicalCategory: state.physicalCategory,
  attribute: state.attribute,
  attributeOption: state.attributeOption,
});

export const mapDispatchToProps = {
  fetchDetailsLogMessageList,
  clearFromDetailsLogMessageState,
  fetchDetailsLogList,
  clearFromDetailsLogState,
  fetchPhysicalCategory,
  clearFromPhysicalCategoryState,
  updateLog,
  updateLogMultiple,
  fetchAttributeAggregationList,
  updateAttributeList,
  replaceAttributeList,
  fetchAttributeOptionAggregationList,
  updateAttributeOptionList,
  setAttributeBeforeKey,
  deleteAttributeBeforeKeys,
  clearFromAttributeState,
};

export default {
  mapStateToProps,
  mapDispatchToProps,
};
