import React from 'react';
import {
  shape, string, bool,
} from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const Badge = (props) => {
  const { badge, tooltip } = props;
  return (
    tooltip
      ? (
        <Tooltip title={badge.name} placement="top">
          <div className="rct-badge" style={{ color: badge.color }}>
            <span className="rct-badge-value">{badge.value}</span>
          </div>
        </Tooltip>
      ) : (
        <div className="rct-badge">
          <span className="rct-badge-value">{badge.value}</span>
        </div>
      )
  );
};

Badge.propTypes = {
  tooltip: bool,
  badge: shape({
    id: string,
  }).isRequired,
};

Badge.defaultProps = {
  tooltip: false,
};

export default Badge;
