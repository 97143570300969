/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty, isEqual, sortBy } from 'lodash';
import { getAggregationField } from '../../../../../utils/getAggregationField';
import getLogType from '../../../../../utils/getLogType';
import getGroupFilter from '../../../utils/getGroupFilter';
import { aggregation, keywordTypeSet, mappingFields } from '../../../../../utils/variables';
import attributeAggList from '../../../../../utils/stash/attributeAggList';
import getDateRangeFilter from '../../../utils/getDateRangeFilter';
import capitalizeString from '../../../../../utils/capitalizeString';

const useLog = (state, props) => {
  useEffect(() => {
    const { list, fetched, total } = props.log;
    if (!isEmpty(list) && fetched) {
      state.setTableTotal(total);
    }
  }, [
    props.log.fetched,
  ]);

  useEffect(() => {
    props.setLogBeforeKey({
      data: props.log.after,
      directionKey: state.paginationDirectionKey,
    });
  }, [
    state.pagination,
  ]);

  const fetchAggregatedLogs = () => {
    if (
      props.eventMessageType.searched
      && !isEmpty(props.settings.statusOptions)
      && !props.log.fetching
      && !props.physicalCategory.fetchingById
    ) {
      let filter = [];
      let categoryGroup = [];
      let merchantGroup = [];
      let timeRangeGroup = [];
      let errorLevelGroup = [];
      let messageFilterGroup = [];
      let logTypesSearcFilter = [];
      const origin = state.originFilter;
      const categories = [...state.categoriesFilter];
      const merchants = [...state.merchantsFilter];
      const timeRange = [...state.timeFrameFilter];
      const message = [...state.messageFilter];

      if (!attributeAggList.some((agg) => agg.value === state.logType)) {
        logTypesSearcFilter = [{
          field: 'log_type',
          value: [getLogType(), capitalizeString(getLogType())],
          operator: 'in',
        }];
      }

      const groups = [origin, timeRange, logTypesSearcFilter].flat();

      let aggField = '';

      switch (state.logType) {
        case 'category':
          aggField = mappingFields.propertiesCategories;
          break;

        default:
          aggField = getAggregationField({
            prefix: 'properties',
            key: state.logType,
            suffix: keywordTypeSet.has(state.logType) ? 'keyword' : false,
          });
          break;
      }

      if (!isEmpty(categories)) {
        categoryGroup = [{
          condition: !isEmpty(categories) ? 'or' : 'and',
          group: getGroupFilter({
            field: mappingFields.propertiesCategories,
            list: categories.map((c) => c.value),
            operator: 'eq',
            valueIsString: true,
          }),
        }];
      }

      if (!isEmpty(merchants)) {
        merchantGroup = [{
          condition: !isEmpty(merchants) ? 'or' : 'and',
          group: merchants,
        }];
      }

      if (isEmpty(timeRange)) {
        timeRangeGroup = [{
          condition: 'and',
          group: getDateRangeFilter(state.timeFrameValue),
        }];
      }

      if (!isEmpty(message)) {
        messageFilterGroup = [{
          condition: 'and',
          group: message,
        }];
      }

      if (!isEmpty(state.errorLevelFilterGroup)) {
        errorLevelGroup = state.errorLevelFilterGroup;
      }

      if (!isEmpty(message)) {
        filter = [
          {
            condition: 'and',
            group: [groups, message].flat(),
          }, categoryGroup, merchantGroup,
        ].flat();
      }

      if (!isEmpty(groups)) {
        filter = [
          {
            condition: 'and',
            group: groups,
          }, categoryGroup, merchantGroup, timeRangeGroup, errorLevelGroup,
        ].flat();
        if (!isEqual(sortBy(filter), sortBy(state.filter))) {
          state.setFilter(filter);
        }
      }

      const compositeSubAggregation = [{
        field: aggregation.messageId,
        operator: 'terms',
      }, {
        field: aggregation.recordStatus,
        operator: 'terms',
      }, {
        field: aggregation.timestamp,
        operator: 'max',
      }];

      const compositeField = aggField === 'properties' && attributeAggList.some((agg) => agg.value === state.logType)
        ? attributeAggList.find((agg) => agg.value === state.logType).field
        : aggField;

      const compositeData = {
        type: 'composite',
        bucket: compositeField,
        size: state.pagination.limit,
        aggregations: compositeSubAggregation,
        sources: [{
          field: compositeField,
          order: 'desc',
          operator: 'terms',
        }],
      };

      const compositeAggtegation = !isEmpty(state.paginationDirectionKey)
        ? ({
          ...compositeData,
          after: state.paginationDirectionKey === 'after'
            ? props.log[state.paginationDirectionKey]
            : props.log.beforeKeys[props.log.beforeKeys.length - 2],
        })
        : ({
          ...compositeData,
        });

      const searchFilter = {
        aggregations: [compositeAggtegation],
        fields: [aggField, 'message_id'],
        filter: [...filter, messageFilterGroup, state.attributeFilterGroup].flat(),
        pagination: state.pagination,
        logType: state.logType,
      };

      const catalogValues = {
        origins: state.originFilter,
        categories: state.categoriesFilter,
        merchants: state.merchantsFilter,
        messages: state.messageFilter,
        timeFrameValue: state.timeFrameValue,
        logType: state.logType,
        entityType: state.entityType,
        originValue: state.originValue.toLowerCase(),
        selectCategoryQuery: state.selectCategoryQuery,
        aggregations: searchFilter.aggregations,
        fields: searchFilter.fields,
      };

      sessionStorage.setItem('catalogValues', JSON.stringify(catalogValues));

      if (!attributeAggList.some((agg) => agg.value === state.logType)) {
        props.fetchAggregatedLogList(searchFilter);
      }
    }
  };

  useEffect(() => {
    if (
      state.logType === 'category'
      && !props.physicalCategory.fetchingById
      && props.physicalCategory.fetchedById
    ) {
      fetchAggregatedLogs('all');
    } else {
      fetchAggregatedLogs('else');
    }
  }, [
    props.eventMessageType.searched,
    state.originFilter,
    state.categoriesFilter,
    state.sort,
    state.pagination,
    state.aggregations,
    state.fields,
    state.merchantsFilter,
    state.logType,
    state.messageFilter,
    state.refreshFilter,
    !isEmpty(props.settings.statusOptions),
    state.timeFrameFilter,
    state.errorLevelFilterGroup,
  ]);
};

export default useLog;
