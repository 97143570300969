export default ({ element, behavior, top }) => setTimeout(() => {
  if (element.scrollTo) {
    element.scrollTo({
      top,
      behavior,
    });
  } else {
    const scrollingElement = (element || window.document.body);
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }
}, 500);
