/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getTableBody } from '../../../utils/tableSettings';

const useTableBody = (state, props) => {
  const { aggregateList, fetchAggregatedProductsHistoryFinished } = props.productsHistory;
  useEffect(() => {
    if (fetchAggregatedProductsHistoryFinished && !isEmpty(state.tableDateRange)) {
      const tBody = getTableBody(state.tableDateRange, aggregateList);
      state.setTableBody(tBody);
    }
  }, [
    fetchAggregatedProductsHistoryFinished,
  ]);
};

export default useTableBody;
