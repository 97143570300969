/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const usePhysicalCategories = (state, props) => {
  useEffect(() => {
    const isCategory = window.location.pathname.includes('category');
    const categoryId = window.location.pathname.split('/').slice(-1).join('');
    if (categoryId && categoryId.includes('ctgr_') && !props.physicalCategory.fetchedOne && isCategory) {
      props.fetchPhysicalCategory(categoryId);
    }

    if (
      props.physicalCategory.fetchedOne && !isEmpty(props.physicalCategory.item)
      && isCategory
    ) {
      state.setLogTitle(props.physicalCategory.item.name);
      state.setLogPathByName(props.physicalCategory.item.pathByName);
    }
  }, [props.physicalCategory.fetchedOne]);
};

export default usePhysicalCategories;
