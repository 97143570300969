import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from './SignIn';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';

import {
  signinAppPath,
  forgotPasswordPath,
  changePasswordPath,
} from './utils/paths';

export default () => (
  <Switch>
    <Route exact path={signinAppPath} component={SignIn} />
    <Route exact path={forgotPasswordPath} component={ForgotPassword} />
    <Route exact path={changePasswordPath} component={ChangePassword} />
  </Switch>
);
