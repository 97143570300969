const types = {
  merchant: 'merchant_id',
  recordStatus: 'record_status',
  messageId: 'message_id',
  category: 'categories',
};

export const getAggregationField = (data) => {
  const { suffix, key, prefix } = data;
  const type = types[key];
  return [prefix, type, suffix].filter((el) => el).join('.');
};

export default {
  getAggregationField,
};
