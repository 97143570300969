/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { aggregation } from '../../../../../utils/variables';
import getDateRangeFilter from '../../../utils/getDateRangeFilter';

const useMerchant = (state, props) => {
  useEffect(() => {
    if (!props.merchant.fetching) {
      const fetchList = async () => {
        let updatedFilter = state.filter;
        let timeRangeGroup = [];
        if (!isEmpty(state.filter)) {
          const merchantFilterItem = state.filter.find((f) => f.group.some((fg) => fg.field === 'properties.merchant_id'));
          const merchantFilterItemIndex = state.filter.indexOf(merchantFilterItem);
          updatedFilter = state.filter.filter((f, i) => i !== merchantFilterItemIndex);
        }

        const valuesStorage = JSON.parse(sessionStorage.getItem('catalogValues'));
        const catalogValues = !isEmpty(valuesStorage) ? valuesStorage : {};

        if (!isEmpty(catalogValues.timeFrameValue)) {
          timeRangeGroup = {
            condition: 'and',
            group: getDateRangeFilter(state.timeFrameValue),
          };
          updatedFilter.push(timeRangeGroup);
        }

        props.fetchMerchantList({
          fields: [aggregation.merchantId],
          aggregations: [{
            field: aggregation.merchantId,
            operator: 'terms',
            pagination: {
              page: 1,
              limit: 100,
            },
          }],
          pagination: state.pagination,
          filter: updatedFilter.flat(),
        });
      };
      fetchList();
    }
  }, [
    state.filter,
  ]);

  useEffect(() => {
    const valuesStorage = JSON.parse(sessionStorage.getItem('catalogValues'));
    const catalogValues = !isEmpty(valuesStorage) ? valuesStorage : {};
    if (
      catalogValues && !isEmpty(catalogValues.merchants) && props.merchant.fetched
    ) {
      const merchants = catalogValues.merchants.map(
        (mF) => props.merchant.list.find((m) => mF.value === m.value),
      );
      state.setSelectedMerchants(merchants);
    }
  }, [
    props.merchant.fetched,
    state.refreshFilter,
  ]);
};

export default useMerchant;
