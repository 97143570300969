import React from 'react';
import {
  shape, string, func, node, bool,
} from 'prop-types';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utils/IntlMessages';


const SimplePopover = (props) => {
  const {
    content, button, size, settings, className, footer, okButtonClass, disabledOk,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.onClose) props.onClose();
  };

  const onCancel = () => {
    handleClose();
    if (props.onCancel) props.onCancel();
  };

  const onOk = () => {
    handleClose();
    if (props.onOk) props.onOk();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={`popover-wrapper popover-${size} ${className}`}>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={button.className}
      >
        {button.title && <div className="btn-title">{button.title}</div>}
        {
          button.value && (
            <div className="btn-value">
              {button.value}
              <ArrowDropDownRoundedIcon className="icon-abs fz-20" />
            </div>
          )
        }
      </Button>
      <Popover
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...settings}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {content}
        {footer && (
          <div className="popover-footer">
            <Button
              variant="contained"
              className="btn-muted"
              onClick={onCancel}
            >
              <IntlMessages id="text.cancel" />
            </Button>
            <Button
              variant="contained"
              className={`btn-info ${okButtonClass}`}
              onClick={onOk}
              disabled={disabledOk}
            >
              <IntlMessages id="text.select" />
            </Button>
          </div>
        )}
      </Popover>
    </div>
  );
};

SimplePopover.propTypes = {
  button: shape({
    className: string,
  }),
  size: string,
  className: string,
  okButtonClass: string,
  settings: shape({}),
  content: node,
  footer: bool,
  onCancel: func,
  onClose: func,
  onOk: func,
  disabledOk: bool,
};

SimplePopover.defaultProps = {
  button: shape({
    className: '',
  }),
  size: 'daefault',
  okButtonClass: '',
  className: '',
  settings: {},
  content: null,
  footer: false,
  onCancel: null,
  onOk: null,
  onClose: null,
  disabledOk: bool,
};

export default SimplePopover;
