/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useUnmount = (state, props) => {
  useEffect(() => () => {
    props.clearFromDetailsLogMessageState({
      list: [],
      item: {},
      fetched: false,
    });
    props.clearFromDetailsLogState({
      list: [],
      fetched: false,
    });
    props.clearFromPhysicalCategoryState({
      item: {},
      fetchedOne: false,
      selectedList: [],
    });
  }, []);
};

export default useUnmount;
