import axios from 'axios';
import humps from 'humps';
import { isEmpty } from 'lodash';

import RestActions from '../utils/rest/actions';

import {
  getPhysicalCategoryWithLocaleSearchPath,
  getPhysicalCategoriesWithLocaleSearchPath,
} from '../utils/paths';
import { mapObjectToArray } from '../utils/objectsToArray';
import { setLogList } from './log';

import {
  PHYSICAL_CATEGORY_SEARCH_BY_IDS_START,
  PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED,
  PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START,
  PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED,
} from './types';
import handleError from '../utils/handleError';

const logActions = new RestActions('physical_category');
const {
  fetchStart,
  fetchFinished,
  fetchAllStart,
  fetchAllFinished,
  searchAllStart,
  searchAllFinished,
  clearFromStateFinished,
  clearStateFinished,
} = logActions;

const getMappedCategories = (categoryLogs, physicalCategories) => (
  categoryLogs.map((cl) => ({
    ...cl,
    name: physicalCategories.find((pC) => pC.id === cl.key)
      ? physicalCategories.find((pC) => pC.id === cl.key).name
      : cl.key,
    pathByName: physicalCategories.find((pC) => pC.id === cl.key)
      ? physicalCategories.find((pC) => pC.id === cl.key).pathByName
      : cl.key,
  }))
);

export const fetchPhysicalCategory = (id) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch(fetchStart());
  axios.get(getPhysicalCategoryWithLocaleSearchPath(cdms.apiPath, id, locale)).then((res) => {
    dispatch(fetchFinished({
      item: humps.camelizeKeys(res.data.data),
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchFinished({
      item: {},
      error: errorRes,
      hasError: true,
    }));
  });
};

export const searchPhysicalCategories = (query) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch(searchAllStart());
  axios.post(
    getPhysicalCategoriesWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(query),
  ).then((res) => {
    dispatch(searchAllFinished({
      searchList: humps.camelizeKeys(mapObjectToArray(res.data.data)),
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(searchAllFinished({
      searchList: [],
      error: errorRes,
      hasError: true,
    }));
  });
};

export const searchPhysicalCategoriesByIds = (query) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  const { list } = getState().log;
  dispatch({
    type: PHYSICAL_CATEGORY_SEARCH_BY_IDS_START,
    payload: {
      fetchedById: false,
      fetchingById: true,
    },
  });
  axios.post(
    getPhysicalCategoriesWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(query),
  ).then((res) => {
    const cetgoryList = humps.camelizeKeys(mapObjectToArray(res.data.data));
    const mappedCategoryLogs = !isEmpty(list) ? getMappedCategories(list, cetgoryList) : [];
    dispatch(setLogList(mappedCategoryLogs));

    if (!isEmpty(mappedCategoryLogs)) {
      dispatch({
        type: PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED,
        payload: {
          list: cetgoryList,
          fetchedById: true,
          fetchingById: false,
        },
      });
    }
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: PHYSICAL_CATEGORY_SEARCH_BY_IDS_FINISHED,
      payload: {
        list: [],
        error: errorRes,
        hasError: true,
        fetchedById: true,
        fetchingById: false,
      },
    });
  });
};

export const searchSelectedPhysicalCategoriesByIds = (query) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch({
    type: PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_START,
    payload: {
      searchingSelected: true,
      searchedSelected: false,
    },
  });
  axios.post(
    getPhysicalCategoriesWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(query),
  ).then((res) => {
    dispatch({
      type: PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED,
      payload: {
        selectedList: humps.camelizeKeys(mapObjectToArray(res.data.data)),
        searchingSelected: false,
        searchedSelected: true,
      },
    });
  }, (error) => {
    const errorRes = handleError(error);
    dispatch({
      type: PHYSICAL_CATEGORY_SEARCH_SELECTED_BY_ID_ALL_FINISHED,
      payload: {
        selectedList: [],
        error: errorRes,
        hasError: true,
        searchingSelected: false,
        searchedSelected: true,
      },
    });
  });
};

export const fetchPhysicalCategories = (query) => (dispatch, getState) => {
  const { locale: { locale }, serviceConfigs: { cdms } } = getState().settings;
  dispatch(fetchAllStart());
  axios.post(
    getPhysicalCategoriesWithLocaleSearchPath(cdms.apiPath, locale), humps.decamelizeKeys(query),
  ).then((res) => {
    dispatch(fetchAllFinished({
      list: humps.camelizeKeys(mapObjectToArray(res.data.data)),
    }));
  }, (error) => {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      list: [],
      error: errorRes,
      hasError: true,
    }));
  });
};

export const clearFromPhysicalCategoryState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};

export const clearPhysicalCategoryState = () => (dispatch) => {
  dispatch(clearStateFinished());
};
