import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  shape, string, node, bool, func,
} from 'prop-types';

import Header from '../Header';
import disableEventsOnScroll from '../../utils/disableEventsOnScroll';

const RootLayout = (props) => (
  <div className="app-container">
    <div className="app-header">
      <Header
        logOut={props.logOut}
        subHeaderContent={props.header.subHeaderContent}
        horizontalMenu={props.horizontalMenu}
        className={props.header.className}
      />
      {props.header.content}
    </div>
    <div
      id="appMainContainer"
      className={`app-main ${props.main.className || ''}`}
      onScroll={disableEventsOnScroll('appMainContainer')}
    >
      {props.main.content}
    </div>
  </div>
);

RootLayout.propTypes = {
  header: shape({
    className: string,
    subHeaderContent: node,
    content: node,
  }),
  logOut: func,
  horizontalMenu: bool,
  main: shape({
    className: string,
    content: node,
  }),
};

RootLayout.defaultProps = {
  header: null,
  main: null,
  logOut: null,
  horizontalMenu: false,
};

export default withRouter(RootLayout);
