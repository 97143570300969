export const aggregation = {
  merchantId: 'properties.merchant_id',
  recordStatus: 'record_status.keyword',
  messageId: 'message_id',
  timestamp: '@timestamp',
  entityId: 'entity_id',
  countProducts: 'count_products',
  categoryCount: 'category_count',
  afterKey: 'after_key',
  keyAsString: 'key_as_string',
  toAsString: 'to_as_string',
  level: 'level.keyword',
  attributesOriginList: 'properties.attributes_orig_list',
  attributesList: 'properties.attributes_list',
};

export const mappingFields = {
  timestamp: '@timestamp',
  merchantId: 'properties.merchant_id',
  recordStatus: 'record_status.keyword',
  level: 'level',
  messageId: 'message_id',
  messageIdKeyword: 'message_id.keyword',
  propertiesCategories: 'properties.categories',
  attributesList: 'properties.attributes_list',
  origin: 'origin',
  virtualCategoryId: 'virtual_category_id.keyword',
  entityId: 'entity_id',
};

export const keywordTypeSet = new Set(['category']);

export default {
  aggregation,
  keywordTypeSet,
  mappingFields,
};
