import axios from 'axios';
import { isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { camelizeKeys, decamelizeKeys } from 'humps';

import {
  LOG_SET_BEFORE_KEYS,
  LOG_DELETE_BEFORE_KEYS,
} from './types';


import RestActions from '../utils/rest/actions';
import {
  getLogsSearchPath,
  getLogPath,
} from '../utils/paths';
import { getAggregationField } from '../utils/getAggregationField';
import getMessageName from '../utils/getMessageName';
import getMessageColor from '../utils/getMessageColor';
import { keywordTypeSet, aggregation, mappingFields } from '../utils/variables';
import handleError from '../utils/handleError';

const logActions = new RestActions('log');
const {
  fetchFinished,
  fetchAllStart,
  fetchAllFinished,
  clearFromStateFinished,
  updateStart,
  updateFinished,
  updateMultipleStart,
  updateMultipleFinished,
} = logActions;

export const fetchAggregatedLogList = ({
  filter,
  pagination = {},
  searchFilter = {},
  fields = [],
  aggregations = [],
  sort = [],
  logType = 'merhcnat',
}) => async (dispatch, getState) => {
  const { list: merchantList } = getState().merchant;
  const { searchList } = getState().eventMessageType;
  const { monitoring } = getState().settings.serviceConfigs;
  const { list: physicalCategoryList } = getState().physicalCategory;
  const { statusOptions } = getState().settings;
  const mappedSearchParams = !isEmpty(searchFilter)
    ? `?${queryString.stringify(decamelizeKeys(searchFilter))}` : '';

  const body = {
    pagination,
  };

  const getLogName = (logId, list) => (
    !isEmpty(list) && physicalCategoryList.find((p) => p.id === logId)
      ? physicalCategoryList.find((p) => p.id === logId).name
      : logId
  );

  const getPathByName = (logId, list) => (
    !isEmpty(list) && physicalCategoryList.find((p) => p.id === logId)
      ? physicalCategoryList.find((p) => p.id === logId).pathByName
      : '-'
  );

  if (!isEmpty(aggregations)) body.aggregations = aggregations;
  if (!isEmpty(sort)) body.sort = sort;
  if (!isEmpty(filter)) body.filter = filter;
  if (!isEmpty(fields)) body.fields = fields;


  let aggField = '';

  switch (logType) {
    case 'category':
      aggField = mappingFields.propertiesCategories;
      break;

    default:
      aggField = getAggregationField({
        prefix: 'properties',
        key: logType,
        suffix: keywordTypeSet.has(logType) ? 'keyword' : false,
      });
      break;
  }

  dispatch(fetchAllStart());
  try {
    const result = await axios.post(
      `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
      decamelizeKeys(body),
    );
    let total = 0;
    let logList = [];
    let mappedList = [];
    let after = null;
    let categoryResult = {};

    if (result.data.aggregations[aggField]) {
      const resAggreagation = result.data.aggregations[aggField];
      logList = resAggreagation.buckets;
      after = camelizeKeys(resAggreagation[aggregation.afterKey]);

      mappedList = !isEmpty(logList) ? logList.map((log) => ({
        ...log,
        name: getLogName(log.key[aggField], physicalCategoryList),
        entityType: logType,
        key: log.key[aggField],
        pathByName: getPathByName(log.key, physicalCategoryList),
        messages: log[mappingFields.messageId].buckets.map((message) => ({
          ...message,
          value: message.doc_count,
          name: getMessageName(message, searchList) || message.key,
          color: getMessageColor(message, searchList),
        })),
        statuses: log[mappingFields.recordStatus].buckets.map((status) => ({
          ...status,
          ...statusOptions.find((sL) => sL.label === status.key || sL.label.includes(status.key)),
          count: status.doc_count,
        })),
      })) : [];

      switch (logType) {
        case 'merchant':
          total = merchantList.length;
          break;

        case 'category':
          total = merchantList.length;
          if (!isEmpty(aggregations)) body.aggregations = aggregations;
          categoryResult = await axios.post(
            `${getLogsSearchPath(monitoring.apiPath)}${mappedSearchParams}`,
            decamelizeKeys({
              ...body,
              aggregations: [{
                field: aggField,
                operator: 'terms',
                pagination: {
                  page: 1,
                  limit: 1,
                },
                aggregations: [{
                  field: aggregation.messageId,
                  operator: 'terms',
                }, {
                  field: aggregation.recordStatus,
                  operator: 'terms',
                }],
              }],
            }),
          );
          total = categoryResult.data.aggregations[aggField].total;
          break;

        default:
          total = result.data.total;
          break;
      }
    }

    dispatch(fetchAllFinished({
      list: camelizeKeys(mappedList),
      after,
      total,
    }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(fetchAllFinished({
      error: errorRes,
      hasErrors: true,
      list: [],
      total: 0,
    }));
  }
};

export const updateLog = (log) => (dispatch, getState) => {
  const { monitoring } = getState().settings.serviceConfigs;
  const updatedLog = omit(log, 'message');
  dispatch(updateStart());
  return axios.put(getLogPath(monitoring.apiPath, updatedLog.id), {
    data: decamelizeKeys(updatedLog),
  }).then(() => {
    // TODO check response after the API deploy
    dispatch(updateFinished({
      success: true,
      hasErrors: false,
    }));
  }, (error) => {
    dispatch(updateFinished({
      error, hasErrors: true,
    }));
  });
};

export const updateLogMultiple = (array) => async (dispatch, getState) => {
  const { monitoring } = getState().settings.serviceConfigs;
  dispatch(updateMultipleStart());
  try {
    await axios.all(array.map((item) => axios.put(getLogPath(monitoring.apiPath, item.id), {
      data: decamelizeKeys(item),
    })));
    dispatch(updateMultipleFinished({ success: true }));
  } catch (error) {
    const errorRes = handleError(error);
    dispatch(updateMultipleFinished({
      error: errorRes,
      hasError: true,
    }));
  }
};

export const setLogBeforeKey = ({ data, directionKey }) => (dispatch, getState) => {
  const { beforeKeys } = getState().log;
  const keys = [...beforeKeys];
  if (directionKey === 'after') {
    keys.push(data);
  } else {
    keys.pop();
  }
  dispatch({
    type: LOG_SET_BEFORE_KEYS,
    payload: { beforeKeys: keys },
  });
};

export const deleteLogBeforeKeys = () => (dispatch) => {
  dispatch({
    type: LOG_DELETE_BEFORE_KEYS,
    payload: { beforeKeys: [] },
  });
};

export const setLog = (item) => (dispatch) => {
  dispatch(fetchFinished({ item }));
};

export const clearLog = () => (dispatch) => {
  dispatch(fetchFinished({ item: {} }));
};

export const setLogList = (list) => (dispatch) => {
  dispatch(fetchAllFinished({ list }));
};

export const clearFromLogState = (payload) => (dispatch) => {
  dispatch(clearFromStateFinished(payload));
};
