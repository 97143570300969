import React from 'react';
import {
  func, arrayOf, shape, string, oneOfType, node, bool,
} from 'prop-types';
import { isEmpty } from 'lodash';
import Toggler from '../Toggler';
import Select from '../Select';
import IntlMessages from '../../utils/IntlMessages';
import attributeAggList from '../../utils/stash/attributeAggList';


const ExtraActions = (props) => {
  const {
    errorLevel, toggler, staticLabel, selectedAttribute, className, selectClassName, extraList,
  } = props;

  const getExtraList = () => (
    selectedAttribute && attributeAggList.some((a) => a.id === selectedAttribute)
      ? [...extraList, ...attributeAggList]
      : [...extraList]
  );

  const handleTogglerChange = (value) => {
    const currentAgg = attributeAggList.find((agg) => agg.value === value);
    props.toggler.onChange(currentAgg);
  };

  const list = [{
    value: 'filter',
    className: 'enabled-content',
    content: (
      <Select
        displayEmpty
        className={selectClassName}
        size={errorLevel.size}
        value={errorLevel.value}
        staticLabel={errorLevel.staticLabelId && <IntlMessages id={errorLevel.staticLabelId} />}
        name={errorLevel.name}
        options={errorLevel.options}
        onChange={errorLevel.onChange}
      />
    ),
    disabled: true,
  }, getExtraList()].flat();

  const disabledClassName = props.disabled ? 'disabled' : '';
  return !isEmpty(toggler) && (
    <div className={`extra-actions-wrapper ${className} ${disabledClassName}`}>
      <Toggler
        grid={{
          container: true,
          className: toggler.className,
          item: true,
        }}
        staticLabel={staticLabel}
        list={list}
        onChange={handleTogglerChange}
      />
    </div>
  );
};

ExtraActions.propTypes = {
  className: string,
  selectClassName: string,
  extraList: arrayOf(shape()),
  errorLevel: shape({
    name: string,
    value: string,
    options: arrayOf(shape({
      value: string,
      label: string,
    })),
    onChange: func,
  }),
  toggler: shape({}),
  staticLabel: oneOfType([string, node]),
  selectedAttribute: string,
  disabled: bool,
};

ExtraActions.defaultProps = {
  className: '',
  selectClassName: '',
  errorLevel: {},
  toggler: {},
  staticLabel: null,
  selectedAttribute: '',
  extraList: [],
  disabled: false,
};

export default ExtraActions;
