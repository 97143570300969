import React from 'react';
import {
  shape, arrayOf, object, string, bool, oneOfType, number,
} from 'prop-types';
import LineChart from './TinyLineChart';
import LineChartResponsive from './TinyLineChartResponsive';

const Chart = (props) => {
  const {
    width,
    height,
    sourceData,
    xAxis,
    yAxis,
    responsive,
    strokeWidth,
    dot,
    className,
    dataKey,
  } = props;

  return responsive
    ? (
      <LineChartResponsive
        className={className}
        width={width}
        height={height}
        sourceData={sourceData}
        xAxis={xAxis}
        yAxis={yAxis}
        strokeWidth={strokeWidth}
        dataKey={dataKey}
        dot={dot}
      />
    )
    : (
      <LineChart
        className={className}
        width={width}
        height={height}
        sourceData={sourceData}
        xAxis={xAxis}
        yAxis={yAxis}
        dataKey={dataKey}
        strokeWidth={strokeWidth}
        dot={dot}
      />
    );
};

Chart.propTypes = {
  className: string,
  dataKey: string,
  responsive: bool,
  dot: shape({
    strokeWidth: number,
    r: number,
  }),
  strokeWidth: number,
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  sourceData: shape({
    color: string,
    data: arrayOf(object),
  }).isRequired,
  xAxis: shape({
    axisLine: bool,
    tickLine: bool,
    customTick: bool,
    padding: shape(),
  }),
  yAxis: shape({
    axisLine: bool,
    tickLine: bool,
    customTick: bool,
    padding: shape(),
  }),
};

Chart.defaultProps = {
  className: '',
  dataKey: '',
  strokeWidth: 2,
  dot: null,
  xAxis: null,
  yAxis: null,
  responsive: false,
  width: 300,
  height: 100,
};

export default Chart;
