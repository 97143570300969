import React from 'react';
import { isEmpty } from 'lodash';

import {
  Table, TablePagination, Paper, TableFooter, TableRow, CircularProgress,
} from '@material-ui/core';

import IntlMessages from '../../utils/IntlMessages';

import Head from './Head';
import Toolbar from './Toolbar';
import Body from './Body';

import useStateStore from './hooks/useState';
import useTableRows from './hooks/useTableRows';
import useTablePagination from './hooks/useTablePagination';

import { propTypes, defaultProps } from './propTypes';

const EnhancedTable = (props) => {
  const {
    headCells, table, wrapperClassName,
    pagination, checkbox, toolbar, isSortable,
    loading, selectedRowId, rowDetails,
  } = props;

  const state = useStateStore(props);

  useTableRows(state, props);
  useTablePagination(state, props);

  const handleRequestSort = (event, property) => {
    if (isSortable) {
      const isDesc = state.orderBy === property && state.order === 'desc';
      state.setOrder(isDesc ? 'asc' : 'desc');
      state.setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.tableRows && !isEmpty(state.tableRows)
        && state.tableRows.map((n) => n.id);
      state.setSelected(newSelecteds);
      if (props.onRowCheckClick) {
        props.onRowCheckClick(newSelecteds);
      }
      return;
    }
    state.setSelected([]);
    props.onRowCheckClick([]);
  };

  const handleChangePage = (event, newPage) => {
    state.setPage(newPage);
    const directionKey = state.page > newPage ? 'before' : 'after';
    props.onPaginationChange({
      page: newPage + 1,
      limit: state.rowsPerPage,
    }, directionKey);
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value, 10);
    state.setRowsPerPage(limit);
    state.setPage(0);
    props.onPaginationChange({
      page: 1,
      limit,
    });
  };

  const handleRowCheckClick = (selectedRows) => {
    state.setSelected(selectedRows);
    if (props.onRowCheckClick) {
      props.onRowCheckClick(selectedRows);
    }
  };

  const handleRowClick = (selectedRows) => {
    if (props.onRowClick) {
      props.onRowClick(selectedRows);
    }
  };

  const getBodyPlaceholder = () => (
    <tr>
      <td
        className="p25 text-center disabled"
        // eslint-disable-next-line react/prop-types
        colSpan={(headCells.length + 1) - (table.hideColumns.length)}
      >
        <IntlMessages id="table.body.placeholder.noData" />
      </td>
    </tr>
  );

  const tableScrol = state.tableRows.length > 9;
  const tableHasRows = state.tableRows && !isEmpty(state.tableRows);
  const tableWithDetailsClass = rowDetails ? 'flex' : '';
  const tableClass = table.tableSticky && tableScrol ? 'table-sticky' : 'table-default';

  return (
    <div className="container-fluid">
      <Paper className={`table ${wrapperClassName} ${tableScrol ? 'table-border-right' : ''}`}>
        {loading && (
          <div className="loader-abs">
            <CircularProgress className="pos-abs" size={20} />
          </div>
        )}
        {toolbar.show && (
          <Toolbar
            className={toolbar.className}
            numSelected={state.selected.length}
            content={toolbar.content}
            extraContent={toolbar.extraContent}
            showSelectedTitle={checkbox.visible}
          />
        )}
        <div className={`table-wrapper ${tableClass} ${tableWithDetailsClass}`}>
          <Table
            className={table.className}
            aria-labelledby={table.arialaBelledby}
            size={table.size}
            aria-label={table.ariaLabel}
          >
            <Head
              disabledCheckbox={!tableHasRows}
              hideColumns={table.hideColumns}
              sortable={isSortable}
              numSelected={state.selected.length}
              checkbox={checkbox}
              headCells={headCells}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableHasRows ? state.tableRows.length : null}
            />
            <Body
              key={state.orderBy}
              rowClassName={table.rowClassName}
              hideColumns={table.hideColumns}
              selectedRowId={selectedRowId}
              rows={!tableHasRows ? getBodyPlaceholder() : state.tableRows}
              onRowCheckClick={handleRowCheckClick}
              onRowClick={handleRowClick}
              selected={state.selected}
              checkbox={checkbox}
              columnsWithExtraAction={table.columnsWithExtraAction}
            />
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={!tableHasRows ? 'disabled' : ''}
                  rowsPerPageOptions={pagination.rowsPerPageOptions}
                  component={pagination.component}
                  count={table.total}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  backIconButtonProps={pagination.backIconButton}
                  nextIconButtonProps={pagination.nextIconButton}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        {rowDetails}
      </Paper>
    </div>
  );
};

EnhancedTable.propTypes = propTypes;
EnhancedTable.defaultProps = defaultProps;

export default EnhancedTable;
