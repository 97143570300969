import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Home from './pages/Home';

import { clearFromProductsHistoryState } from '../../actions/productsHistory';
import { clearFromPhysicalCategoryState } from '../../actions/physicalCategory';

import { productsHistoryAppPath } from '../../utils/paths';

import useMount from './hooks/useMount';
import useUnmount from './hooks/useUnmount';

const ProductsHistory = (props) => {
  useMount();
  useUnmount(props);
  return (
    <>
      <Route exact path={productsHistoryAppPath} component={Home} />
    </>
  );
};

export const mapStateToProps = () => ({

});


const mapDispatchToProps = {
  clearFromProductsHistoryState,
  clearFromPhysicalCategoryState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsHistory);
