import { isEmpty } from 'lodash';
import getDateRangeFilter from './getDateRangeFilter';
import { defaultTimestampSort, defaultTablePagination } from './defaultData';
import { mappingFields } from '../../../utils/variables';

export default (values) => {
  if (!isEmpty(values)) {
    const {
      categories, origins, merchants, timeFrameValue, logType,
      aggregations, fields, messages,
    } = values;

    const timeRangeFilter = [{
      condition: 'and',
      group: getDateRangeFilter(timeFrameValue),
    }];

    const mappedCategoriesFilter = !isEmpty(categories)
      ? categories.map((c) => ({ ...c, field: mappingFields.propertiesCategories }))
      : [];

    const categoriesFilter = [{
      condition: 'and',
      group: mappedCategoriesFilter,
    }];

    const merchantsFilter = !isEmpty(merchants)
      ? [{
        condition: 'and',
        group: merchants,
      }]
      : [];

    const messagesFilter = !isEmpty(messages)
      ? [{
        condition: 'and',
        group: messages,
      }]
      : [];

    const entityFilter = logType === 'merchant' ? merchantsFilter : categoriesFilter;

    const filter = [
      origins,
      entityFilter,
      timeRangeFilter,
      messagesFilter,
    ].flat();

    const sort = [defaultTimestampSort];
    const pagination = defaultTablePagination;

    const searchFilter = {
      aggregations,
      filter,
      fields,
      sort,
      pagination,
    };

    return searchFilter;
  }

  return {};
};
