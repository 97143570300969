/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useTableRows = (state, props) => {
  useEffect(() => {
    state.setTableRows(props.rows);
  }, [props.rows]);

  useEffect(() => {
    state.setSelected([]);
  }, [props.update]);
};

export default useTableRows;
