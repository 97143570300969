/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { emptyPagination } from '../../../../ProductsHistory/utils/defaultData';
import exluedAggregationTypeSet from '../../../../../utils/stash/exluedAggregationTypeSet';
import getUniqArrayOfObjects from '../../../../../utils/getUniqArrayOfObjects';

const useAttribute = (state, props) => {
  const isMerchant = window.location.pathname.includes('merchant');

  const messages = [...state.messageFilter];

  const searchFilter = {
    aggregations: [],
    fields: [],
    filter: [...state.generalFilter.filter],
    pagination: state.pagination,
    logType: state.logType,
  };

  const filter = [];
  const fetchList = (groupFilter = []) => {
    filter.push({
      condition: 'and',
      group: [
        ...groupFilter,
      ],
    });
    if (!isEmpty(state.messageAttributeOptionAggregation) && !isEmpty(state.aggregationType)) {
      searchFilter.pagination = emptyPagination;
      searchFilter.aggregations = state.messageAttributeOptionAggregation;
    }

    const mappedFilter = getUniqArrayOfObjects([...searchFilter.filter, ...filter].flat());

    const isExtraFetch = !exluedAggregationTypeSet.has(state.aggregationType.value)
      && !exluedAggregationTypeSet.has(state.setSelectedChart.id);

    searchFilter.filter = mappedFilter;
    props.fetchAttributeOptionAggregationList({
      ...searchFilter,
      isExtraFetch,
    });
  };

  useEffect(() => {
    const { list } = props.attribute;
    const { titleId } = state.selectedAttribute;
    const isExist = titleId && (!isEmpty(list) && !isEmpty(state.selectedAttribute)
      && !isEmpty(
        list.some((a) => a.code === titleId).options
          ? list.find((a) => a.code === titleId).options
          : [],
      ));
    if (
      state.pagination
      && !isEmpty(state.messageAttributeOptionAggregation)
      && isMerchant
      && !isExist
    ) {
      fetchList([...messages].flat());
    }
  }, [
    state.pagination,
    state.messageAttributeOptionAggregation,
  ]);
};

export default useAttribute;
