import React, { useEffect, useState } from 'react';
import {
  oneOfType, array, node, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import { setAPIError } from '../../actions/error';
import { catalogAppPath } from '../../utils/paths';
import setInterceptors from './setInterceptors';

const ErrorBoundary = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setInterceptors(setAPIError);
  }, []);

  const reloadPage = () => document.location.reload();
  const onHomePageClick = () => document.location.replace(catalogAppPath);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  let snackBar = null;
  const { error } = props;
  if (error && error.errors.hasError) {
    const { title, statusCode, message } = error.errors;
    snackBar = (
      <>
        <Snackbar
          className="snackbar-main-wrapper"
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          open={open || error.errors.hasError}
          autoHideDuration={600000}
          onClose={handleClose}
          message={(
            <div className="snackbar-wrapper">
              <div className="flex">
                <i className="ti-alert text-lg text-danger mr-15" />
                <div className="snackbar-right">
                  <span className="h5">{`${title} - ${statusCode}`}</span>
                  <p className="mb-0">{message}</p>
                  {error.errors.extraDetails && (
                    <details className="mt-10">
                      <summary>See More</summary>
                      <div className="details-row">
                        <b>name:</b>
                        {error.errors.name}
                      </div>
                      <div className="details-row">
                        <b>message:</b>
                        {error.errors.extraDetails.message}
                      </div>
                      <div className="details-row">
                        <b>Config data:</b>
                        {error.errors.extraDetails.configData}
                      </div>
                      <div className="details-row">
                        <b>response url:</b>
                        {error.errors.extraDetails.responseURL}
                      </div>
                      <div className="details-row">
                        <b>stack:</b>
                        {error.errors.extraDetails.stack}
                      </div>
                    </details>
                  )}
                </div>
              </div>
            </div>
          )}
          action={(
            <>
              <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                <i className="zmdi zmdi-close" />
              </IconButton>
              <div className="pos-abs actions-abs flex block">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onHomePageClick}
                >
                  Go home
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={reloadPage}
                >
                  Refresh page
                </Button>
              </div>
            </>
          )}
        />
      </>
    );
  }

  return (
    <>
      {error.errors.hasError && snackBar}
      {!error.errors.hasError && props.children}
    </>
  );
};

ErrorBoundary.propTypes = {
  error: shape().isRequired,
  children: oneOfType([array, node]),
};

ErrorBoundary.defaultProps = {
  children: [],
};

const mapStateToProps = ({
  error,
}) => ({
  error,
});

const actionCreators = {
  setAPIError,
};

export default connect(mapStateToProps, actionCreators)(ErrorBoundary);
