/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import _ from 'lodash';

const useSourceList = (state, props) => {
  useEffect(() => {
    const { sourceList } = props;
    if (!_.isEqual(_.sortBy(sourceList), _.sortBy(state.sourceList))) {
      state.setSourceList(sourceList);
    }
  }, [props.sourceList]);
};

export default useSourceList;
