/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { defaultTimestampSort } from '../../../utils/defaultData';
import getUniqArrayOfObjects from '../../../../../utils/getUniqArrayOfObjects';
import { mappingFields } from '../../../../../utils/variables';

const useDetailsLog = (state, props) => {
  const isCategory = window.location.pathname.includes('category');
  const messages = [...state.messageFilter];
  const catalogCategoryId = window.location.pathname.split('/').slice(-1).join();

  const searchFilter = {
    aggregations: [],
    sort: [defaultTimestampSort],
    fields: [],
    filter: [...state.generalFilter.filter],
    pagination: state.pagination,
    logType: state.logType,
  };

  const filter = [];

  const fetchList = (groupFilter = []) => {
    const categoryIdExist = searchFilter.filter.map(
      (cL) => cL.group.some((g) => g.value === catalogCategoryId),
    ).includes(true);

    if (!categoryIdExist) {
      filter.push({
        condition: 'and',
        group: [
          {
            field: mappingFields.propertiesCategories,
            value: catalogCategoryId,
            operator: 'eq',
          },
          ...groupFilter,
        ],
      });

      if (!isEmpty(state.errorLevelFilterGroup)) {
        filter.push(state.errorLevelFilterGroup);
      }
    }


    const mappedFilter = getUniqArrayOfObjects([...searchFilter.filter, filter].flat());
    searchFilter.filter = mappedFilter;
    props.fetchDetailsLogList(searchFilter);
  };

  useEffect(() => {
    if (props.detailsLog.fetched && props.eventMessageType.searched && isCategory) {
      state.setTableRows([...props.detailsLog.list]);
    }
  }, [props.detailsLog.fetched, props.eventMessageType.searched]);

  useEffect(() => {
    if (
      state.messageFilter
      && state.pagination
      && props.eventMessageType.searched
      && isCategory
    ) {
      fetchList(messages.flat());
    }
  }, [
    state.pagination,
    state.errorLevelFilterGroup,
    props.eventMessageType.searched,
    state.messageFilter,
  ]);
};

export default useDetailsLog;
