export default [
  {
    messageId: 'merchant_blacklisted',
    color: '#064fdc',
  },
  {
    messageId: 'etl_transform_failed',
    color: '#06cbdc',
  },
  {
    messageId: 'etl_extract_failed',
    color: '#bca576',
  },
  {
    messageId: 'queue_item_payload_not_valid',
    color: '#5de33d',
  },
  {
    messageId: 'not_mapped_result_exception',
    color: '#23566c',
  },
  {
    messageId: 'database_response_error',
    color: '#d16666',
  },
  {
    messageId: 'database_not_available',
    color: '#f713aa',
  },
  {
    messageId: 'could_not_save_entity',
    color: '#a7da40',
  },
  {
    messageId: 'queue_service_protocol_is_not_available',
    color: '#531869',
  },
  {
    messageId: 'portal_attempted_product_condition_change',
    color: '#1150e8',
  },
  {
    messageId: 'queue_service_is_not_available_connection_closed',
    color: '#be57f1',
  },
  {
    messageId: 'etl_load_failed',
    color: '#5ea65f',
  },
  {
    messageId: 'queue_service_is_not_available',
    color: '#93cfcf',
  },
  {
    messageId: 'database_data_source_not_found',
    color: '#b053ec',
  },
  {
    messageId: 'queue_service_is_not_available_runtime',
    color: '#6b7ca8',
  },
  {
    messageId: 'queue_service_is_not_available_connection',
    color: '#e5e12f',
  },
  {
    messageId: 'queue_service_is_not_available_timeout',
    color: '#ad1e07',
  },
  {
    messageId: 'entity_instance_instantiate_error',
    color: '#4d1b4f',
  },
  {
    messageId: 'event_monitoring_service_not_available',
    color: '#d6714a',
  },
  {
    messageId: 'entity_field_not_unique',
    color: '#f09b2d',
  },
  {
    messageId: 'unexpected_entity_type',
    color: '#3e1563',
  },
  {
    messageId: 'image_not_received',
    color: '#1e6d28',
  },
  {
    messageId: 'entity_not_valid',
    color: '#af89fb',
  },
  {
    messageId: 'method_not_allowed',
    color: '#a53e0a',
  },
  {
    messageId: 'database_input_data_error',
    color: '#964a0b',
  },
  {
    messageId: 'empty_collection',
    color: '#4223ad',
  },
  {
    messageId: 'schema_not_valid',
    color: '#2388ad',
  },
  {
    messageId: 'entity_not_defined',
    color: '#23ad8c',
  },
  {
    messageId: 'storage_source_missing',
    color: '#28e0b4',
  },
  {
    messageId: 'schema_not_defined',
    color: '#28e045',
  },
  {
    messageId: 'not_mapped_raw_data',
    color: '#f5a2e1',
  },
  {
    messageId: 'enrichment_product',
    color: '#68d553',
  },
  {
    messageId: 'enrichment_categories_differs"',
    color: '#3a35e1',
  },
  {
    messageId: 'queue_service_is_not_available_send',
    color: '#79e028',
  },
  {
    messageId: 'resource_not_found',
    color: '#dfe028',
  },
  {
    messageId: 'user_not_found',
    color: '#e028ba',
  },
  {
    messageId: 'user_not_authenticated',
    color: '#6428e0',
  },
  {
    messageId: 'invalid_app_env',
    color: '#1283f3',
  },
  {
    messageId: 'user_not_authorized',
    color: '#1283f3',
  },
  {
    messageId: 'entity_not_found',
    color: '#1283f3',
  },
  {
    messageId: 'invalid_argument',
    color: '#7aad23',
  },
  {
    messageId: 'bad_request',
    color: '#adf533',
  },
];
