import {
  arrayOf, shape, string, func, number, bool, node, object, oneOfType,
} from 'prop-types';

export const propTypes = {
  loading: bool,
  isSortable: bool,
  rows: arrayOf(shape()),
  selectedRowId: string,
  headCells: arrayOf(shape()).isRequired,
  table: shape({
    tableSticky: bool,
    columnsWithExtraAction: arrayOf(string),
    hideColumns: arrayOf(string),
    rowClassName: string,
    className: string,
    size: string,
    ariaLabel: string,
    arialaBelledby: string,
    orderBy: string,
    rowsPerPage: number,
    total: number,
  }),
  toolbar: shape({
    className: string,
    show: bool,
    content: node,
  }),
  checkbox: shape({
    className: string,
    color: string,
  }),
  pagination: shape({
    className: string,
    component: string,
    backIconButton: shape(),
    nextIconButton: shape(),
    rowsPerPageOptions: arrayOf(oneOfType([object, number, string])),
  }),
  wrapperClassName: string,
  onRowClick: func,
  onRowCheckClick: func,
  onPaginationChange: func,
};

export const defaultProps = {
  loading: false,
  rows: [],
  isSortable: false,
  selectedRowId: '',
  table: {
    tableSticky: false,
    columnsWithExtraAction: [],
    hideColumns: [],
    rowClassName: '',
    className: '',
    size: 'medium',
    ariaLabel: 'enhanced table',
    arialaBelledby: 'tableTitle',
    orderBy: '',
    rowsPerPage: 25,
    total: 0,
  },
  toolbar: {
    className: '',
    show: false,
    content: null,
  },
  checkbox: {
    className: 'checkbox-sm',
    color: 'primary',
    inputProps: { 'aria-label': 'select all desserts' },
    visible: true,
  },
  pagination: {
    component: 'div',
    backIconButton: {
      'aria-label': 'previous page',
    },
    nextIconButton: {
      'aria-label': 'next page',
    },
    rowsPerPageOptions: null,
  },
  wrapperClassName: '',
  onRowClick: null,
  onRowCheckClick: null,
  onPaginationChange: null,
};

export default {
  propTypes,
  defaultProps,
};
