/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { aggregation } from '../../../../../utils/variables';
import getUniqArrayOfObjects from '../../../../../utils/getUniqArrayOfObjects';

const useMerchantLog = (state, props) => {
  const isMerchant = window.location.pathname.includes('merchant');

  const messages = [...state.messageFilter];
  const catalogMerchantId = window.location.pathname.split('/').slice(-1).join();

  const searchFilter = {
    aggregations: [],
    fields: [],
    filter: [...state.generalFilter.filter],
    pagination: state.pagination,
    logType: state.logType,
  };

  const filter = [];

  const fetchList = (groupFilter = []) => {
    const mercganFilterGroup = {
      condition: 'and',
      group: [{
        field: aggregation.merchantId,
        value: catalogMerchantId,
        operator: 'eq',
      }],
    };

    if (!isEmpty(groupFilter)) {
      filter.push({
        condition: 'and',
        group: [...groupFilter],
      });
    }

    if (catalogMerchantId) {
      filter.push(mercganFilterGroup);
    }

    if (!isEmpty(state.errorLevelFilterGroup)) {
      searchFilter.filter.push(state.errorLevelFilterGroup);
    }

    const mappedFilter = getUniqArrayOfObjects([...searchFilter.filter, filter].flat());
    searchFilter.filter = mappedFilter;
    props.fetchDetailsLogList(searchFilter);
  };

  useEffect(() => {
    if (props.detailsLog.fetched && props.eventMessageType.searched && isMerchant) {
      state.setTableRows([...props.detailsLog.list]);
    }
  }, [props.detailsLog.fetched, props.eventMessageType.searched]);

  useEffect(() => {
    if (
      state.messageFilter
      && state.pagination
      && props.eventMessageType.searched
      && isMerchant
      && isEmpty(state.aggregationType)
    ) {
      fetchList([...messages].flat());
    }
  }, [
    state.pagination,
    state.errorLevelFilterGroup,
    props.eventMessageType.searched,
    state.messageFilter,
  ]);
};

export default useMerchantLog;
