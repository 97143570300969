/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useUnmount = (props) => {
  useEffect(() => () => {
    props.clearFromPhysicalCategoryState({
      fetchedById: false,
      list: [],
    });
    props.clearFromLogState({
      fetched: false,
      list: [],
      beforeKeys: [],
      after: {},
    });
  }, []);
};

export default useUnmount;
